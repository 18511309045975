import { Heading } from "@chakra-ui/react"
import styled from "styled-components"

export const SectionHeading = styled(Heading).attrs({
  fontWeight: 400,
  mb: "2mm",
  fontSize: "18pt",
  textTransform: "uppercase",
})``

export const SectionSubHeading = styled(Heading).attrs({
  fontWeight: 500,
  mb: "2mm",
  fontSize: "14pt",
})``
