import { type Client, createClient as createWSClient } from "graphql-ws"
import * as auth from "lib/auth"
import { config } from "lib/helpers/config"
import { createContext, useContext, useEffect, useRef } from "react"
import { useSearchParams } from "react-router-dom"
import { useAuth } from "./AuthProvider"
import { useVersion } from "./VersionProvider"

const WsClientContext = createContext<Client>(null)

const createClient = (endpoint: string, paramToken?: string) => {
  return createWSClient({
    url: endpoint,
    retryAttempts: 3,
    lazy: false,
    keepAlive: 10000,
    // For printing PDFs, we only want to use the token provided in the URL
    connectionParams: paramToken
      ? { token: paramToken }
      : async () => {
          const currentToken = localStorage.getItem("r8me-token")

          if (!currentToken) {
            return { token: currentToken }
          }

          const refreshToken = await auth.refreshToken({ token: currentToken })

          if (refreshToken) {
            localStorage.setItem("r8me-token", refreshToken)
          }

          return {
            token: refreshToken || currentToken,
          }
        },
  })
}

export function WsClientProvider({ children }: { children: React.ReactNode }) {
  const { isMaintenance } = useVersion()
  const [params] = useSearchParams()
  const endpoint = params.get("ws_endpoint") || config.WS_API_URL
  const paramToken = params.get("authToken")

  const auth = useAuth()
  const { token } = paramToken ? { token: params.get("authToken") } : auth

  const currentToken = useRef(token)
  const currentWsClient = useRef<Client>()

  useEffect(() => {
    if (isMaintenance && currentWsClient.current) {
      currentWsClient.current.terminate()
    }
  }, [isMaintenance, currentWsClient.current])

  const shouldCreate = !currentWsClient.current || currentToken?.current !== token

  if (shouldCreate && !isMaintenance) {
    currentToken.current = token

    if (currentWsClient.current) {
      currentWsClient.current.dispose()
    }

    currentWsClient.current = createClient(endpoint, paramToken)
  }

  if (!currentWsClient.current) {
    return null
  }

  return <WsClientContext.Provider value={currentWsClient.current}>{children}</WsClientContext.Provider>
}

export function useWsClient() {
  return useContext(WsClientContext)
}
