import { Icon, type IconProps } from "@chakra-ui/react"

export const HelpPopoverIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon width="14" height="14" viewBox="0 0 64 64" fill="none" {...props}>
      <title>Help</title>
      <path
        d="M32.0002 5.33331C46.7282 5.33331 58.6669 17.272 58.6669 32C58.6669 46.728 46.7282 58.6666 32.0002 58.6666C27.6861 58.6724 23.4356 57.6271 19.6162 55.6213L8.17619 58.6C7.79394 58.6995 7.3923 58.6975 7.0111 58.594C6.62989 58.4905 6.28234 58.2892 6.00289 58.01C5.72345 57.7309 5.52181 57.3835 5.41796 57.0024C5.31412 56.6213 5.31167 56.2197 5.41085 55.8373L8.38685 44.4C6.37565 40.5765 5.32758 36.3201 5.33352 32C5.33352 17.272 17.2722 5.33331 32.0002 5.33331ZM32.0002 41.3333C31.2929 41.3333 30.6147 41.6143 30.1146 42.1144C29.6145 42.6145 29.3335 43.2927 29.3335 44C29.3335 44.7072 29.6145 45.3855 30.1146 45.8856C30.6147 46.3857 31.2929 46.6666 32.0002 46.6666C32.7074 46.6666 33.3857 46.3857 33.8858 45.8856C34.3859 45.3855 34.6669 44.7072 34.6669 44C34.6669 43.2927 34.3859 42.6145 33.8858 42.1144C33.3857 41.6143 32.7074 41.3333 32.0002 41.3333ZM32.0002 18C30.0553 18 28.19 18.7726 26.8147 20.1479C25.4395 21.5231 24.6669 23.3884 24.6669 25.3333C24.667 25.84 24.8595 26.3278 25.2055 26.6981C25.5514 27.0684 26.025 27.2935 26.5305 27.328C27.0361 27.3626 27.5359 27.2039 27.929 26.8842C28.3221 26.5644 28.5791 26.1073 28.6482 25.6053L28.6855 24.992C28.7756 24.1441 29.1871 23.3631 29.8354 22.8093C30.4838 22.2556 31.3196 21.9714 32.1711 22.0151C33.0226 22.0589 33.825 22.4272 34.4132 23.0444C35.0014 23.6616 35.3308 24.4807 35.3335 25.3333C35.3335 26.7706 34.9735 27.48 33.6135 28.8853L32.8029 29.712C30.7922 31.8026 30.0002 33.304 30.0002 36C30.0002 36.5304 30.2109 37.0391 30.586 37.4142C30.961 37.7893 31.4698 38 32.0002 38C32.5306 38 33.0393 37.7893 33.4144 37.4142C33.7895 37.0391 34.0002 36.5304 34.0002 36C34.0002 34.5626 34.3602 33.8533 35.7202 32.448L36.5309 31.6213C38.5415 29.5306 39.3335 28.0293 39.3335 25.3333C39.3335 23.3884 38.5609 21.5231 37.1856 20.1479C35.8104 18.7726 33.9451 18 32.0002 18Z"
        fill="#FBA421"
      />
    </Icon>
  )
}
