import type { Client as WsClient } from "graphql-ws"
import { config } from "lib/helpers/config"
import { useHandleErrors } from "lib/helpers/useHandleErrors"
import { useRef } from "react"
import { useSearchParams } from "react-router-dom"
import {
  Provider,
  type Client as UrqlClient,
  cacheExchange,
  createClient,
  errorExchange,
  subscriptionExchange,
} from "urql"
import { useWsClient } from "./WsClientProvider"

let _urqlClient: UrqlClient | null = null

export const UrqlProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const wsClient = useWsClient()
  const [params] = useSearchParams()

  const endpoint = params.get("ws_endpoint") || config.WS_API_URL

  const handleErrors = useHandleErrors()
  const currentWsClient = useRef<WsClient>()

  const createUrqlClient = () => {
    return createClient({
      url: endpoint,
      exchanges: [
        cacheExchange,
        errorExchange({
          onError(error, operation) {
            // Error thrown in WS connection

            if (!handleErrors(error?.networkError)) {
              console.error(error)
              console.error(operation)
            }
          },
        }),
        subscriptionExchange({
          forwardSubscription: (request) => {
            const input = { ...request, query: request.query || "" }
            return {
              subscribe(sink) {
                const unsubscribe = wsClient.subscribe(input, sink)
                return { unsubscribe }
              },
            }
          },
          enableAllOperations: true,
        }),
      ],
    })
  }

  if (currentWsClient.current !== wsClient || !_urqlClient) {
    currentWsClient.current = wsClient
    _urqlClient = createUrqlClient()
  }

  return <Provider value={_urqlClient}>{children}</Provider>
}
