import { Box, Grid, GridItem } from "@chakra-ui/react"
import { InfoHeadingPanel } from "components/InfoHeadingPanel"
import { useRating } from "lib/providers/RatingProvider"
import type React from "react"
import { AdditionalQuestionsTable } from "./AdditionalQuestionsTable"
import { Answer } from "./Answer"

export const Answers: React.FC = () => {
  const { ratingId, ratingState, ratingProfile, method } = useRating()

  if (!ratingState) return null
  if (!ratingProfile) return null
  if (!method) return null

  const questions = method.config.questions
  const additionalQuestions = ratingState?.additionalQuestions || []

  return (
    <Box>
      <InfoHeadingPanel as="h2" size="md" helpText="helpTexts.questions">
        Spørsmål - svar og grunnlag
      </InfoHeadingPanel>
      <Grid mt={8} columnGap="6px" gridRowGap="20px" gridTemplateColumns="auto 1fr">
        {additionalQuestions.length > 0 && (
          <GridItem colSpan={2}>
            <AdditionalQuestionsTable ratingState={ratingState} />
          </GridItem>
        )}
        {questions.map((question) => (
          <Answer key={question.id} question={question} ratingState={ratingState} ratingId={ratingId} />
        ))}
      </Grid>
    </Box>
  )
}
