import { DeleteIcon, SmallCloseIcon, WarningTwoIcon } from "@chakra-ui/icons"
import { Alert, AlertDescription, AlertIcon, Box, Button, Flex, FormLabel, Icon, Input } from "@chakra-ui/react"
import type { ExtendedCalculatedKpi, KpiOverride } from "@esgt/method-structure"
import type { Kpi } from "@esgt/types"
import { useOverrideKpiValueMutation, useRemoveKpiValueOverrideMutation } from "lib/generated/graphql"
import * as mathjs from "mathjs"
import { useForm } from "react-hook-form"
import { GrUserAdmin } from "react-icons/gr"
import { useBoolean } from "usehooks-ts"

type KpiOverwriterProps = {
  ratingId: string
  kpi: Kpi
  calculatedKpi: ExtendedCalculatedKpi
  currentOverride?: KpiOverride
}

export const KpiOverrider = ({ ratingId, kpi, calculatedKpi, currentOverride }: KpiOverwriterProps) => {
  const { value: isOpen, setTrue: open, setFalse: close } = useBoolean(false)

  const calculatedValue = calculatedKpi.value ?? 0

  const { register, watch } = useForm({
    defaultValues: {
      valueInput: currentOverride?.value ?? mathjs.number(calculatedValue),
      reasonInput: currentOverride?.reason ?? "",
    },
  })

  const [overrideKpiValueStatus, overrideKpiValue] = useOverrideKpiValueMutation()
  const [removeKpiValueOverrideStatus, removeKpiValueOverride] = useRemoveKpiValueOverrideMutation()
  const isQuerying = overrideKpiValueStatus.fetching || removeKpiValueOverrideStatus.fetching

  const { valueInput, reasonInput } = watch()

  const isValidInput = typeof valueInput === "number" && !Number.isNaN(valueInput) && reasonInput.length > 0

  const override = () => {
    if (isValidInput) {
      overrideKpiValue({
        ratingId,
        kpiId: kpi.id,
        reason: reasonInput,
        value: valueInput,
        oldValue: mathjs.number(calculatedValue),
      })
      close()
    }
  }

  const removeOverride = () => {
    removeKpiValueOverride({ ratingId, kpiId: kpi.id })
  }

  return (
    <Box gap={1} borderWidth="1px" borderRadius="lg" padding={4}>
      {isOpen ? (
        <Flex direction="column">
          <Alert status="warning">
            <AlertIcon />
            <Flex flexDirection="column">
              <AlertDescription>
                Dette er et <strong>teknisk verktøy</strong> kun for super-admins ved behov i helt spesielle tilfeller.
                <br />
                Det skal kun overskrives ved <strong>sterkt og tidssensitivt behov</strong>, for eksempel for viktige
                rettelser rett før generering av en rapport som ikke kan vente på at den <em>faktiske</em> rettelsen
                (formel-bugfix e.l.) ferdigstilles.
              </AlertDescription>
            </Flex>
          </Alert>

          <FormLabel>
            Verdi
            <Input type="number" {...register("valueInput", { valueAsNumber: true, required: true })} />
          </FormLabel>

          <FormLabel>
            Kommentar/begrunnelse
            <Input type="text" {...register("reasonInput", { required: true })} />
          </FormLabel>

          <Flex gap={1}>
            <Button onClick={override} size="sm" isDisabled={isQuerying || !isValidInput} leftIcon={<WarningTwoIcon />}>
              Overskriv
            </Button>

            <Button onClick={close} size="sm" isDisabled={isQuerying} leftIcon={<SmallCloseIcon />}>
              Avbryt
            </Button>
          </Flex>
        </Flex>
      ) : (
        <>
          <Button
            onClick={open}
            isDisabled={typeof valueInput !== "number" || isQuerying}
            size="sm"
            leftIcon={<Icon as={GrUserAdmin} />}
          >
            Overstyr KPI-verdi
          </Button>

          {currentOverride !== undefined && (
            <Button onClick={removeOverride} size="sm" isDisabled={isQuerying} leftIcon={<DeleteIcon />}>
              Slett overskriving
            </Button>
          )}
        </>
      )}
    </Box>
  )
}
