import { WarningTwoIcon } from "@chakra-ui/icons"
import { Button, Checkbox, Flex, Icon, Spinner, Td, Text, Tooltip, Tr } from "@chakra-ui/react"
import { extendedCalculateKpi } from "@esgt/method-structure"
import type { Kpi } from "@esgt/types"
import { MethodI18nFragment } from "components/I18nText"
import { useApproveKpiValueMutation } from "lib/generated/graphql"
import { formatValue } from "lib/helpers/formatValue"
import { useRating } from "lib/providers/RatingProvider"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"

interface Props {
  kpi: Kpi
}

const KPIBox: React.FC<Props> = ({ kpi }) => {
  const { ratingState, ratingId, ratingProfileConfig, isEditable, method } = useRating()
  const navigate = useNavigate()
  const [approveKpiStatus, approveKpi] = useApproveKpiValueMutation()

  const editKpi = () => navigate(`${kpi.id}/`)

  const calculatedKpi = useMemo(() => {
    if (!method) return undefined
    return extendedCalculateKpi(
      kpi,
      ratingProfileConfig,
      ratingState.datapoints,
      ratingState.anomalies,
      ratingState.phase,
      ratingState.overriddenKpisById,
      method.kpisById,
    )
  }, [ratingState.datapoints, kpi, ratingProfileConfig, method])

  if (!ratingState || !method) return null

  return (
    <Tr>
      <Td maxW="200px" pr="16px">
        <MethodI18nFragment text={kpi.name} />
      </Td>
      <Td>
        <Flex direction="column">
          <span>
            {calculatedKpi?.value !== undefined ? formatValue(calculatedKpi.value.toString()) : "-"}
            {ratingState.overriddenKpisById[kpi.id] && (
              <Tooltip
                placement="top"
                fontSize="md"
                lineHeight="20px"
                padding="12px 16px"
                borderRadius="4px"
                background="gray.900"
                label={`Overstyrt, med kommentar "${ratingState.overriddenKpisById[kpi.id].reason}"`}
              >
                <span>
                  {" "}
                  <WarningTwoIcon boxSize={3} />
                </span>
              </Tooltip>
            )}
          </span>
          <Text color="neutral.600" size="sm">
            <MethodI18nFragment text={kpi.unit} />
          </Text>
        </Flex>
      </Td>
      <Td>
        <Icon viewBox="0 0 200 200" boxSize={4} color={calculatedKpi.color}>
          <circle cx="100" cy="100" r="55" fill="currentColor" />
        </Icon>
        <Text as="span" size="sm" ml="4px">
          {calculatedKpi.text}
        </Text>
      </Td>
      <Td textAlign="center">
        <div
          style={{
            display: approveKpiStatus.fetching ? "block" : "none",
          }}
        >
          <Spinner size="xs" mr="10px" ml="2px" />
        </div>

        <Checkbox
          mt="2px"
          size="md"
          isChecked={ratingState.approvedKpis[kpi.id] === true}
          isDisabled={!isEditable}
          style={{ display: approveKpiStatus.fetching ? "none" : "" }}
          onChange={(e) => {
            approveKpi({ kpiId: kpi.id, ratingId, approved: e.target.checked })
          }}
        />
      </Td>
      <Td textAlign="right">
        <Button variant="outline" size="sm" onClick={editKpi}>
          {isEditable ? "Kontrollér" : "Vis"}
        </Button>
      </Td>
    </Tr>
  )
}

export { KPIBox }
