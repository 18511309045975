import { Flex, Text } from "@chakra-ui/react"
import { RATING_ROLE_GUI_NAMES } from "@esgt/utils"
import type { RaterOption } from "../../RaterOption"
import { FadedLabel } from "../FadedLabel"

interface RaterSummaryProps {
  raters: Array<RaterOption>
}

export const RaterSummary = ({ raters }: RaterSummaryProps) => {
  return (
    <Flex direction="column" gap={2}>
      <Text fontWeight="semibold">Navn</Text>

      <Flex direction="column" gap={1}>
        {raters.map((rater) => (
          <Flex key={rater.user.id} direction="row" justifyContent="space-between" gap={8}>
            <Text>
              {rater.user.name}{" "}
              <Text as="span" fontSize="0.9em" opacity={0.5}>
                ({rater.user.email})
              </Text>
            </Text>

            <FadedLabel>{RATING_ROLE_GUI_NAMES[rater.role]}</FadedLabel>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}
