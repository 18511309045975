import type { Kpi, MethodConfig } from "@esgt/types"
import { useMemo } from "react"
import { type Method, useMethodQuery } from "../generated/graphql"

function byId<T extends { id: I }, I extends string>(items: T[]): Record<I, T> {
  return items.reduce((acc, item) => Object.assign(acc, { [item.id]: item }), {} as Record<I, T>)
}

type MethodWithTypedConfig = Omit<Method, "config"> & { config: MethodConfig }

export type MethodResult = MethodWithTypedConfig & {
  kpisById: Record<Kpi["id"], Kpi>
}

export type UseMethodResult = {
  method?: MethodResult
  isFetchingMethod: boolean
}

export function useMethod(id?: number): UseMethodResult {
  const [methodQuery] = useMethodQuery({
    variables: { id: id ?? -1 },
    requestPolicy: "cache-first",
    pause: !id,
  })

  const method = useMemo(() => {
    const retrievedMethod = methodQuery.data?.method as MethodWithTypedConfig

    if (retrievedMethod) {
      return {
        ...retrievedMethod,
        kpisById: byId(retrievedMethod.config.kpis),
      }
    }

    return undefined
  }, [methodQuery.data?.method])

  return {
    method,
    isFetchingMethod: methodQuery.fetching,
  }
}
