import { FormControl, Input, Td, Tr } from "@chakra-ui/react"
import { validateContactPerson } from "@esgt/utils"
import { EmailInput } from "components/common/Contact/EmailInput"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import type { ContactInput } from "./EditContactPersonsModal"

interface NewContactPersonRowProps {
  index: number
  contact: ContactInput
  contactChanged: (index: number, updated: ContactInput) => void
}

export const NewContactPersonRow = ({ index, contact, contactChanged }: NewContactPersonRowProps) => {
  const nameKey = `${index}-name`
  const emailKey = `${index}-email`

  const {
    watch,
    register,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      [nameKey]: contact.name,
      [emailKey]: contact.email,
    },
  })

  const values = watch()

  useEffect(() => {
    const name = values[nameKey].trim()
    const email = values[emailKey].trim()
    const hasChanged = name !== contact.name || email !== contact.email
    if (hasChanged) {
      contactChanged(index, { name, email, isValid: validateContactPerson({ name, email }) })
    }
  }, [values, nameKey, emailKey, index, contactChanged, contact.name, contact.email])

  return (
    <Tr gap={2} gridTemplateRows="1fr 1fr">
      <Td paddingLeft={1}>
        <FormControl ml="-1ch">
          <Input required type="text" placeholder="Navn" {...register(nameKey, { value: contact.name })} />
        </FormControl>
      </Td>

      <Td>
        <EmailInput
          ml="-1ch"
          inputKey={emailKey}
          setError={setError}
          clearErrors={clearErrors}
          register={register}
          errors={errors}
          value={contact.email}
          errorMessage=""
        />
      </Td>
    </Tr>
  )
}
