import { Flex, FormLabel, Select, Spinner, Text } from "@chakra-ui/react"
import type { RatingState } from "@esgt/event-store"
import type { MethodVersionId } from "@esgt/types"
import { useChangeRatingProfileMutation, useRatingProfilesQuery } from "lib/generated/graphql"
import { useResultToast } from "lib/hooks"
import { useCallback, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useBoolean, useUpdateEffect } from "usehooks-ts"
import { OrderPropertyRow } from "./OrderPropertyRow"

interface OrderProfileProps {
  ratingId: string
  ratingState: RatingState
  methodVersion?: MethodVersionId
}

export const OrderProfile = ({ ratingId, ratingState, methodVersion }: OrderProfileProps) => {
  const [changeProfileResult, changeProfile] = useChangeRatingProfileMutation()
  const [ratingProfiles] = useRatingProfilesQuery({
    variables: { methodVersion },
    // Don't query before we know which method version we need profiles for
    pause: !methodVersion,
  })

  const {
    register,
    watch,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues: {
      profileId: ratingState.ratingProfileId,
    },
  })

  const values = watch()

  // Update what the "unchanged" state is (wrt isDirty, etc)
  useUpdateEffect(() => {
    reset({ profileId: ratingState.ratingProfileId })
  }, [ratingState, reset])

  const [isLoading, setIsLoading] = useState<boolean>(!!ratingState?.ratingProfileId)

  const currentProfile = useMemo(() => {
    if (ratingProfiles.data?.ratingProfiles && ratingState?.ratingProfileId) {
      setIsLoading(false)
      return ratingProfiles.data.ratingProfiles.find((p) => p.id === ratingState.ratingProfileId)
    }
    return undefined
  }, [ratingProfiles.data?.ratingProfiles, ratingState?.ratingProfileId])

  const { value: isEditingProfile, setTrue: setEditingProfile, setFalse: setNotEditingProfile } = useBoolean()

  const submitProfile = useCallback(() => {
    setNotEditingProfile()
    changeProfile({ ratingId, ratingProfileId: values.profileId })
    setIsLoading(true)
  }, [changeProfile, ratingId, setNotEditingProfile, values])

  useResultToast(changeProfileResult, "Profil oppdatert", "Kunne ikke oppdatere profil")

  return (
    <OrderPropertyRow
      title="Målingsprofil"
      infoBody="helpTexts.ratingSettings.orderProfile"
      isEditing={isEditingProfile}
      onEditStart={setEditingProfile}
      onEditEnd={setNotEditingProfile}
      onSubmit={submitProfile}
      isDisabled={isLoading}
      hasChanged={isDirty}
    >
      {isEditingProfile ? (
        <Flex as={FormLabel} htmlFor="profile-selector" direction="column" gap="4px">
          Velg målingsprofil
          {ratingProfiles.data?.ratingProfiles && ratingState.ratingProfileId && (
            <Select
              width="50%"
              id="profile-selector"
              background="white"
              {...register("profileId", {
                required: true,
                disabled: !isEditingProfile || changeProfileResult.fetching,
                valueAsNumber: true,
              })}
            >
              {ratingProfiles.data?.ratingProfiles.map((profile) => (
                <option key={profile.id} value={profile.id}>
                  {profile.name} {profile.methodVersion} — {profile.description}
                </option>
              ))}
            </Select>
          )}
          {/*
						// TODO: text that explains sideeffects? Are there any sideeffects?
						<Text mb={3} fontSize={16}>
						Ved endring av rapportperiode må virksomheten, dersom de har lastet opp dokumentasjon
						eller svart ut spørsmål, gå inn og oppdatere dette slik at det svarer til ny rapport
						periode.
					</Text> */}
        </Flex>
      ) : (
        <Flex direction="column" gap="4px">
          {isLoading ? (
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="md" />
          ) : (
            <Text>
              {currentProfile?.name} {currentProfile?.methodVersion} — {currentProfile?.description}
            </Text>
          )}
        </Flex>
      )}
    </OrderPropertyRow>
  )
}
