import { Heading, Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react"
import type { KpiDimension } from "@esgt/types"
import { MethodI18nFragment } from "components/I18nText"
import { useRating } from "lib/providers/RatingProvider"
import { Route, Routes, useParams } from "react-router-dom"
import styled from "styled-components"
import { KPIBox } from "./KPIBox"
import { KPIEditor } from "./KPIEditor"

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  gap: 36px;
  overflow: auto;
  padding-bottom: 150px;
`

export const CategoryKPIs: React.FC = () => {
  const { dimensionId, categoryId } = useParams()
  const { ratingProfileConfig, method } = useRating()

  if (!categoryId || !dimensionId || !method) return null
  const dimension = method.config.kpiDimensions.find((dim: KpiDimension) => dim.slug === dimensionId)
  const category = dimension.categories.find((cat) => cat.id === categoryId)
  const kpis = category.kpiIds.map((id) => method.kpisById[id])

  return (
    <Container>
      <Heading size="md" as="h2">
        <MethodI18nFragment text={dimension.name} />: <MethodI18nFragment text={category.name} />
      </Heading>
      <Table>
        <Thead>
          <Tr>
            <Th>Indikator</Th>
            <Th>Resultat</Th>
            <Th>Vurdering</Th>
            <Th textAlign="center">Godkjent</Th>
            <Th> </Th>
          </Tr>
        </Thead>
        <Tbody>
          {kpis.map((kpi) =>
            ratingProfileConfig?.kpis?.[kpi.id]?.enabled !== false ? <KPIBox key={kpi.id} kpi={kpi} /> : null,
          )}
        </Tbody>
      </Table>

      <Routes>
        <Route path=":kpiId" element={<KPIEditor />} />
      </Routes>
    </Container>
  )
}
