import type { Period } from "@esgt/types"
import dayjs from "dayjs"

export type ValidatorResult = {
  valid: boolean
  reason?: string
}

export function validatePeriod(period: Period): ValidatorResult {
  const start = dayjs(`${period.start.year}-${period.start.month}`)
  const end = dayjs(`${period.end.year}-${period.end.month}`).endOf("month")

  if (!start.isBefore(end)) {
    return { valid: false, reason: "Period start is not before end" }
  }

  if (!end.isBefore(new Date())) {
    return { valid: false, reason: "Period has not ended yet" }
  }

  return { valid: true }
}
