// Note: does not support scientific notation
import { type BigNumber, bignumber } from "mathjs"

export function calculateOmpostering(datapoint: BigNumber, amount: BigNumber): BigNumber {
  // If the datapoint being used has not yet been defined, return the amount instead of adding or subtracting it from the datapoint
  if (datapoint === undefined) {
    return amount
  }
  const ret = bignumber(datapoint)

  /*
		If the amount is positive
			if the datapoint is negative
				return datapoint sub amount
			else
				return datapoint add amount
		
		if the amount is negative
			if the datapoint is negative
				return datapoint add amount
			else
				return datapoint sub amount
	*/

  return amount.isPos()
    ? ret.isNeg()
      ? ret.sub(amount)
      : ret.add(amount)
    : ret.isNeg()
      ? ret.add(amount)
      : ret.sub(amount)
}
