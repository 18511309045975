import type { PeriodMonth } from "@esgt/types"
import { type Dayjs, isDayjs } from "dayjs"

const months = [
  "Januar",
  "Februar",
  "Mars",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Desember",
]

type RawData = { start: PeriodMonth; end: PeriodMonth }

export const periodFromRaw = (raw: RawData | undefined) => {
  return raw ? new AccountingPeriodRange(new AccountingPeriod(raw.start), new AccountingPeriod(raw.end)) : undefined
}

export function ratingPeriodString(start?: PeriodMonth, end?: PeriodMonth) {
  if (!start || !end) return null

  return `${months[start.month - 1]} ${start.year} - ${months[end.month - 1]} ${end.year}`
}

export class AccountingPeriod {
  public year: number
  public month: number

  constructor(year: number, month: number)
  constructor(date: Date)
  constructor(date: Dayjs)
  constructor(params: { year: number; month: number })
  constructor(str: string)
  constructor(...args: any[]) {
    if (args[0] instanceof Date) {
      this.year = args[0].getFullYear()
      this.month = args[0].getMonth() + 1
    } else if (typeof args[0] === "string") {
      const [year, month] = args[0].split("-").map((s: string) => Number.parseInt(s, 10))
      this.year = year
      this.month = month
    } else if (isDayjs(args[0])) {
      this.year = args[0].year()
      this.month = args[0].month() + 1
    } else if (typeof args[0] === "number") {
      this.year = args[0]
      this.month = args[1]
    } else if (typeof args[0] === "object" && "year" in args[0]) {
      this.year = args[0].year
      this.month = args[0].month
    } else {
      throw new Error("Invalid input")
    }
  }

  toString() {
    return `${this.year}-${this.month.toString().padStart(2, "0")}`
  }

  toJSON() {
    return this.toString()
  }

  toPrettyString() {
    return `${months[this.month - 1]} ${this.year}`
  }

  diff(otherPeriod: AccountingPeriod) {
    return (this.year - otherPeriod.year) * 12 + (this.month - otherPeriod.month)
  }

  toDate() {
    return new Date(`${this.year}-${this.month.toString().padStart(2, "0")}-01`)
  }

  // valueOf() {
  // 	//
  // }
}

export class AccountingPeriodRange {
  constructor(
    public start: AccountingPeriod,
    public end: AccountingPeriod,
  ) {}

  toString() {
    return `${this.start} - ${this.end}`
  }

  toPrettyString() {
    return `${this.start.toPrettyString()} - ${this.end.toPrettyString()}`
  }

  toShortString() {
    // if period is 12 months and start is month 1, then only return the year, else return the full period
    if (this.start.month === 1 && this.end.month === 12) {
      return `${this.start.year}`
    }
    return `${this.start.month.toString().padStart(2, "0")}/${this.start.year.toString().substring(2)}
     - ${this.end.month.toString().padStart(2, "0")}/${this.end.year.toString().substring(2)}`
  }

  isSame(otherRange: AccountingPeriodRange) {
    return (
      otherRange.start.year === this.start.year &&
      otherRange.start.month === this.start.month &&
      otherRange.end.year === this.end.year &&
      otherRange.end.month === this.end.month
    )
  }

  containsPeriod(period: AccountingPeriod) {
    return period.toString() >= this.start.toString() && period.toString() <= this.end.toString()
  }

  containsRange(range: AccountingPeriodRange) {
    return this.containsPeriod(range.start) && this.containsPeriod(range.end)
  }

  // 2020-12
  // 2020-6
  // 2020-6
  // 2019-9
  get noPeriods() {
    return (this.end.year - this.start.year) * 12 + (this.end.month - this.start.month) + 1
  }

  missingPeriods(ranges: AccountingPeriodRange[]) {
    const missingPeriods: AccountingPeriod[] = []

    for (let year = this.start.year; year <= this.end.year; year++) {
      for (
        let month = year === this.start.year ? this.start.month : 1;
        month <= (year === this.end.year ? this.end.month : 12);
        month++
      ) {
        const period = new AccountingPeriod(year, month)

        if (!ranges.some((range) => range.containsPeriod(period))) {
          missingPeriods.push(period)
        } else {
        }
      }
    }

    let missingPeriodsString = ""
    let previousPeriod: AccountingPeriod | undefined

    for (let i = 0; i < missingPeriods.length; i++) {
      const period = missingPeriods[i]

      if (previousPeriod) {
        if (period.diff(previousPeriod) === 1) {
          const nextPeriod = missingPeriods[i + 1]
          if (nextPeriod && nextPeriod.diff(period) === 1) {
            // No need to mention this period, part of the range
          } else {
            // We finish the period
            missingPeriodsString += ` - ${period.toPrettyString()}`
          }
        } else {
          missingPeriodsString += `, ${period.toPrettyString()}`
        }
      } else {
        missingPeriodsString += period.toPrettyString()
      }

      previousPeriod = period
    }

    return {
      missingPeriodsString,
      noMissingPeriods: missingPeriods.length,
    }
  }
}
