import type { RatingState } from "@esgt/event-store"
import { BusinessArea } from "@esgt/types"

export const makeAdditionalQuestionsCompatible = (
  additionalQuestions: RatingState["additionalQuestions"],
  businessArea = BusinessArea.SMBS,
) => {
  return additionalQuestions.map((additionalQ) => ({
    businessArea,
    id: additionalQ.id,
    questionNumber: additionalQ.questionNumber,
    text: { nb: additionalQ.text, en: additionalQ.text },
    helpText: { nb: additionalQ.helpText || "", en: additionalQ.helpText || "" },
    uploads: additionalQ.uploads ? [{ text: { nb: "", en: "" } }] : [],
    textInputs: additionalQ.textInput ? [{ text: { nb: "", en: "" }, size: "lg" as const }] : [],
    noPrimaryValue: true,
  }))
}
