import { useRating } from "lib/providers/RatingProvider"
import { Navigate, Route, Routes } from "react-router-dom"
import { AltinnRoutes } from "./Altinn/Routes"
import { CompanyTextsRoutes } from "./CompanyTexts/Routes"
import { FinancesRoutes } from "./Finances/Routes"
import { Navigation } from "./Navigation"
import { QuestionsRoutes } from "./Questions/Routes"
import { Results } from "./Results"

export const FoundationRoutes = () => {
  const { ratingState } = useRating()
  return (
    <Routes>
      <Route
        index
        element={<Navigate to={ratingState?.phase === "COMPLETED" ? "results" : "foundation"} replace={true} />}
      />
      <Route
        path="foundation/*"
        element={
          <>
            <Navigation />
            <Routes>
              <Route index element={<Navigate to="company-texts/about-company" replace={true} />} />
              <Route path="company-texts/*" element={<CompanyTextsRoutes />} />
              <Route path="finances/*" element={<FinancesRoutes />} />
              <Route path="altinn/*" element={<AltinnRoutes />} />
              <Route path="questions/*" element={<QuestionsRoutes />} />
            </Routes>
          </>
        }
      />
      <Route path="results/" element={<Results />} />
    </Routes>
  )
}
