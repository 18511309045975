import { Icon, type IconProps } from "@chakra-ui/react"

export const IconModal: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <title>Modal</title>
      <path
        d="M21.1667 4H2.83333C2.3471 4 1.88079 4.21071 1.53697 4.58579C1.19315 4.96086 1 5.46957 1 6V20C1 20.5304 1.19315 21.0391 1.53697 21.4142C1.88079 21.7893 2.3471 22 2.83333 22H21.1667C21.6529 22 22.1192 21.7893 22.463 21.4142C22.8068 21.0391 23 20.5304 23 20V6C23 5.46957 22.8068 4.96086 22.463 4.58579C22.1192 4.21071 21.6529 4 21.1667 4ZM2.83333 20V6H21.1667V20H2.83333Z"
        fill="currentColor"
      />
      <line x1="14" y1="5" x2="14" y2="21" stroke="currentColor" strokeWidth="2" />
    </Icon>
  )
}
