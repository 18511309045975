import { type ApplicationPermissions, doesUserHavePermission } from "@esgt/auth"
import { useCurrentUser } from "lib/providers/CurrentUser"
import type { ReactElement } from "react"

export const Can: React.FC<{
  permission: ApplicationPermissions
  entityId: string
  noAccessComponent?: ReactElement
  children?: React.ReactNode
}> = ({ permission, entityId, noAccessComponent, children }) => {
  const { user } = useCurrentUser()
  return doesUserHavePermission(user, permission, entityId) ? (
    <>{children}</>
  ) : noAccessComponent ? (
    noAccessComponent
  ) : null
}
