import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import { EmailInput } from "components/common/Contact/EmailInput"
import { PhoneInput } from "components/common/Contact/PhoneInput"
import { useEditRatingContactMutation } from "lib/generated/graphql"
import { useRating } from "lib/providers/RatingProvider"
import { type FieldValues, useForm } from "react-hook-form"

interface EditPrimaryContactModalProps {
  onClose: () => void
  refetchContacts: () => void
}

export const EditPrimaryContactModal = ({ onClose, refetchContacts }: EditPrimaryContactModalProps) => {
  const { ratingId, ratingState } = useRating()
  const [editStatus, editContact] = useEditRatingContactMutation()

  const defaultValues: FieldValues = {
    name: ratingState.contactName,
    phone: ratingState.contactPhone,
    email: ratingState.contactEmail,
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    defaultValues,
  })

  if (!ratingState) return null

  // TODO support editing primary contact in new modal
  const save = async (values: any) => {
    const { name, phone, email } = values
    await editContact({ ratingId, name, phone, email })
    refetchContacts()
    onClose()
  }

  return (
    <Modal onClose={onClose} isOpen={true}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(save)}>
        <ModalHeader>Endre kontaktperson</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <FormControl mb={4}>
            <FormLabel htmlFor="name">Navn</FormLabel>
            <Input required id="name" type="text" placeholder="Navn" {...register("name")} />
          </FormControl>

          <EmailInput
            mb={4}
            inputKey="email"
            label="E-postadresse"
            setError={setError}
            clearErrors={clearErrors}
            register={register}
            errors={errors}
          />

          <PhoneInput
            inputKey="phone"
            setError={setError}
            clearErrors={clearErrors}
            register={register}
            errors={errors}
          />
        </ModalBody>

        <ModalFooter gap={2}>
          {!editStatus.fetching && (
            <Button variant="outline" onClick={onClose} isLoading={editStatus.fetching}>
              Avbryt
            </Button>
          )}
          <Button colorScheme="green" size="lg" mr={3} type="submit" isLoading={editStatus.fetching}>
            Lagre
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
