import { Icon, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { ACCOUNT_ROLE_GUI_NAMES, RATING_ROLE_GUI_NAMES } from "@esgt/utils"
import type { AccountListResult, RatingSearchResult } from "lib/generated/graphql"
import { routes } from "lib/routes"
import { GrScorecard } from "react-icons/gr"
import { PiBriefcase } from "react-icons/pi"
import { useNavigate } from "react-router-dom"

interface DashboardTableProps {
  ratings: Partial<RatingSearchResult>[]
  accounts: AccountListResult[]
}

export const DashboardTable: React.FC<DashboardTableProps> = ({ ratings, accounts }) => {
  return (
    <Table variant="roundedTop">
      <Thead>
        <Tr>
          <Th p="20px" width="20%">
            Hensikt
          </Th>
          <Th width="40%">Konto</Th>
          <Th width="25%">Rolle</Th>
          <Th width="15%">Målinger</Th>
        </Tr>
      </Thead>
      <Tbody>
        {ratings?.map((rating, index) => (
          <RatingRow rating={rating} key={`rating-${index}`} />
        ))}
        {accounts?.map((account, index) => (
          <AccountRow account={account} key={`account-${index}`} />
        ))}
      </Tbody>
    </Table>
  )
}

const RatingRow = ({ rating }) => {
  const navigate = useNavigate()
  return (
    <Tr onClick={() => navigate(routes.myRatings())} _hover={{ background: "primary.50" }} cursor="pointer">
      <Td p="20px" flexDirection="row" display="flex">
        <Icon as={PiBriefcase} color="neutral.600" boxSize="24px" alignSelf="baseline" mb="2px" mr="6px" />
        <Text fontSize="lg" fontWeight={500}>
          Målinger
        </Text>
      </Td>
      <Td>
        <Text fontSize="lg">{rating.companyName}</Text>
      </Td>
      <Td>
        <Text fontSize="lg">{RATING_ROLE_GUI_NAMES[rating.userRole]}</Text>
      </Td>
      <Td>
        <Text fontSize="lg">1</Text>
      </Td>
    </Tr>
  )
}

const AccountRow = ({ account }) => {
  const navigate = useNavigate()
  return (
    <Tr onClick={() => navigate(routes.ratings())} _hover={{ background: "primary.50" }} cursor="pointer">
      <Td p="20px" flexDirection="row" display="flex">
        <Icon as={GrScorecard} color="neutral.600" boxSize="20px" alignSelf="baseline" mt="2px" mr="10px" />
        <Text fontSize="lg" fontWeight={500}>
          Kontroll
        </Text>
      </Td>
      <Td>
        <Text fontSize="lg">{account.title}</Text>
      </Td>
      <Td>
        <Text fontSize="lg">{ACCOUNT_ROLE_GUI_NAMES[account.userRole]}</Text>
      </Td>
      <Td>
        <Text fontSize="lg">{account.numOfRatings}</Text>
      </Td>
    </Tr>
  )
}
