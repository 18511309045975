import { filterCategoriesWithoutQuestions } from "@esgt/helpers"
import { MethodI18nFragment } from "components/I18nText"
import { useRating } from "lib/providers/RatingProvider"
import { routes } from "lib/routes"
import { useMemo } from "react"

type FoundationNav = {
  title: string
  items: { label: string | JSX.Element; to: string; completed: boolean; readOnly: boolean }[]
  readOnly: boolean
  completed: boolean
}[]

export const useFoundationNav = (): FoundationNav => {
  const { isFree, submissionProgress, ratingState, ratingProfileConfig, method, ratingId } = useRating()
  const rootPath = routes.rating(ratingId, "foundation")

  const questionCategories = filterCategoriesWithoutQuestions(
    ratingState,
    method?.config,
    method?.config.questionCategories,
    ratingProfileConfig,
  )

  const phaseIsSubmission = ["SUBMISSION", "PROCESSING_DATAFILES"].includes(ratingState?.phase)
  return useMemo(() => {
    const readOnly = {
      companyTexts:
        !phaseIsSubmission || (ratingState?.reopenedScopes && !ratingState?.reopenedScopes?.includes("companyTexts")),
      finances:
        !phaseIsSubmission || (ratingState?.reopenedScopes && !ratingState?.reopenedScopes?.includes("finances")),
      altinn: !phaseIsSubmission || (ratingState?.reopenedScopes && !ratingState?.reopenedScopes?.includes("altinn")),
      questions:
        !phaseIsSubmission || (ratingState?.reopenedScopes && !ratingState?.reopenedScopes?.includes("questions")),
      AdditionalQuestions:
        !phaseIsSubmission ||
        (ratingState?.reopenedScopes && !ratingState?.reopenedScopes?.includes("additionalQuestions")),
    }

    const questionsNavItems =
      submissionProgress &&
      [
        ratingState?.additionalQuestions?.length > 0 && {
          label: "Tilleggsspørsmål",
          to: `${rootPath}/questions/additional-questions`,
          completed: submissionProgress.questions.Tilleggsspørsmål,
          readOnly: readOnly.AdditionalQuestions,
        },
        ...questionCategories.map((category) => ({
          label: <MethodI18nFragment text={category.name} />,
          to: `${rootPath}/questions/${encodeURIComponent(category.slug)}`,
          completed: submissionProgress.questions[category.id],
          readOnly: readOnly.questions,
        })),
      ].filter(Boolean)

    return submissionProgress
      ? ([
          {
            title: "Virksomhet og bærekraft",
            items: [
              {
                label: "Om virksomheten",
                to: `${rootPath}/company-texts/about-company`,
                completed: submissionProgress.companyTexts?.aboutCompany,
                readOnly: readOnly.companyTexts,
              },
              !isFree && {
                label: "Bærekraftsmål",
                to: `${rootPath}/company-texts/company-goals`,
                completed: submissionProgress.companyTexts?.companyGoals,
                readOnly: readOnly.companyTexts,
              },
              !isFree && {
                label: "Bærekraftsstrategi",
                to: `${rootPath}/company-texts/company-strategy`,
                completed: submissionProgress.companyTexts?.companyStrategy,
                readOnly: readOnly.companyTexts,
              },
            ].filter(Boolean),
            readOnly: readOnly.companyTexts,
            completed: submissionProgress.companyTextsStatus === "COMPLETED",
          },
          {
            title: "Regnskap",
            items: [
              {
                label: "SAF-T",
                to: `${rootPath}/finances/saft`,
                completed: submissionProgress.regnskap.saft,
                readOnly: readOnly.finances,
              },
              !isFree && {
                label: "Saldobalanse",
                to: `${rootPath}/finances/saldobalanse`,
                completed: submissionProgress.regnskap.saldobalanse,
                readOnly: readOnly.finances,
              },
              !isFree && {
                label: "Aldersfordelt saldoliste",
                to: `${rootPath}/finances/aldersfordelt-saldoliste`,
                completed: submissionProgress.regnskap.aldersfordeltSaldoListe,
                readOnly: readOnly.finances,
              },
            ].filter(Boolean),
            readOnly: readOnly.finances,
            completed: submissionProgress.regnskapStatus === "COMPLETED",
          },
          {
            title: "Altinn",
            items: [
              {
                label: "Avstemmingsinformasjon",
                to: `${rootPath}/altinn/avstemmingsinformasjon`,
                completed: submissionProgress.altinn.A07,
                readOnly: readOnly.altinn,
              },
            ].filter(Boolean),
            readOnly: readOnly.altinn,
            completed: submissionProgress.altinnStatus === "COMPLETED",
          },
          {
            title: "Spørsmål",
            items: questionsNavItems,
            readOnly: readOnly.questions && readOnly.AdditionalQuestions,
            completed: submissionProgress.questionsStatus === "COMPLETED",
          },
        ] as FoundationNav)
      : []
  }, [ratingState, method, ratingProfileConfig])
}
