import { assertCUID } from "@esgt/cuid"
import type { BenchmarkLevel, CUID, KpiBenchmarkVariable, KpiEnabledVariable, KpiMathVariable } from "@esgt/types"

export const kpiIdToScoreLabel = (kpiId: CUID) => {
  return `kpi_${kpiId}` as KpiMathVariable
}

export const kpiIdToBenchmarkLabel = (kpiId: CUID, benchmark: BenchmarkLevel) => {
  return `kpi_${kpiId}_benchmark_${benchmark}` as KpiBenchmarkVariable
}

export const kpiMathVariableToKpiId = (kpiScoreLabel: KpiMathVariable) => {
  const id = kpiScoreLabel.replace("kpi_", "")
  assertCUID(id)
  return id
}

export const kpiMathVariableToEnabledVariable = (kpiScoreLabel: KpiMathVariable): KpiEnabledVariable => {
  return `${kpiScoreLabel}_enabled`
}

export const kpiIdToEnabledVariable = (id: CUID): KpiEnabledVariable => {
  const kpiScoreLabel = kpiIdToScoreLabel(id)
  return kpiMathVariableToEnabledVariable(kpiScoreLabel)
}
