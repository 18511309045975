import { Button, Flex, FormControl, FormLabel, PinInput, PinInputField, Spinner } from "@chakra-ui/react"
import { ErrorMessage } from "components/ErrorMessage"
import { type JWTPayload, decodeJwt } from "jose"
import * as auth from "lib/auth"
import { useState } from "react"
import styled from "styled-components"
import { Countdown } from "./Countdown"

const Form = styled.form`
  overflow: visible;
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
`

interface FormValues {
  otp: string
}

interface EnterOtpProps {
  token: string
  onLogin: () => void
  onResend: () => void
}

const getTokenData = (token: string) => {
  const { email, nextUrl, iat, exp } = decodeJwt(token) as JWTPayload & { email: string; nextUrl?: string }
  return { email, nextUrl, issuedAt: (iat || 0) * 1000, expiresAt: (exp || 0) * 1000 }
}

export const EnterOtp: React.FC<EnterOtpProps> = ({ token, onLogin, onResend }) => {
  const tokenData = getTokenData(token)
  const [otp, setOtp] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()

  const login = async ({ otp }: FormValues) => {
    setLoading(true)

    try {
      const data = await auth.getLoginToken({ otp, token })

      localStorage.setItem("r8me-token", data.token)

      onLogin()
    } catch (e) {
      switch (e.message) {
        case "Invalid otp": {
          setError("Feil engangskode")
          setOtp("")
          break
        }
        default: {
          setError("Noe gikk galt, prøv igjen senere")
        }
      }
    } finally {
      setLoading(false)
    }
  }

  const { issuedAt, expiresAt } = tokenData

  return (
    <>
      {error && <ErrorMessage title="Innlogging feilet" error={error} status="error" />}
      <Form>
        <FormControl>
          <FormLabel>Engangskode</FormLabel>
          <small>Vi har sendt deg en epost med engangskode</small>
          <Flex alignItems="center" gap={4} mt={2}>
            <PinInput
              value={otp}
              autoFocus
              isDisabled={loading}
              size="lg"
              onChange={setOtp}
              onComplete={(otp) => login({ otp })}
              otp
            >
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
            {loading && <Spinner size="lg" color="green.400" />}
          </Flex>
          <Countdown onTimeout={onResend} startsAt={issuedAt} expiresAt={expiresAt} mt={6} />
          <Button variant="link" onClick={onResend} size="sm">
            Send engangskode på nytt
          </Button>
        </FormControl>
      </Form>
    </>
  )
}
