import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import { type CurrentUserQuery, UserRatingRole } from "lib/generated/graphql"
import { useCallback, useEffect, useMemo, useState } from "react"
import type { RaterOption } from "../../../RaterOption"
import type { RaterPickerRow } from "./RaterPickerRow"
import { RaterPickerUserRow } from "./RaterPickerUserRow"

type User = CurrentUserQuery["currentUser"]

interface RaterPickerProps {
  ratingOwnerId?: number
  users: Array<User>
  raters: Array<RaterOption>
  setRaters: (raters: Array<RaterOption>) => void
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const RaterPicker = ({ users, raters, setRaters, ratingOwnerId, isOpen, setIsOpen }: RaterPickerProps) => {
  const [rows, updateRow, recreateRows] = useRaterPickerRows(users, raters, ratingOwnerId)

  const submit = useCallback(() => {
    const selectedRaters = rows
      .filter((rater) => rater.isSelected)
      .map((rater) => ({ user: rater.user, role: rater.role }))

    setRaters(selectedRaters)
    setIsOpen(false)
  }, [rows, setIsOpen, setRaters])

  const resetAndClose = useCallback(() => {
    recreateRows()
    setIsOpen(false)
  }, [recreateRows, setIsOpen])

  return (
    <Modal size="xl" isOpen={isOpen} onClose={resetAndClose} closeOnEsc>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Oppdater kontrollører</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Table>
            <Thead>
              <Tr>
                <Th />
                <Th>Navn</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {rows?.map(({ user, isSelected }) => (
                <RaterPickerUserRow
                  key={user.id}
                  user={user}
                  updateRater={updateRow}
                  isSelected={isSelected}
                  isOwner={ratingOwnerId && user.id === ratingOwnerId}
                />
              ))}
            </Tbody>
          </Table>
        </ModalBody>

        <ModalFooter>
          <Flex gap={3}>
            <Button variant="outline" onClick={resetAndClose}>
              Avbryt
            </Button>

            <Button colorScheme="green" onClick={submit}>
              Fullfør
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

function useRaterPickerRows(
  users: Array<User>,
  raters: Array<RaterOption>,
  accountOwnerId?: number,
): [Array<RaterPickerRow>, (userId: number, value: RaterPickerRow) => void, () => void] {
  const usersWithOwnerFirst = useMemo(() => {
    return users.sort(({ id }) => (id === accountOwnerId ? -1 : 1))
  }, [accountOwnerId, users])

  const createRaterRows = useCallback(() => {
    return usersWithOwnerFirst.map((user) => createRaterPickerRow(user, raters, accountOwnerId))
  }, [accountOwnerId, raters, usersWithOwnerFirst])

  const [rows, setRows] = useState<Array<RaterPickerRow>>(createRaterRows)

  const updateRow = useCallback((userId: number, value: RaterPickerRow) => {
    setRows((list) => {
      return replaceWhere(list, value, (rater) => rater.user.id === userId)
    })
  }, [])

  const recreateRows = useCallback(() => setRows(createRaterRows()), [createRaterRows])

  // Update table rows when base rater data is changed
  useEffect(() => {
    recreateRows()
  }, [recreateRows, raters])

  return [rows, updateRow, recreateRows]
}

function createRaterPickerRow(user: User, raters: Array<RaterOption>, accountOwnerId?: number): RaterPickerRow {
  const isAccountOwner = user.id === accountOwnerId
  const existingRater = raters.find((rater) => rater.user.id === user.id)
  const isSelected = Boolean(isAccountOwner || existingRater)

  if (existingRater) {
    return { ...existingRater, isSelected }
  }
  return {
    user,
    isSelected,
    role: isAccountOwner ? UserRatingRole.Owner : UserRatingRole.Rater,
  }
}

function replaceWhere<T>(array: Array<T>, newValue: T, condition: (t: T) => boolean): Array<T> {
  return array.map((value) => {
    return condition(value) ? newValue : value
  })
}
