import type { TranslatedText } from "@esgt/types"
import type { i18n as I18nType } from "i18next"

type Join<K, P, S extends string = "."> = K extends string | number
  ? P extends string | number
    ? `${K}${"" extends P ? "" : S}${P}`
    : never
  : never

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]]

export type Leaves<T, D extends number = 10, S extends string = "."> = [D] extends [never]
  ? never
  : T extends object
    ? {
        [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D], S>, S>
      }[keyof T]
    : ""

export const flattenObject = (obj: object, prefix = "") => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const newKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === "object" && value !== null) {
      const flattened = flattenObject(value, newKey)
      return Object.assign(acc, flattened)
    }

    return Object.assign(acc, { [newKey]: replaceLinks(value) })
  }, {})
}

const replaceLinks = (text: string) => {
  // biome-ignore lint/correctness/noEmptyCharacterClassInRegex: I'm not confident enough in regex to fix this
  return text.replace(/([^[]https:\/\/[^\s<]+[^]])/g, " [$1]($1) ")
}

export function getLanguage(text: TranslatedText, i18n: I18nType): string {
  return text[i18n.language] || text.nb || text.en
}
