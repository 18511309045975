import { Box, Heading, Table, TableContainer, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import { utcToZonedTime } from "date-fns-tz"
import { formatDate } from "lib/helpers/formatDate"
import { useRating } from "lib/providers/RatingProvider"
import type { ReactNode } from "react"

export const OrderInfo: React.FC = () => {
  const { ratingState, method, ratingProfile } = useRating()

  if (!ratingState || !method || !ratingProfile) return null

  const reportCreatedAt = utcToZonedTime(new Date(), "Europe/Oslo")

  return (
    <>
      <Heading fontWeight={600} fontSize={"12pt"} mb={"0mm"}>
        {ratingState.brreg.navn} ({ratingState?.brreg.organisasjonsnummer.substring(0, 3)}{" "}
        {ratingState?.brreg.organisasjonsnummer.substring(3, 6)}{" "}
        {ratingState?.brreg.organisasjonsnummer.substring(6, 9)})
      </Heading>

      <Box mb="1mm" fontWeight="100">
        {ratingState.brreg.forretningsadresse.adresse.map((a) => a)}
        <br />
        {ratingState.brreg.forretningsadresse.postnummer} {ratingState.brreg.forretningsadresse.poststed}
        <br />
        {ratingState.brreg.forretningsadresse.land}
      </Box>

      <TableContainer>
        <Table width={"100%"} variant="simple">
          <Tbody>
            <Row
              label="Næringskode"
              value={
                <>
                  {ratingState.brreg.naeringskode1 ? ratingState.brreg.naeringskode1.kode : " - "}
                  <br />
                  {ratingState.brreg.naeringskode1 ? ratingState.brreg.naeringskode1.beskrivelse : ""}
                </>
              }
            />

            <Row label="Siste innsendte årsregnskap" value={ratingState.brreg.sisteInnsendteAarsregnskap} />

            <Row
              label="Registreringsdato i enhetsregisteret"
              value={ratingState.brreg.registreringsdatoEnhetsregisteret}
            />

            {ratingState.brreg.registrertIMvaregisteret && (
              <Row
                label="Registrert i MVA-registeret"
                value={ratingState.brreg.registrertIMvaregisteret ? "Ja" : "Nei"}
              />
            )}

            {ratingState.brreg.registrertIForetaksregisteret && (
              <Row
                label="Registrert i foretaksregisteret"
                value={ratingState.brreg.registrertIForetaksregisteret ? "Ja" : "Nei"}
              />
            )}

            {ratingState.brreg.registrertIStiftelsesregisteret && (
              <Row
                label="Registrert i stiftelsesregisteret"
                value={ratingState.brreg.registrertIStiftelsesregisteret ? "Ja" : "Nei"}
              />
            )}

            {ratingState.brreg.registrertIFrivillighetsregisteret && (
              <Row
                label="Registrert i frivillighetsregisteret"
                value={ratingState.brreg.registrertIFrivillighetsregisteret ? "Ja" : "Nei"}
              />
            )}

            {ratingState.brreg.underTvangsavviklingEllerTvangsopplosning && (
              <Row
                label="Under tvangsavvikling eller tvangsoppløsning"
                value={ratingState.brreg.underTvangsavviklingEllerTvangsopplosning ? "Ja" : "Nei"}
              />
            )}

            <Row
              label="Kontaktperson bærekraft"
              value={
                <>
                  {ratingState.contactName}
                  <br />
                  {ratingState.contactEmail}
                  <br />
                  {ratingState.contactPhone}
                </>
              }
            />

            <Row label="Målingsprofil" value={`${ratingProfile.id} (${ratingProfile.name})`} />

            <Row
              label="Metodeversjon"
              value={`${method.id} (${formatDate(new Date(method.createdAt), "dd.MM.yyyy")})`}
            />

            <Row label="Tidspunkt for rapport" value={formatDate(reportCreatedAt, "dd.MM.yyyy HH:mm:ss")} />
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}

const Row = ({ label, value }: { label: string; value: string | ReactNode }) => {
  return (
    <Tr>
      <Td padding={2}>
        <TableText>{label}</TableText>
      </Td>
      <Td padding={2}>
        <TableText>{value}</TableText>
      </Td>
    </Tr>
  )
}

const TableText = ({ children }) => (
  <Text whiteSpace={"break-spaces"} maxWidth="100%" margin={0} fontSize={"12pt"}>
    {children}
  </Text>
)
