import { Box, Flex, Icon } from "@chakra-ui/react"
import { extendedCalculateKpi } from "@esgt/method-structure"
import type { Kpi, KpiCategory } from "@esgt/types"
import { MethodI18nFragment } from "components/I18nText"
import { MarkdownText } from "components/MarkdownText"
import { getLanguage } from "lib/i18n/utils"
import { useRating } from "lib/providers/RatingProvider"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { PreviewWatermark } from "../components"
import { BenchmarkBox } from "./BenchmarkBox"

const Container = styled.div`
	break-inside: avoid;
	position: relative;
`

const CircleIcon = (props: any) => (
  <Icon viewBox="0 0 100 100" {...props} width="18px" height="18px">
    <circle cx="50" cy="50" r="40" fill="currentColor" />
  </Icon>
)

export const KPI: React.FC<{
  kpi: Kpi
  category: KpiCategory
}> = ({ kpi, category }) => {
  const { ratingState, ratingProfileConfig, method, isFree } = useRating()
  const { i18n } = useTranslation()
  const calculatedKPI = useMemo(() => {
    if (!ratingState) return null
    if (!ratingProfileConfig) return null
    return extendedCalculateKpi(
      kpi,
      ratingProfileConfig,
      ratingState.datapoints,
      ratingState.anomalies,
      ratingState.phase,
      ratingState.overriddenKpisById,
      method.kpisById,
    )
  }, [ratingProfileConfig, kpi, ratingState, method])

  if (!ratingState) return null

  return (
    calculatedKPI.shouldBeIncluded && (
      <Container>
        <PreviewWatermark />
        <Box py="25px">
          <Box fontSize="11px" mb="4px" fontWeight="300">
            <MethodI18nFragment text={category.name} />
          </Box>
          <Flex flexFlow="row nowrap" justifyContent="space-between" mb="8px" alignItems="center">
            <Box fontSize="19px" fontWeight="300">
              <MethodI18nFragment text={kpi.name} />
            </Box>
            <Box>
              <Flex flexFlow="row nowrap" alignItems="center" gap="7px" fontSize="14px">
                {calculatedKPI.text.toUpperCase()}
                <CircleIcon color={calculatedKPI.color} />
              </Flex>
            </Box>
          </Flex>
          <Box width="65px" height="1px" boxShadow="inset 0 -0.3px 0 0 black" mb="25px" />
          <Box fontSize="13px" mb="3px" lineHeight="20px">
            <Box mb="20px">
              <MarkdownText markdown={getLanguage(kpi.description, i18n)} />
            </Box>
            <Box mb="14px">
              <Box mb="6px">
                <MarkdownText markdown={`Definisjon: \n${getLanguage(kpi.definition, i18n)}`} />
              </Box>
              {kpi.formulaDescription && (
                <Box mb="6px">
                  <MarkdownText markdown={`Formel: \n${getLanguage(kpi.formulaDescription, i18n)}`} />
                </Box>
              )}
            </Box>
            <>
              <Box mb="7px" fontWeight="400">
                RESULTAT
              </Box>

              <Box
                fontSize="16px"
                width="auto"
                display="flex"
                flexFlow="column nowrap"
                alignItems="flex-start"
                mb="30px"
                fontWeight="500"
              >
                <Box>
                  {isFree ? (
                    <Box fontStyle="italic" fontSize="14px">
                      <Box>Resultatet er ikke verifisert eller kontrollert av tredjepart</Box>
                      <Box>Oppgrader til Full Versjon - For å se utregning og resultat</Box>
                    </Box>
                  ) : kpi.censor ? (
                    <>
                      <Flex flexFlow="row nowrap" alignItems="center" gap="7px" mt="2px">
                        <CircleIcon color={calculatedKPI.color} />
                        {calculatedKPI.text}
                      </Flex>
                      <Box fontSize="13px" fontWeight="300" fontStyle="italic" mt="3px">
                        Tallet er sensurert av personvernhensyn ved innsyn i lønnsopplysninger.
                      </Box>
                    </>
                  ) : calculatedKPI.value !== undefined ? (
                    <>
                      {calculatedKPI.value.toLocaleString("no")} {getLanguage(kpi.unit, i18n)}{" "}
                    </>
                  ) : (
                    "-"
                  )}
                  <Box borderTop="0.7pt double black" borderBottom="0.7pt double black" height="2px" mt="5" />
                </Box>
              </Box>
            </>
          </Box>
          {calculatedKPI.benchmarkLevel !== undefined && <BenchmarkBox kpi={kpi} calculatedKPI={calculatedKPI} />}
        </Box>
      </Container>
    )
  )
}
