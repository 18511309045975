import { Box, Button, Flex, Tooltip } from "@chakra-ui/react"
import { doesUserHavePermission } from "@esgt/auth"
import { shortenString } from "@esgt/utils"
import { CompleteOrSubmitRating } from "components/CompleteOrSubmitRating"
import { useBreadcrumb } from "components/breadcrumbs/BreadcrumbProvider"
import { Breadcrumbs } from "components/breadcrumbs/Breadcrumbs"
import { useCurrentUser } from "lib/providers/CurrentUser"
import { useRating } from "lib/providers/RatingProvider"
import { routes } from "lib/routes"
import type { ReactNode } from "react"
import { NavLink } from "react-router-dom"

export const RatingHeader = () => {
  const { ratingState, ratingId } = useRating()
  const { user, account } = useCurrentUser()
  const canUserControlRating =
    doesUserHavePermission(user, "rating:control", ratingId) ||
    doesUserHavePermission(user, "account:rating:create", String(account?.id))

  if (ratingState?.brreg?.navn) {
    useBreadcrumb(`${shortenString(ratingState?.brreg?.navn)} ${ratingState?.period?.toShortString()}`, ".")
  }
  return (
    <>
      <Box
        backgroundColor="white"
        borderBottom="1px solid"
        borderColor="neutral.100"
        position="sticky"
        top="0"
        px="8"
        py="4"
        zIndex="100"
      >
        <Flex flexFlow="row" flexWrap="nowrap" alignItems="center">
          <Flex basis="1" grow="1">
            <Breadcrumbs />
          </Flex>
          <Flex justify="center" gap="8px">
            <NavButton to={routes.rating(ratingId, "foundation")}>Legg inn data</NavButton>

            <ReportButton to={routes.rating(ratingId, "results")} disabled={ratingState?.phase !== "COMPLETED"}>
              Rapport
            </ReportButton>
            {canUserControlRating && <NavButton to={routes.ratings(ratingId)}>Til kontroll</NavButton>}
          </Flex>
          <Flex basis="1" grow="1" justify="right">
            <CompleteOrSubmitRating />
          </Flex>
        </Flex>
      </Box>
    </>
  )
}

const NavButton = ({ children, to, disabled }: { children: ReactNode; to: string; disabled?: boolean }) => (
  <Button
    as={NavLink}
    to={to}
    variant="ghost"
    _activeLink={{ backgroundColor: "neutral.100", color: "neutral.800" }}
    _hover={{ backgroundColor: "neutral.100" }}
    color="neutral.600"
    isDisabled={disabled}
    onClick={(e) => disabled && e.preventDefault()}
  >
    {children}
  </Button>
)

const ReportButton = ({ to, disabled }: { children: ReactNode; to: string; disabled?: boolean }) => (
  <>
    {disabled ? (
      <Tooltip
        borderRadius="4px"
        mr="4"
        p="4"
        hasArrow
        label="Rapport fanen er ikke tilgjengelig før målingen er fullført"
      >
        <Box>
          <NavButton to={to} disabled={disabled}>
            Rapport
          </NavButton>
        </Box>
      </Tooltip>
    ) : (
      <NavButton to={to} disabled={disabled}>
        Rapport
      </NavButton>
    )}
  </>
)
