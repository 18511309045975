import { Flex } from "@chakra-ui/react"
import type { RatingState } from "@esgt/event-store"
import { validatePeriod } from "@esgt/utils"
import { RatingPeriodSelector } from "components/RatingPeriodSelector"
import { addMonths } from "date-fns"
import { type DateTimePeriodInput, useChangeRatingPeriodMutation } from "lib/generated/graphql"
import { useResultToast } from "lib/hooks"
import { useCallback, useMemo } from "react"
import { type FieldValues, useForm } from "react-hook-form"
import { useBoolean, useUpdateEffect } from "usehooks-ts"
import { OrderPropertyRow } from "./OrderPropertyRow"

interface OrderPeriodProps {
  ratingId: string
  ratingState: RatingState
}

function ratingStateToFormState(ratingState: RatingState) {
  return {
    periodStartYear: ratingState.period.start.year,
    periodStartMonth: ratingState.period.start.month,
    periodLengthMonths: ratingState.period.end.diff(ratingState.period.start) + 1,
  }
}

export const OrderPeriod = ({ ratingId, ratingState }: OrderPeriodProps) => {
  const [changePeriodResult, changePeriod] = useChangeRatingPeriodMutation()

  const {
    register,
    watch,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues: ratingStateToFormState(ratingState) as FieldValues,
  })

  // Update what the "unchanged" state is (wrt isDirty, etc)
  useUpdateEffect(() => {
    reset(ratingStateToFormState(ratingState))
  }, [ratingState, reset])

  const values = watch()

  const periodValidity = useMemo(() => {
    const period = periodEndPoints(values.periodStartYear, values.periodStartMonth, values.periodLengthMonths)
    return validatePeriod(period)
  }, [values.periodLengthMonths, values.periodStartMonth, values.periodStartYear])

  const { value: isEditingPeriod, setTrue: setEditingPeriod, setFalse: setNotEditingPeriod } = useBoolean()

  const submitPeriod = useCallback(() => {
    setNotEditingPeriod()
    const period = periodEndPoints(values.periodStartYear, values.periodStartMonth, values.periodLengthMonths)
    changePeriod({
      ratingId,
      startYear: period.start.year,
      startMonth: period.start.month,
      endYear: period.end.year,
      endMonth: period.end.month,
    })
  }, [changePeriod, ratingId, setNotEditingPeriod, values])

  useResultToast(changePeriodResult, "Periode oppdatert", "Kunne ikke oppdatere periode")

  return (
    <OrderPropertyRow
      title="Periode"
      infoBody="helpTexts.ratingSettings.orderPeriod"
      onEditStart={setEditingPeriod}
      onEditEnd={setNotEditingPeriod}
      onSubmit={submitPeriod}
      isDisabled={isEditingPeriod && !periodValidity.valid}
      isEditing={isEditingPeriod}
      hasChanged={isDirty}
    >
      <Flex direction="column" gap={6} maxWidth="400px">
        <RatingPeriodSelector
          isDisabled={!isEditingPeriod}
          register={register}
          startYear={ratingState.period.start.year}
          startMonth={ratingState.period.start.month}
          periodValidity={periodValidity}
        />
      </Flex>
    </OrderPropertyRow>
  )
}

function periodEndPoints(startYear: number, startMonth: number, lengthMonths: number): DateTimePeriodInput {
  // Using `startMonth - 1` because AccountingPeriod uses 1-indexed months (unlike Date)
  const startDate = new Date(startYear, startMonth - 1, 1)

  // adding month count minus one, because ends are inclusive; tracking only 1 month would be 1 in "length" but 0 in month diff
  const endDate = addMonths(startDate, lengthMonths - 1)

  return {
    start: { year: startYear, month: startMonth },
    end: { year: endDate.getFullYear(), month: endDate.getMonth() + 1 },
  }
}
