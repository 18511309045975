import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { UserRatingRole } from "@esgt/types"
import { RATING_ROLE_GUI_NAMES } from "@esgt/utils"
import type { RaterOption } from "../../../RaterOption"
import { FadedLabel } from "../../FadedLabel"

interface RaterTableProps {
  raters: Array<RaterOption>
}

export const RaterTable = ({ raters }: RaterTableProps) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th paddingLeft={0}>Navn</Th>
          <Th>Epost</Th>
          <Th />
        </Tr>
      </Thead>

      <Tbody>
        {raters.map((rater) => (
          <RaterTableRow rater={rater} key={rater.user.id} />
        ))}
      </Tbody>
    </Table>
  )
}

interface RaterTableRowProps {
  rater: RaterOption
}

const RaterTableRow = ({ rater }: RaterTableRowProps) => {
  return (
    <Tr>
      <Td paddingLeft={0}>
        <Text>{rater.user.name}</Text>
      </Td>

      <Td>
        <Text>{rater.user.email}</Text>
      </Td>

      <Td textAlign="right">
        <FadedLabel highlighted={rater.role === UserRatingRole.Owner}>{RATING_ROLE_GUI_NAMES[rater.role]}</FadedLabel>
      </Td>
    </Tr>
  )
}
