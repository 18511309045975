import { SimpleGrid } from "@chakra-ui/react"
import type { RatingType } from "@esgt/types"
import type { Rating, RatingState } from "../types"
import { RatingCard } from "./RatingCard"

interface RatingCardsProps {
  loading?: boolean
  ratings: Rating[]
  count?: number
  state: RatingState | RatingType.Free
}

export const RatingCards: React.FC<RatingCardsProps> = ({ loading, ratings, count, state }) => {
  return (
    <SimpleGrid minChildWidth="580px" gap="56px">
      {loading
        ? Array.from({ length: count }).map((_, i) => <RatingCard key={i} loading state={state} />)
        : ratings.map((rating, i) => <RatingCard key={rating.key} fadeDuration={i} rating={rating} state={state} />)}
    </SimpleGrid>
  )
}
