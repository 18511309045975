import { Flex } from "@chakra-ui/react"
import type { MethodConfig } from "@esgt/types"
import { useBreadcrumb } from "components/breadcrumbs/BreadcrumbProvider"

type MethodKpisProps = {
  config: MethodConfig
}

export const MethodKpis = ({ config }: MethodKpisProps) => {
  useBreadcrumb("Method", "/admin/method/kpis")

  return (
    <Flex direction="column">
      <div>
        <pre>{JSON.stringify(config.kpiDimensions, null, 2)}</pre>
        <hr />
        <pre>{JSON.stringify(config.kpis, null, 2)}</pre>
      </div>
    </Flex>
  )
}
