import { ArrowBackIcon, InfoOutlineIcon } from "@chakra-ui/icons"
import {
  Accordion,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import { DataPointSources } from "@esgt/helpers"
import { extendedCalculateKpi } from "@esgt/method-structure"
import type { RatingDatapoints } from "@esgt/types"
import { Feedback } from "components/Feedback"
import { I18nText, MethodI18nFragment, MethodI18nText } from "components/I18nText"
import { InfoTooltipIcon } from "components/Tooltips/InfoTooltipIcon"
import { useSetRatingDatapointsMutation } from "lib/generated/graphql"
import { useIsSuperAdmin } from "lib/helpers/useIsRole"
import type { I18nKeys } from "lib/i18n/nb"
import { getLanguage } from "lib/i18n/utils"
import { useRating } from "lib/providers/RatingProvider"
import type React from "react"
import { useCallback, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { useBoolean } from "usehooks-ts"
import { CalculationOutput } from "./CalculationOutput"
import { Datapoint } from "./Datapoint"
import { KpiOverrider } from "./KpiOverrider"
import { getUploadsByQuestionId } from "./getUploadsByQuestionId"
import { useQuestionsByDatapoint } from "./useQuestionsByDatapoint"

export const KPIEditor: React.FC = () => {
  const navigate = useNavigate()
  const { dimensionId, categoryId, kpiId } = useParams()
  const {
    ratingState,
    ratingId,
    ratingProfile: { methodVersion, businessArea },
    ratingProfileConfig,
    method,
    isEditable,
  } = useRating()

  const uploadsByQuestionId = getUploadsByQuestionId(ratingState.uploads)

  const questionsByDatapoint = useQuestionsByDatapoint(method)

  const [setDatapointsStatus, setDatapoints] = useSetRatingDatapointsMutation()
  const kpi = useMemo(() => {
    return method.config.kpis.find((k) => k.id === kpiId)
  }, [method, kpiId])

  if (!kpi) throw new Error(`KPI with id ${kpiId} not found`)

  const useFormMethods = useForm({
    defaultValues: kpi.datapoints.reduce(
      (memo, k) =>
        Object.assign(memo, {
          [k]: ratingState?.datapoints[k]?.toString(),
        }),
      {} as Record<string, string>,
    ),
  })

  const {
    watch,
    handleSubmit,
    formState: { isDirty },
  } = useFormMethods

  const formValues = watch()

  const override = ratingState.overriddenKpisById?.[kpi.id]

  const calculatedKpi = useMemo(() => {
    if (kpi.formula && ratingProfileConfig) {
      const datapoints = { ...ratingState.datapoints, ...formValues } as RatingDatapoints
      return extendedCalculateKpi(
        kpi,
        ratingProfileConfig,
        datapoints,
        ratingState.anomalies,
        ratingState.phase,
        ratingState.overriddenKpisById,
        method.kpisById,
      )
    }
  }, [kpi, formValues, ratingProfileConfig, method])

  const {
    value: isCalculationPanelOpen,
    setTrue: openCalculationPanel,
    setFalse: closeCalculationPanel,
  } = useBoolean(false)
  const { value: isFormulaShown, setTrue: showFormula, setFalse: hideFormula } = useBoolean(false)
  const { value: isGuidanceShown, setTrue: showGuidance, setFalse: hideGuidance } = useBoolean(false)

  const isSuperAdmin = useIsSuperAdmin()
  const onCalculationHeaderClicked = useCallback(
    (event: React.MouseEvent<HTMLHeadingElement>) => {
      if (isSuperAdmin && event.shiftKey) {
        // eslint-disable-next-line -- intentional debug logging upon request
        console.log(
          `KPI "${kpi.name.nb}" (${businessArea}/${methodVersion}):`,
          "\n- ratingState.datapoints:",
          ratingState.datapoints,
          "\n- ratingState.anomalies:",
          ratingState.anomalies,
          "\n- ratingProfileConfig:",
          ratingProfileConfig,
          "\n- kpi:",
          kpi,
          "\n- formValues:",
          formValues,
          "\n- calculatedKpi:",
          calculatedKpi,
        )
      }
    },
    [
      businessArea,
      calculatedKpi,
      formValues,
      isSuperAdmin,
      kpi,
      methodVersion,
      ratingProfileConfig,
      ratingState.datapoints,
    ],
  )

  if (!categoryId || !dimensionId || !kpiId || !ratingState) {
    return null
  }

  const close = () => navigate("..")

  const onSubmit = async (data: Record<string, string | number | null | undefined>) => {
    // DISABLED for now, perhaps there should be a button for using the extracted data point value
    // for (const [key, val] of Object.entries(data)) {
    // 	if (!val.length) delete data[key]
    // }
    if (isDirty) {
      const newDatapoints = Object.entries(data).reduce((obj, [key, value]) => {
        obj[key] = typeof value === "number" ? value.toString() : value
        return obj
      }, {})

      await setDatapoints({ datapoints: newDatapoints, ratingId })
    }

    close()
  }
  const { i18n } = useTranslation()

  return (
    <Modal isOpen={true} onClose={close} size="xl">
      <ModalOverlay />

      <ModalContent maxWidth="1250px">
        <FormProvider {...useFormMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody p={0}>
              <Flex height="80vh">
                <Flex width="625px" mb="32px" direction="column">
                  <Heading size="h3" m="32px">
                    {kpi.kpiNumber} <MethodI18nFragment text={kpi.name} />
                    <InfoTooltipIcon label={getLanguage(kpi.definition, i18n)} placement="right" />
                  </Heading>
                  {kpi?.datapoints?.length > 0 ? (
                    <>
                      <Box>
                        <Grid m="0 16px 16px 32px" gap="16px" templateColumns="1fr 170px 32px" alignItems="center">
                          <Heading size="h5">Datapunkt ({kpi.datapoints.length})</Heading>
                          <Heading size="h5" textAlign="right">
                            Verdi
                          </Heading>
                        </Grid>
                      </Box>
                      <Box overflow="auto">
                        <Accordion allowToggle borderColor="neutral.100">
                          {kpi.datapoints.map((datapoint, index) => {
                            const source = DataPointSources[datapoint]
                            const sourceQuestion = questionsByDatapoint?.[datapoint]
                            const parentQuestionAnswers = ratingState?.answerValues?.[sourceQuestion?.dependsOn]
                            const questionUploads = uploadsByQuestionId?.[sourceQuestion?.id]
                            const questionAnswers = ratingState?.answerValues?.[sourceQuestion?.id]
                            return (
                              <Datapoint
                                key={datapoint}
                                ratingId={ratingId}
                                name={datapoint}
                                autoFocus={index === 0}
                                isLoading={setDatapointsStatus.fetching}
                                source={source}
                                sourceQuestion={sourceQuestion}
                                questionUploads={questionUploads}
                                questionAnswers={questionAnswers}
                                parentQuestionAnswers={!!parentQuestionAnswers}
                                allUploads={ratingState.uploads}
                              />
                            )
                          })}
                        </Accordion>
                      </Box>
                    </>
                  ) : (
                    <Box maxW="50%" m="auto" textAlign="center">
                      <Feedback
                        type="search"
                        heading="Denne indikatoren har ikke datapunkter"
                        text="Informasjon om hvordan denne indikatoren kalkuleres finner du til høyre"
                      />
                    </Box>
                  )}
                </Flex>
                <Flex
                  direction="column"
                  width="625px"
                  bg="neutral.25"
                  borderLeft="1px solid"
                  borderColor="neutral.100"
                  p="32px"
                  borderRadius="0 6px 6px 0"
                >
                  {isGuidanceShown && (
                    <>
                      <Box minHeight="64px">
                        <Link onClick={hideGuidance}>
                          <Text size="lg">
                            <ArrowBackIcon mt="-3px" mr="6px" fontSize="sm" />
                            Tilbake
                          </Text>
                        </Link>
                      </Box>

                      <Box overflow="auto">
                        <Heading size="h4" mb="16px">
                          Hvordan jobber vi med denne indikatoren?
                        </Heading>
                        <MethodI18nText text={kpi.helpText} />
                        <I18nText i18nKey={`kpis.${kpi.kpiNumber}.guidance` as I18nKeys} />
                      </Box>
                    </>
                  )}
                  {!isGuidanceShown && (
                    <>
                      <Flex marginTop="64px" overflow="hidden" flexGrow={1} direction="column">
                        <ModalCloseButton m="14px 10px" />
                        {!isCalculationPanelOpen && (
                          <Box flexGrow={1} mb="32px" overflow="auto">
                            <Box>
                              <Heading size="h4" mb="16px">
                                Hvordan jobber vi med denne indikatoren?
                              </Heading>

                              <MethodI18nText text={kpi.helpText} />

                              {i18n.exists(`kpis.${kpi.kpiNumber}.guidance`) && (
                                <Text>
                                  <Link variant="underlined" onClick={showGuidance}>
                                    Vis veiledning for vurderingen
                                  </Link>
                                </Text>
                              )}
                            </Box>
                          </Box>
                        )}

                        <Flex
                          alignSelf="flex-end"
                          direction="column"
                          width="100%"
                          height={isCalculationPanelOpen ? "100%" : "auto"}
                          background="neutral.100"
                          padding="21px 21px 24px 24px"
                          borderRadius="8px"
                          gap="32px"
                        >
                          <Flex flexGrow={1} direction="column" overflow="hidden">
                            {isCalculationPanelOpen && (
                              <Flex direction="column" overflow="hidden">
                                <Flex>
                                  {kpi.formulaDescription && (
                                    <>
                                      <Heading size="h6" color={isFormulaShown ? "neutral.900" : "neutral.600"}>
                                        <Link onClick={hideFormula}>
                                          {isFormulaShown ? "Vis utregning" : "Utregning"}
                                        </Link>
                                      </Heading>

                                      <Heading
                                        size="h6"
                                        pl="16px"
                                        color={isFormulaShown ? "neutral.600" : "neutral.900"}
                                      >
                                        <Link onClick={showFormula}>{isFormulaShown ? "Formel" : "Vis formel"}</Link>
                                      </Heading>
                                    </>
                                  )}
                                  {!kpi.formulaDescription && (
                                    <Heading size="h6" color="neutral.600">
                                      Formel
                                    </Heading>
                                  )}
                                  <Text ml="auto">
                                    <Link variant="underlined" onClick={closeCalculationPanel}>
                                      Skjul
                                    </Link>
                                  </Text>
                                </Flex>

                                <Box mt="16px" mb="16px" overflow="auto">
                                  {kpi.formulaDescription && !isFormulaShown && (
                                    <Box>
                                      <MethodI18nText text={kpi.formulaDescription} />
                                    </Box>
                                  )}
                                  {(!kpi.formulaDescription || isFormulaShown) && (
                                    <Box>
                                      <Text variant="formula" whiteSpace="pre-line">
                                        {kpi.formula}
                                      </Text>
                                    </Box>
                                  )}
                                </Box>
                              </Flex>
                            )}
                            {!isCalculationPanelOpen && (
                              <Flex direction="column" gap="16px">
                                <Flex>
                                  <Heading size="h6" color="neutral.600" onClick={onCalculationHeaderClicked}>
                                    Utregning
                                  </Heading>
                                  <Text ml="auto">
                                    <Link variant="underlined" onClick={openCalculationPanel}>
                                      Vis mer
                                    </Link>
                                  </Text>
                                </Flex>
                              </Flex>
                            )}
                          </Flex>

                          <CalculationOutput kpi={kpi} calculatedKpi={calculatedKpi} override={override} />

                          <div>
                            {isSuperAdmin && isEditable && (
                              <KpiOverrider
                                calculatedKpi={calculatedKpi}
                                ratingId={ratingId}
                                kpi={kpi}
                                currentOverride={ratingState.overriddenKpisById?.[kpi.id]}
                              />
                            )}
                          </div>
                        </Flex>
                      </Flex>

                      <Flex mt="auto" pt="32px">
                        <Text alignSelf="center">
                          <InfoOutlineIcon mr="4px" mb="1" />
                          Lagrede datapunkt-verdier blir oppdatert overalt.
                        </Text>
                        {isEditable ? (
                          <Flex ml="auto">
                            <Button
                              size="lg"
                              variant="outline"
                              onClick={close}
                              isLoading={setDatapointsStatus.fetching}
                            >
                              Avbryt
                            </Button>
                            <Button
                              size="lg"
                              colorScheme="green"
                              type="submit"
                              isLoading={setDatapointsStatus.fetching}
                              ml="16px"
                            >
                              Lagre
                            </Button>
                          </Flex>
                        ) : (
                          <Button ml="auto" size="lg" variant="outline" onClick={close}>
                            Lukk
                          </Button>
                        )}
                      </Flex>
                    </>
                  )}
                </Flex>
              </Flex>
            </ModalBody>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  )
}
