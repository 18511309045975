import {
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
} from "@chakra-ui/react"
import type { RatingState } from "@esgt/event-store"

interface CompanyInfoModalProps {
  ratingState: RatingState
  isOpen: boolean
  onClose: () => void
}

export const CompanyInfoModal: React.FC<CompanyInfoModalProps> = ({ ratingState, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{ratingState.brreg.navn}</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Table>
            <Tbody>
              <Tr>
                <Th scope="row">Bedriftsnavn</Th>
                <Td>{ratingState.brreg.navn}</Td>
              </Tr>

              <Tr>
                <Th scope="row">Org. nummer</Th>
                <Td>{ratingState.brreg.organisasjonsnummer}</Td>
              </Tr>

              <Tr>
                <Th scope="row">Næringskode</Th>
                <Td>
                  {ratingState?.brreg.naeringskode1 ? ratingState?.brreg.naeringskode1.kode : " - "}
                  {" - "}
                  {ratingState?.brreg.naeringskode1 ? ratingState?.brreg.naeringskode1.beskrivelse : ""}
                </Td>
              </Tr>

              <Tr>
                <Th scope="row">Adresse</Th>
                <Td>
                  {ratingState.brreg.forretningsadresse.adresse}
                  {", "}
                  {ratingState.brreg.forretningsadresse.postnummer} {ratingState.brreg.forretningsadresse.poststed}
                </Td>
              </Tr>

              <Tr>
                <Th scope="row">Br. reg.</Th>
                <Td>
                  <Link
                    href={`https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${ratingState?.orgNr}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Enhet
                  </Link>
                  {" | "}
                  <Link
                    href={`https://w2.brreg.no/eHandelPortal/ecomsys/velg.jsp?enhetsnr=${ratingState?.orgNr}&action=getList`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Utskrifter og attester
                  </Link>
                </Td>
              </Tr>

              <Tr>
                <Th scope="row">Måling opprettet</Th>
                <Td>{ratingState.ts && new Date(ratingState.ts).toLocaleString()}</Td>
              </Tr>
            </Tbody>
          </Table>
        </ModalBody>

        <ModalFooter />
      </ModalContent>
    </Modal>
  )
}
