import {
  Box,
  Heading,
  ListItem,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
} from "@chakra-ui/react"
import type { ReactNode } from "react"
import { Page, SectionHeading, SectionSubHeading } from "./components"

export const Indicators: React.FC = () => {
  return (
    <>
      <Page id="interessenter">
        <SectionHeading>Metodikk</SectionHeading>

        <Text mb="4mm">
          ESG Trackr sin plattform for måling av bærekraft forenkler bærekraftarbeidet for små og mellomstore bedrifter.
          Hensikten er å gjøre bærekraft enkelt, håndgripelig og til en naturlig del av virksomheters hverdag.
        </Text>

        <Text mb="4mm">
          Bærekraft konkretiseres i metodebeskrivelsen, og er inndelt i tre dimensjoner; den økonomiske dimensjonen,
          miljødimensjonen og den sosiale dimensjonen. Dimensjonene defineres så i kategorier og videre inn i
          indikatorer. Det er utviklet et forhåndsdefinert indikatorsett, som ikke er tilpasset hver enkelt bransje og
          virksomhet. Indikatorsettet er basert på FNs bærekraftmål, ESG, GRI, TCFD, NSRS og VSMEs ESRS.
        </Text>

        <Text mb="4mm">
          Resultatet av en bærekraftmåling presenteres i en bærekraftrapport. Den beskriver hvor bærekraftig driften til
          virksomheten i målingsperioden, og resultatet er basert på hvilken bransje virksomheten er i.
          Bærekraftmålingen og rapporten er basert på data og dokumentasjon innlevert på plattformen til ESG Trackr.
          Data og dokumentasjon er gitt en kontroll før sammenstilling og sertifisering. Dette gjør bærekraftrapporten
          objektiv og etterprøvbar.{" "}
        </Text>

        <Text mb="4mm">
          Metodikken hensyntar vesentlig påvirkning i en dobbel vesentlighetsanalyse. Fargene i solen representerer
          risiko (rød) og muligheter (grønn).{" "}
        </Text>

        <SectionSubHeading mt="10mm">Bransjeinndeling og måltall</SectionSubHeading>
        <Text mb="4mm">
          Virksomhetens resultat fra bærekraftmålingen blir sett i sammenheng med måltall for bransjen den er i. Det er
          utarbeidet måltall/benchmark for samtlige bransjer, inndelt etter EUs bransjeinndeling, NACE Rev 2. Den norske
          bransjeinndelingen, SN2007, bygger på NACE Rev 2., og de er derfor identiske på 4-sifret nivå. Siden måltall
          er utarbeidet per NACE-kode, er resultatene i bærekraftrapporten sammenlignbar for virksomheter, både innad og
          på tvers av bransjer. Måltall justeres i takt med samfunnets utvikling.
        </Text>

        <Text mb="4mm">
          I «solen» presenteres resultatet til den enkelte indikator, kategori og dimensjon. Fargene følger
          trafikklys-systemet. Det er muligheter for å gi indikatorene mer eller mindre vekting i sola. Det er valgt å
          vekte følgende:
        </Text>

        <UnorderedList m="0 6mm">
          <ListItem>EBT Resultatmargin før skatt vektes 2</ListItem>
          <ListItem>Avfallsproduksjon areal vektes 0,5</ListItem>
          <ListItem>Etterlevelse av ventilasjon og inneklima vektes 0,5</ListItem>
          <ListItem>Resterende indikatorer er vektet 1</ListItem>
        </UnorderedList>
      </Page>
      <Page id="indicators">
        <SectionHeading>Oversikt over indikatorer (KPI)</SectionHeading>
        <Box fontSize="15px" mb="12mm" fontWeight="400">
          Nedenfor følger en oversikt over dimensjonene, kategoriene og indikatorene som ligger til grunn for denne
          bærekraftmålingen. Oversikten viser indikatorenes relasjon til SDG, ESG, ESRS, GRI og NSRS.
        </Box>
        <SectionSubHeading mb={"6mm"}>DEN ØKONOMISKE DIMENSJONEN</SectionSubHeading>
        <TableContainer>
          <Table width={"100%"} variant="simple">
            <DefaultTableHead title="Lønnsomhet" />
            <Tbody>
              <Tr>
                <TableTextCell isTitle>EBITDA margin</TableTextCell>
                <TableTextCell>8, 9, 12</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>201</TableTextCell>
                <TableTextCell>—</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>EBIT driftsresultatmargin</TableTextCell>
                <TableTextCell>8, 9, 12</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>201</TableTextCell>
                <TableTextCell>—</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>EBT resultatmargin før skatt</TableTextCell>
                <TableTextCell>8, 9, 12</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>201</TableTextCell>
                <TableTextCell>—</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Avkastning på egenkapital før skatt</TableTextCell>
                <TableTextCell>8, 9, 12</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>201</TableTextCell>
                <TableTextCell>—</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Avkastning på eiendeler før skatt</TableTextCell>
                <TableTextCell>8, 9, 12</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>201</TableTextCell>
                <TableTextCell>—</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Avkastning på egenkapital</TableTextCell>
                <TableTextCell>8, 9, 12</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>201</TableTextCell>
                <TableTextCell>—</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Avkastning på eiendeler</TableTextCell>
                <TableTextCell>8, 9, 12</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>201</TableTextCell>
                <TableTextCell>—</TableTextCell>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <TableContainer mt={"6mm"}>
          <Table width={"100%"} variant="simple">
            <DefaultTableHead title="Likviditet" />
            <Tbody>
              <Tr>
                <TableTextCell isTitle>Likviditetsgrad 1</TableTextCell>
                <TableTextCell>8, 9, 12</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>201</TableTextCell>
                <TableTextCell>1-4.6-12</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Arbeidskapital</TableTextCell>
                <TableTextCell>8, 9, 12</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>201</TableTextCell>
                <TableTextCell>1-4.6-12</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Likviditetsgrad 2</TableTextCell>
                <TableTextCell>8, 9, 12</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>201</TableTextCell>
                <TableTextCell>1-4.6-12</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Rentedekningsgrad</TableTextCell>
                <TableTextCell>8, 9, 12</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>201</TableTextCell>
                <TableTextCell>1-4.6-12</TableTextCell>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <TableContainer mt={"6mm"}>
          <Table width={"100%"} variant="simple">
            <DefaultTableHead title="Soliditet" />
            <Tbody>
              <Tr>
                <TableTextCell isTitle>Egenkapitalandel</TableTextCell>
                <TableTextCell>8, 9, 12</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>201</TableTextCell>
                <TableTextCell>1-4.6-12</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Finansieringsgrad 1</TableTextCell>
                <TableTextCell>8, 9, 12</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>201</TableTextCell>
                <TableTextCell>1-4.6-12</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Kvalitet på økonomisk data</TableTextCell>
                <TableTextCell>8, 9, 10, 11, 16</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>201</TableTextCell>
                <TableTextCell>1-4.6-12</TableTextCell>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Page>

      <Page id="indicators-env">
        <SectionSubHeading mb={"6mm"}>Miljødimensjonen</SectionSubHeading>
        <TableContainer>
          <Table width={"100%"} variant="simple">
            <DefaultTableHead title="Ressurseffektivitet" />
            <Tbody>
              <Tr>
                <TableTextCell isTitle>Energieffektivitet areal</TableTextCell>
                <TableTextCell>7, 9, 12, 13</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E1-5</TableTextCell>
                <TableTextCell>302</TableTextCell>
                <TableTextCell>1-4.3-11-a, 2-7-1-c, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Energieffektivitet relatert til omsetning</TableTextCell>
                <TableTextCell>7, 9, 12, 13</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E1-5</TableTextCell>
                <TableTextCell>302</TableTextCell>
                <TableTextCell>1-4.3-11-a, 2-7-1-c, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Energikilde</TableTextCell>
                <TableTextCell>7, 9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E1-5</TableTextCell>
                <TableTextCell>302</TableTextCell>
                <TableTextCell>1-4.3-11-a, 2-7-1-c, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Gjennomsnittlig- og maksimal belastning av strømnett</TableTextCell>
                <TableTextCell>7, 9, 12, 13, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E1-5</TableTextCell>
                <TableTextCell>302</TableTextCell>
                <TableTextCell>-</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Fossilt drivstofforbruk relatert til omsetning</TableTextCell>
                <TableTextCell>7, 9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>302</TableTextCell>
                <TableTextCell>1-4.3-11-a, 2-7-1-c, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Vanneffektivitet areal</TableTextCell>
                <TableTextCell>6, 7, 9, 12, 13</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E3-4</TableTextCell>
                <TableTextCell>303</TableTextCell>
                <TableTextCell>-</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Vanneffektivitet relatert til omsetning</TableTextCell>
                <TableTextCell>6, 7, 9, 12, 13</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E3-4</TableTextCell>
                <TableTextCell>303</TableTextCell>
                <TableTextCell>-</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Avfallsproduksjon areal</TableTextCell>
                <TableTextCell>9, 11, 12, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E5-5</TableTextCell>
                <TableTextCell>306</TableTextCell>
                <TableTextCell>1-4.2-6-a, 2-7-1-c, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Avfallsproduksjon relatert til omsetning</TableTextCell>
                <TableTextCell>9, 11, 12, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E5-5</TableTextCell>
                <TableTextCell>306</TableTextCell>
                <TableTextCell>1-4.2-6-a, 2-7-1-c, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Avfallsproduksjon per årsverk</TableTextCell>
                <TableTextCell>9, 11, 12, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E5-5</TableTextCell>
                <TableTextCell>306</TableTextCell>
                <TableTextCell>1-4.2-6-a, 2-7-1-c, 2-9-11</TableTextCell>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <TableContainer mt={"6mm"}>
          <Table width={"100%"} variant="simple">
            <DefaultTableHead title="Sirkulærøkonomi" />
            <Tbody>
              <Tr>
                <TableTextCell isTitle>Resirkulering</TableTextCell>
                <TableTextCell>6, 9, 11, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E5-5</TableTextCell>
                <TableTextCell>301,306</TableTextCell>
                <TableTextCell>1-4.2-6-a, 2-7-1-c, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Matavfall per årsverk</TableTextCell>
                <TableTextCell>2, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E5-5</TableTextCell>
                <TableTextCell>306</TableTextCell>
                <TableTextCell>1-4.2-6-a, 2-7-1-c, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Matavfall relatert til omsetning</TableTextCell>
                <TableTextCell>2, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E5-5</TableTextCell>
                <TableTextCell>306</TableTextCell>
                <TableTextCell>1-4.2-6-a, 2-7-1-c, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Materialbruk</TableTextCell>
                <TableTextCell>8, 9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E5-4</TableTextCell>
                <TableTextCell>301</TableTextCell>
                <TableTextCell>1-4.1-1-a, 1-4.1-2, 2-7-1-c, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Produktets resirkuleringsgrad</TableTextCell>
                <TableTextCell>8, 9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E5-5</TableTextCell>
                <TableTextCell>301</TableTextCell>
                <TableTextCell>-</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Bærekraftig emballasje</TableTextCell>
                <TableTextCell>2, 9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E5-4</TableTextCell>
                <TableTextCell>301, 306</TableTextCell>
                <TableTextCell>1-4.1-2, 2-7-1-c, 2-9-11</TableTextCell>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <TableContainer mt={"6mm"}>
          <Table width={"100%"} variant="simple">
            <DefaultTableHead title="Utslipp" />
            <Tbody>
              <Tr>
                <TableTextCell isTitle>Scope 1 - Direkte utslipp</TableTextCell>
                <TableTextCell>7, 9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E1-6</TableTextCell>
                <TableTextCell>305</TableTextCell>
                <TableTextCell>1-4.4-15-a, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Scope 1 relatert til omsetning</TableTextCell>
                <TableTextCell>7, 9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E1-6</TableTextCell>
                <TableTextCell>305</TableTextCell>
                <TableTextCell>1-4.4-15-a, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Scope 1 relatert til OpEx</TableTextCell>
                <TableTextCell>7, 9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E1-6</TableTextCell>
                <TableTextCell>305</TableTextCell>
                <TableTextCell>1-4.4-15-a, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Scope 1 relatert til CapEx</TableTextCell>
                <TableTextCell>7, 9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E1-6</TableTextCell>
                <TableTextCell>305</TableTextCell>
                <TableTextCell>1-4.4-15-a, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>
                  Scope 2 Lokasjonsbasert - Indirekte utslipp fra kjøpt elektrisitet
                </TableTextCell>
                <TableTextCell>7, 9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E1-5</TableTextCell>
                <TableTextCell>305</TableTextCell>
                <TableTextCell>1-4.4-16, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Scope 2 Markedsbasert - Indirekte utslipp fra kjøpt elektrisitet</TableTextCell>
                <TableTextCell>7, 9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E1-5</TableTextCell>
                <TableTextCell>305</TableTextCell>
                <TableTextCell>1-4.4-16, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Scope 2 relatert til omsetning</TableTextCell>
                <TableTextCell>7, 9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E1-5</TableTextCell>
                <TableTextCell>305</TableTextCell>
                <TableTextCell>1-4.4-16, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Scope 2 relatert til OpEx</TableTextCell>
                <TableTextCell>7, 9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E1-5</TableTextCell>
                <TableTextCell>305</TableTextCell>
                <TableTextCell>1-4.4-16, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Scope 2 relatert til CapEx</TableTextCell>
                <TableTextCell>7, 9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E1-5</TableTextCell>
                <TableTextCell>305</TableTextCell>
                <TableTextCell>1-4.4-16, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Utslipp CO₂-ekvivalenter ved tjenestereiser</TableTextCell>
                <TableTextCell>7, 9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E1-6</TableTextCell>
                <TableTextCell>305</TableTextCell>
                <TableTextCell>2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>NOx-utslipp ved tjenestereiser</TableTextCell>
                <TableTextCell>7, 9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E2-4</TableTextCell>
                <TableTextCell>305</TableTextCell>
                <TableTextCell>2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Utslipp CO₂-ekvivalenter transport og distribusjon</TableTextCell>
                <TableTextCell>7, 9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E1-6</TableTextCell>
                <TableTextCell>305</TableTextCell>
                <TableTextCell>2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>NOx-utslipp transport og distribusjon</TableTextCell>
                <TableTextCell>7, 9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>E2-4</TableTextCell>
                <TableTextCell>305</TableTextCell>
                <TableTextCell>2-9-11</TableTextCell>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <TableContainer mt={"6mm"}>
          <Table width={"100%"} variant="simple">
            <DefaultTableHead title="Miljøinitiativer" />
            <Tbody>
              <Tr>
                <TableTextCell isTitle>Miljøsertifiseringer og grønne partnerskap</TableTextCell>
                <TableTextCell>11, 12, 13, 14, 15, 17</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>2-6-11, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Miljøkostnader drift</TableTextCell>
                <TableTextCell>9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>E-2 CCR-2</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Miljøinvestering eiendeler</TableTextCell>
                <TableTextCell>9, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>E-2 CCR-2</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Energimerking bygg</TableTextCell>
                <TableTextCell>3, 6, 7, 13, 14, 15</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>2-6-11, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Miljøkrav til leverandører</TableTextCell>
                <TableTextCell>6, 7, 8, 12, 13, 14, 15, 17</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>G1-2</TableTextCell>
                <TableTextCell>308</TableTextCell>
                <TableTextCell>1-4.6-10, 2-9-11</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Ansvarlig håndtering av kjemikalier</TableTextCell>
                <TableTextCell>2, 3, 6, 12, 13, 14, 15</TableTextCell>
                <TableTextCell>E</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>305, 307, 403</TableTextCell>
                <TableTextCell>-</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Miljøkontroll</TableTextCell>
                <TableTextCell>2, 3, 6, 7, 8, 9, 11, 12, 13, 14, 15, 17</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>307</TableTextCell>
                <TableTextCell>2-9-11</TableTextCell>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Page>

      <Page id="indicators-soc">
        <SectionSubHeading mb={"6mm"}>Den sosiale dimensjonen</SectionSubHeading>
        <TableContainer>
          <Table width={"100%"} variant="simple">
            <DefaultTableHead title="Likestilling" />
            <Tbody>
              <Tr>
                <TableTextCell isTitle>Kjønnsbalanse</TableTextCell>
                <TableTextCell>5, 8, 10, 16</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-6</TableTextCell>
                <TableTextCell>401,405</TableTextCell>
                <TableTextCell>1-4.6-1a</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Kjønnsbalanse medianlønn</TableTextCell>
                <TableTextCell>5, 8, 10, 16</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-6, S1-9</TableTextCell>
                <TableTextCell>405</TableTextCell>
                <TableTextCell>1-4.6-4, 1-4.6-1b</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Kjønnsbalanse gjennomsnittslønn</TableTextCell>
                <TableTextCell>5, 8, 10, 16</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-6, S1-9</TableTextCell>
                <TableTextCell>405</TableTextCell>
                <TableTextCell>1-4.6-4, 1-4.6-1b</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Aldersbalanse arbeidsstokk</TableTextCell>
                <TableTextCell>8, 10, 16</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-9</TableTextCell>
                <TableTextCell>401,405</TableTextCell>
                <TableTextCell>1-4.6-1b</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Arbeidsstokk under 30 år</TableTextCell>
                <TableTextCell>8, 10, 16</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-6</TableTextCell>
                <TableTextCell>401,405</TableTextCell>
                <TableTextCell>1-4.6-1b</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Arbeidsstokk mellom 30 år og 50 år</TableTextCell>
                <TableTextCell>8, 10, 16</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-6</TableTextCell>
                <TableTextCell>401,405</TableTextCell>
                <TableTextCell>1-4.6-1b</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Arbeidsstokk over 50 år</TableTextCell>
                <TableTextCell>8, 10, 16</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-6</TableTextCell>
                <TableTextCell>401,405</TableTextCell>
                <TableTextCell>1-4.6-1b, 1-4.6-2</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Kjønnsbalanse i ledende stillinger</TableTextCell>
                <TableTextCell>5, 8, 10, 16</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-6</TableTextCell>
                <TableTextCell>405,406</TableTextCell>
                <TableTextCell>1-4.6-1b</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Kjønnsbalanse i styreposisjoner</TableTextCell>
                <TableTextCell>5, 8, 10, 16</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-6</TableTextCell>
                <TableTextCell>405,406</TableTextCell>
                <TableTextCell>1-4.6-1b</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Foreldrepermisjonsordninger</TableTextCell>
                <TableTextCell>3, 5, 8, 10, 16</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-11</TableTextCell>
                <TableTextCell>401,406</TableTextCell>
                <TableTextCell>1-4.6-7</TableTextCell>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <TableContainer mt={"6mm"}>
          <Table width={"100%"} variant="simple">
            <DefaultTableHead title="Balansert lønn" />
            <Tbody>
              <Tr>
                <TableTextCell isTitle>Medianlønn</TableTextCell>
                <TableTextCell>1, 8, 10, 16</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-10</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>-</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Gjennomsnittlig lønn</TableTextCell>
                <TableTextCell>1, 8, 10, 16</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-10</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>1-2-7</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Laveste lønn</TableTextCell>
                <TableTextCell>1, 8, 10, 16</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-16</TableTextCell>
                <TableTextCell>202/412</TableTextCell>
                <TableTextCell>-</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Lønnsforskjell</TableTextCell>
                <TableTextCell>1, 8, 10, 16</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-10</TableTextCell>
                <TableTextCell>405</TableTextCell>
                <TableTextCell>1-4.6-4</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Overtidsbetaling</TableTextCell>
                <TableTextCell>1, 8, 10, 16</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-10</TableTextCell>
                <TableTextCell>401</TableTextCell>
                <TableTextCell>-</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Betaling av midlertidig ansettelse</TableTextCell>
                <TableTextCell>1, 4, 8, 10, 16</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-10</TableTextCell>
                <TableTextCell>401</TableTextCell>
                <TableTextCell>1-4.6-2</TableTextCell>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <TableContainer mt={"6mm"}>
          <Table width={"100%"} variant="simple">
            <DefaultTableHead title="Arbeidsforhold" />
            <Tbody>
              <Tr>
                <TableTextCell isTitle>Langstidssykefravær</TableTextCell>
                <TableTextCell>3, 8</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-14</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>1-4.6-5</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Korttidssykefravær</TableTextCell>
                <TableTextCell>3, 8</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-14</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>1-4.6-5</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Etterlevelse av (internasjonale) standarder i arbeidslivet</TableTextCell>
                <TableTextCell>3, 8, 10, 16</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>403, 419</TableTextCell>
                <TableTextCell>-</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Etterlevelse av krav til ventilasjon og inneklima</TableTextCell>
                <TableTextCell>3, 8, 16</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>403</TableTextCell>
                <TableTextCell>-</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Forsikring av ansatte</TableTextCell>
                <TableTextCell>1, 3, 8, 10, 16</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>S1-11</TableTextCell>
                <TableTextCell>401,403</TableTextCell>
                <TableTextCell>—</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Yrkesskade</TableTextCell>
                <TableTextCell>3, 8, 10, 16</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>S1-14</TableTextCell>
                <TableTextCell>403,419</TableTextCell>
                <TableTextCell>1-4.6-5</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Organisasjonsfrihet</TableTextCell>
                <TableTextCell>1, 8, 10, 16</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>S1-8</TableTextCell>
                <TableTextCell>102,407</TableTextCell>
                <TableTextCell>-</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Kompetanseheving av ansatte</TableTextCell>
                <TableTextCell>4, 8</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-13</TableTextCell>
                <TableTextCell>404</TableTextCell>
                <TableTextCell>1-4.6-8</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Turnover</TableTextCell>
                <TableTextCell>3, 4, 8</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-6</TableTextCell>
                <TableTextCell>401</TableTextCell>
                <TableTextCell>-</TableTextCell>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <TableContainer mt={"6mm"}>
          <Table width={"100%"} variant="simple">
            <DefaultTableHead title="Verdikjedeansvar" />
            <Tbody>
              <Tr>
                <TableTextCell isTitle>Mattrygghet</TableTextCell>
                <TableTextCell>2, 3, 6, 12</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>416</TableTextCell>
                <TableTextCell>-</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Betalingstid leverandører</TableTextCell>
                <TableTextCell>8, 10, 16, 17</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>G1-6</TableTextCell>
                <TableTextCell>419</TableTextCell>
                <TableTextCell>1-4.6-3</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Sosiale krav til leverandører</TableTextCell>
                <TableTextCell>1, 3, 4, 8, 10, 16, 17</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>G1-2</TableTextCell>
                <TableTextCell>408, 409, 412, 414</TableTextCell>
                <TableTextCell>1-4.6-3</TableTextCell>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <TableContainer mt={"6mm"}>
          <Table width={"100%"} variant="simple">
            <DefaultTableHead title="Governance og eksternaliteter" />
            <Tbody>
              <Tr>
                <TableTextCell isTitle>Håndtering av personopplysninger</TableTextCell>
                <TableTextCell>8, 16</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>410, 418</TableTextCell>
                <TableTextCell>-</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Frivillighetsarbeid og donasjoner</TableTextCell>
                <TableTextCell>3, 10, 11, 17</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>413</TableTextCell>
                <TableTextCell>-</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Bransjeeksternaliteter</TableTextCell>
                <TableTextCell>3, 5, 7, 8, 10, 13, 16</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>413</TableTextCell>
                <TableTextCell>2-6-1</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Antikorrupsjonstiltak</TableTextCell>
                <TableTextCell>1, 4, 8, 9, 10, 11, 16, 17</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>G1-3</TableTextCell>
                <TableTextCell>102,205,206</TableTextCell>
                <TableTextCell>1-4.6-13</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Samfunnstiltak, lærlinger og internships</TableTextCell>
                <TableTextCell>4, 8, 10, 16, 17</TableTextCell>
                <TableTextCell>S</TableTextCell>
                <TableTextCell>S1-7</TableTextCell>
                <TableTextCell>102</TableTextCell>
                <TableTextCell>1-4.6-2</TableTextCell>
              </Tr>
              <Tr>
                <TableTextCell isTitle>Sosialt avtrykk</TableTextCell>
                <TableTextCell>1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 16, 17</TableTextCell>
                <TableTextCell>G</TableTextCell>
                <TableTextCell>-</TableTextCell>
                <TableTextCell>419</TableTextCell>
                <TableTextCell>-</TableTextCell>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Page>
    </>
  )
}

type TableTextCellProps = {
  isTitle?: boolean
  children: ReactNode
}

const TableTextCell: React.FC<TableTextCellProps> = ({ children, isTitle }) => {
  const padTop = isTitle ? 0 : 1
  return (
    <Td pt={padTop} pr={2} pb={1} pl={0}>
      <TableText>{children}</TableText>
    </Td>
  )
}

const TableText = ({ children, ...rest }) => (
  <Text whiteSpace={"break-spaces"} maxWidth="100%" margin={0} mb={"1mm"} fontSize={"9pt"} {...rest}>
    {children}
  </Text>
)

const DefaultTableHead = ({ title }: { title: string }) => (
  <Thead>
    <Tr>
      <Th width={"40%"} borderBottom={"1px solid #333"} textAlign={"left"} pl={0} pr={2}>
        <TableText mb={2} fontSize={"10pt"} textTransform={"uppercase"} fontWeight={400}>
          {title}
        </TableText>
      </Th>
      <Th width={"12%"} borderBottom={"1px solid #333"} textAlign={"left"} pl={0} pr={2}>
        <TableText mb={2} fontSize={"10pt"} textTransform={"uppercase"} fontWeight={400}>
          FN MÅL (SDG)
        </TableText>
      </Th>
      <Th width={"12%"} borderBottom={"1px solid #333"} textAlign={"left"} pl={0} pr={2}>
        <TableText mb={2} fontSize={"10pt"} textTransform={"uppercase"} fontWeight={400}>
          ESG
        </TableText>
      </Th>
      <Th width={"12%"} borderBottom={"1px solid #333"} textAlign={"left"} pl={0} pr={2}>
        <TableText mb={2} fontSize={"10pt"} textTransform={"uppercase"} fontWeight={400}>
          ESRS
        </TableText>
      </Th>
      <Th width={"12%"} borderBottom={"1px solid #333"} textAlign={"left"} pl={0} pr={2}>
        <TableText mb={2} fontSize={"10pt"} textTransform={"uppercase"} fontWeight={400}>
          GRI
        </TableText>
      </Th>
      <Th width={"12%"} borderBottom={"1px solid #333"} textAlign={"left"} pl={0} pr={2}>
        <TableText mb={2} fontSize={"10pt"} textTransform={"uppercase"} fontWeight={400}>
          NSRS
        </TableText>
      </Th>
    </Tr>
  </Thead>
)
