import { Divider, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import type { RatingState } from "@esgt/event-store"
import { formatUploadScope } from "@esgt/helpers"
import { useRating } from "lib/providers/RatingProvider"
import { useMemo } from "react"
import styled from "styled-components"

const Container = styled.div`
	width: 100%;
	overflow: auto;
`

function truncate(string: string, length = 40): string {
  if (string.length < length) return string

  return `${string.substring(0, length - 3)}...`
}

interface UploadsTableProps {
  title: string
  uploads: RatingState["uploads"]
}

export const UploadsTable: React.FC<UploadsTableProps> = ({ title, uploads }) => {
  const { ratingState, method, isReady } = useRating()

  const questionDisplayNames = useMemo(
    () =>
      isReady && method
        ? [...ratingState.additionalQuestions, ...method.config.questions].map((q) => ({
            id: q.id,
            questionNumber: q.questionNumber,
          }))
        : [],
    [ratingState.additionalQuestions, method?.config.questions, isReady],
  )

  return (
    <Container>
      <Heading size="md" as="h3" fontWeight="500" mb={4}>
        {title}
      </Heading>
      <Divider />
      <TableContainer width="100%" overflowY="hidden">
        <Table>
          <Thead>
            <Tr>
              <Th textTransform="none">Hva</Th>
              <Th textTransform="none">Filnavn</Th>
              <Th textTransform="none" textAlign="right">
                Tidspunkt for opplastning
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {uploads.map((upload) => (
              <Tr key={upload.uploadId}>
                <Td>{formatUploadScope(upload.scope, questionDisplayNames)}</Td>
                <Td>{truncate(upload.filename)}</Td>
                <Td textAlign="right">{new Date(upload.timestamp).toLocaleString()}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Container>
  )
}
