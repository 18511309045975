import { Box, Heading } from "@chakra-ui/react"
import { type ExtendedCalculatedKpi, type KpiOverride, benchmarkLevels } from "@esgt/method-structure"
import type { Kpi } from "@esgt/types"
import { MethodI18nFragment } from "components/I18nText"
import { InfoTooltipIcon } from "components/Tooltips/InfoTooltipIcon"
import { formatValue } from "lib/helpers/formatValue"
import React from "react"

type CalculationOutputProps = {
  kpi: Kpi
  calculatedKpi?: ExtendedCalculatedKpi
  override?: KpiOverride
}

export const CalculationOutput = ({ kpi, calculatedKpi, override }: CalculationOutputProps) => (
  <Box flexGrow={0}>
    <Heading size="h4">
      <Box as="span">
        <MethodI18nFragment text={kpi.name} />
        {" ="}
      </Box>

      {calculatedKpi.value !== undefined && (
        <>
          <Box
            as="span"
            borderBottom="3px solid"
            p="4px 6px"
            fontWeight="700"
            borderColor={
              typeof calculatedKpi?.benchmarkLevel === "number"
                ? benchmarkLevels[calculatedKpi.benchmarkLevel].color
                : "neutral.500"
            }
          >
            {override && calculatedKpi?.beforeOverride && (
              <>
                <Box as="span" textDecoration="line-through" opacity="0.5">
                  {calculatedKpi.beforeOverride.value ? `${calculatedKpi.beforeOverride.value}` : "(ingen)"}
                </Box>{" "}
              </>
            )}

            {formatValue(calculatedKpi?.value.toString())}

            <Box as="span" fontWeight="500" color="neutral.600">
              {" "}
              <MethodI18nFragment text={kpi.unit} />
            </Box>
          </Box>

          {override && <InfoTooltipIcon label={`Overstyrt, med kommentar: "${override.reason}"`} />}
        </>
      )}
      {calculatedKpi?.value === undefined && (
        <Box as="em" borderBottom="3px solid" borderColor="critical.500" fontWeight="500">
          Mangler Data
        </Box>
      )}
    </Heading>
  </Box>
)
