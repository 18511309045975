import { Box, Button, Flex, type FlexProps } from "@chakra-ui/react"
import { RatingType } from "@esgt/types"
import { FormattedRatingPeriod } from "components/FormattedRatingPeriod"
import type { RatingSearchResult } from "lib/generated/graphql"
import { routes } from "lib/routes"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import useResizeObserver from "use-resize-observer"

export const OverviewTable: React.FC<{ ratings?: Partial<RatingSearchResult>[] }> = ({ ratings }) => {
  const navigate = useNavigate()

  const RowContainer = ({
    isHeader,
    isFirstRow,
    link,
    children,
  }: { isHeader?: boolean; isFirstRow?: boolean; link?: string; children: React.ReactNode }) => {
    const baseProps: FlexProps = {
      flexFlow: "row nowrap",
      alignItems: "center",
      gridGap: "16px",
      p: "20px 24px",
    }
    const props: FlexProps = {
      ...baseProps,
      ...(isHeader
        ? {
            bg: "neutral.100",
            color: "neutral.500",
            borderRadius: "4px 4px 0 0",
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "12px",
            letterSpacing: "var(--chakra-letterSpacings-wider)",
            py: "12px",
          }
        : {
            bg: "white",
            border: "1px solid",
            borderColor: "neutral.100",
            borderRadius: isFirstRow ? "0 0 4px 4px" : "4px",
            cursor: "pointer",
            mb: "16px",
            onClick: () => navigate(link),
          }),
    }
    return <Flex {...props}>{children}</Flex>
  }

  const { ref: c5Ref, width: c5Width = 1 } = useResizeObserver<HTMLDivElement>()

  const Row = useMemo(
    () =>
      ({ rating, isFirstRow }: { rating: Partial<RatingSearchResult>; isFirstRow: boolean }) => {
        return (
          <RowContainer link={routes.rating(rating.key)} isFirstRow={isFirstRow}>
            <Box flex="3">{rating.companyName}</Box>
            <Box flex="2">{rating.ratingType === RatingType.Free ? "Gratis versjon" : "Full versjon"}</Box>
            <Box flex="2">
              <FormattedRatingPeriod period={rating?.period} />
            </Box>
            <Box flex="2">
              {rating?.lastSignificantEventType === "RatingCompleted"
                ? "Ferdigstilt"
                : ["RequestDocumentationProcessing", "DatafilesProcessed"].includes(rating?.lastSignificantEventType)
                  ? "Venter kontroll"
                  : "Pågående"}
            </Box>
            <Box ref={isFirstRow ? c5Ref : null} textAlign="right">
              <Button variant="outline" size="md">
                Gå til måling
              </Button>
            </Box>
          </RowContainer>
        )
      },
    [ratings, navigate],
  )

  return (
    <Box>
      <RowContainer isHeader={true}>
        <Box flex="3">Virksomhet</Box>
        <Box flex="2">Versjon</Box>
        <Box flex="2">Periode</Box>
        <Box flex="2">Status</Box>
        <Box w={c5Width} />
      </RowContainer>

      <>
        {ratings.map((rating, idx) => (
          <Row rating={rating} isFirstRow={idx === 0} key={rating.key} />
        ))}
      </>
    </Box>
  )
}
