import { Box, Flex, Icon, Progress, Text } from "@chakra-ui/react"
import type { RatingFileCategory, UploadScope } from "@esgt/types"
import { useRating } from "lib/providers/RatingProvider"
import { useEffect, useMemo } from "react"
import { MdClear, MdErrorOutline, MdFileUpload } from "react-icons/md"
import styled from "styled-components"
import { UploadState, useUpload } from "./useUpload"

interface Props {
  file: File
  id: string
  ratingFileType: RatingFileCategory
  timestamp: string
  uploadScope: UploadScope
  onDelete?: () => void
  delay?: number
}

const Wrapper = styled.div`
	max-width: 450px;
`

const UploadItem: React.FC<Props> = ({ id, file, uploadScope, ratingFileType, timestamp, onDelete, delay = 0 }) => {
  const { ratingId } = useRating()

  const [{ progress, uploadState, request }, startUpload] = useUpload({
    id,
    file,
    processFileProps: {
      scope: uploadScope,
      ratingFileType,
      timestamp,
      ratingId,
      filename: file.name,
      uploadId: id,
    },
  })

  useEffect(() => {
    if (uploadState === UploadState.PREPARING) {
      setTimeout(() => {
        startUpload()
      }, 1000 + delay)
    }
  }, [uploadState])

  const handleDelete = () => {
    request?.abort()
    onDelete?.()
  }

  const hasError = useMemo(() => uploadState === UploadState.ERROR, [uploadState])
  const canDelete = useMemo(
    () => [UploadState.NEW, UploadState.UPLOADING, UploadState.ERROR].includes(uploadState),
    [uploadState],
  )
  const progressColorScheme = useMemo(() => {
    switch (uploadState) {
      case UploadState.COMPLETED:
        return "green"
      case UploadState.ERROR:
        return "red"
      default:
        return "blue"
    }
  }, [uploadState])
  const isProgressing = useMemo(
    () => [UploadState.UPLOADING, UploadState.PROCESSING].includes(uploadState),
    [uploadState],
  )

  return (
    <Wrapper>
      <Box>
        <Flex flexFlow={"row nowrap"} gap="8px" alignItems="center">
          {hasError ? (
            <Icon boxSize="16px" as={MdErrorOutline} color="red.600" />
          ) : (
            <Icon boxSize="18px" as={MdFileUpload} color="gray.600" />
          )}

          <Flex flex={1} gap="12px" flexFlow={"row nowrap"} overflow="hidden" alignItems={"center"}>
            <Text fontSize="14px" color="gray.700">
              {file.name}
            </Text>
          </Flex>

          {canDelete ? (
            <Icon boxSize="20px" as={MdClear} color="gray.600" onClick={handleDelete} cursor="pointer" />
          ) : null}
        </Flex>

        <Progress
          value={(progress.loaded / progress.total) * 100}
          size="xs"
          colorScheme={progressColorScheme}
          mt="2"
          hasStripe={isProgressing}
          isAnimated={isProgressing}
        />
      </Box>
    </Wrapper>
  )
}

export { UploadItem }
