import { UserRatingRole } from "@esgt/types"
import { type User, useAccountEligibleRatersQuery, useChangeRatingRatersMutation } from "lib/generated/graphql"
import { useResultToast } from "lib/hooks"
import { RaterPicker } from "pages/Ratings/NewRating/contributors/raters/RaterPicker"
import { RaterTable } from "pages/Ratings/NewRating/contributors/raters/RaterTable"
import type { RaterOption } from "pages/Ratings/RaterOption"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useBoolean } from "usehooks-ts"
import { OrderPropertyRow } from "./OrderPropertyRow"

interface OrderRatersProps {
  ratingId: string
  raters: User[]
  ratingOwner?: User
  refetchRaters: () => void
  isLoading: boolean
}

export const OrderRaters = ({ ratingId, raters, ratingOwner, isLoading, refetchRaters }: OrderRatersProps) => {
  const [accountEligibleRatersQuery] = useAccountEligibleRatersQuery()
  const accountUsers: User[] = accountEligibleRatersQuery.data?.accountEligibleRaters?.users

  const ratingElibibleUsers = useMemo(() => {
    // Handle case when rating is created by an Admin outside the account (owner not in account user list)
    const ownerIsInAccount = accountUsers?.some(({ id }) => ratingOwner && id === ratingOwner.id)
    if (accountUsers) {
      return ownerIsInAccount || !ratingOwner ? accountUsers : [ratingOwner, ...accountUsers]
    }
    return undefined
  }, [accountUsers, ratingOwner])

  const [changeRatersResult, changeRaters] = useChangeRatingRatersMutation()

  const [raterList, setRaterList] = useState<Array<RaterOption>>()

  useEffect(() => {
    if (raters) {
      const fetchedRaters = ratingOwner ? [ratingOwner, ...raters] : raters
      setRaterList(fetchedRaters.map(asRater))
    }
  }, [raters, ratingOwner])

  const { value: isEditing, setTrue: setEditing, setValue: setIsEditing } = useBoolean()

  const submitRaters = useCallback(
    async (newRaterList: Array<RaterOption>) => {
      const raterUserIds = newRaterList.map(({ user }) => user.id)
      setIsEditing(false)

      if (ratersChanged(raters, newRaterList, ratingOwner?.id)) {
        await changeRaters({ ratingId, raterUserIds })
        refetchRaters()
      }
    },
    [changeRaters, raters, ratingId, ratingOwner?.id, refetchRaters, setIsEditing],
  )

  useResultToast(changeRatersResult, "Kontrollører oppdatert", "Kunne ikke oppdatere kontrollører")

  return (
    <OrderPropertyRow
      title="Kontrollører"
      infoBody="helpTexts.ratingSettings.orderRaters"
      isEditing={isEditing}
      onEditStart={setEditing}
      isDisabled={isLoading || changeRatersResult.fetching}
    >
      {ratingElibibleUsers && raterList && (
        <>
          <RaterTable raters={raterList} />

          <RaterPicker
            isOpen={isEditing}
            setIsOpen={setIsEditing}
            users={ratingElibibleUsers}
            raters={raterList}
            setRaters={submitRaters}
            ratingOwnerId={ratingOwner?.id}
          />
        </>
      )}
    </OrderPropertyRow>
  )
}

function ratersChanged(raters: Array<User>, newRaterList: Array<RaterOption>, ownerId?: number) {
  const initialIdList = raters.map((user) => user.id).filter((id) => id !== ownerId)
  const selectedIdSet = new Set(newRaterList.map(({ user }) => user.id).filter((id) => id !== ownerId))

  return initialIdList.length !== selectedIdSet.size || initialIdList.some((id) => !selectedIdSet.has(id))
}

function asRater(user: User): RaterOption {
  return { user, role: UserRatingRole.Rater }
}
