import { Box, ListItem, UnorderedList } from "@chakra-ui/react"
import type { KpiCategory } from "@esgt/types"
import ecoPageImg from "assets/report-result-eco.jpg"
import envPageImg from "assets/report-result-env.jpg"
import socPageImg from "assets/report-result-soc.jpg"
import frontpageImg from "assets/report-result.jpg"
import { useRating } from "lib/providers/RatingProvider"
import React from "react"
import { FreeVersionWatermark, Page, SectionHeading } from "../components"
import { KPI } from "./KPI"

export const KPIs: React.FC = () => {
  const { ratingProfile, method } = useRating()

  if (!ratingProfile || !method) return null

  return (
    <>
      <Page id="kpis">
        <SectionHeading mb="8mm">Resultat per indikator</SectionHeading>
        <Box mb="6mm">
          Metodebeskrivelsen deler resultatene etter bærekraftsmålingen inn i tre dimensjoner, den økonomiske
          dimensjonen, miljødimensjonen og den sosiale dimensjonen. Her presenteres resultatene per indikator, med
          redegjørelse for relevans, definisjon, resultat, formel og måltall.
        </Box>
        <Box position="relative">
          <FreeVersionWatermark />
          <img width={"100%"} src={frontpageImg} alt="" />
        </Box>
      </Page>
      <Page id="kpi-eco">
        <SectionHeading mb="8mm">Den økonomiske dimensjonen</SectionHeading>
        <Box mb="4mm">
          Den økonomiske dimensjonen indikerer virksomhetens lønnsomhet, likvide forhold og soliditet. Kategorienes
          indikatorer belyser følgende:
        </Box>
        <UnorderedList m="0 6mm 6mm">
          <ListItem>Om virksomheten tjener penger i sitt virke</ListItem>
          <ListItem>Om det er sannsynlig at virksomheten kan betale sine forpliktelser</ListItem>
          <ListItem>Virksomhetens finansiering</ListItem>
          <ListItem>Om det er sannsynlig at virksomheten kan håndtere nedgangstider</ListItem>
        </UnorderedList>
        <Box position="relative">
          <FreeVersionWatermark />
          <img width={"100%"} src={ecoPageImg} alt="" />
        </Box>
      </Page>
      <Page>
        {method.config.kpiDimensions[0].categories.map((category: KpiCategory) => (
          <React.Fragment key={category.id}>
            {category.kpiIds.map((kpiId) => (
              <KPI kpi={method.kpisById[kpiId]} category={category} key={kpiId} />
            ))}
          </React.Fragment>
        ))}
      </Page>

      <Page id="kpi-env">
        <SectionHeading mb="8mm">Miljødimensjonen</SectionHeading>
        <Box mb="4mm">
          Miljødimensjonen er delt inn i kategoriene ressurseffektivitet, sirkulærøkonomi, utslipp og miljøinitiativ.
          Kategoriens indikatorer belyser følgende:
        </Box>
        <UnorderedList m="0 6mm 6mm">
          <ListItem>Virksomhetens forbruk av ressurser</ListItem>
          <ListItem>Virksomhetens klimagassutslipp</ListItem>
          <ListItem>Virksomhetens deltakelse og innsats i omstillingen til en grønnere økonomi</ListItem>
        </UnorderedList>
        <Box position="relative">
          <FreeVersionWatermark />
          <img width={"100%"} src={envPageImg} alt="" />
        </Box>
      </Page>
      <Page>
        {method.config.kpiDimensions[1].categories.map((category: KpiCategory) => (
          <React.Fragment key={category.id}>
            {category.kpiIds.map((kpiId) => (
              <KPI kpi={method.kpisById[kpiId]} category={category} key={kpiId} />
            ))}
          </React.Fragment>
        ))}
      </Page>

      <Page id="kpi-soc">
        <SectionHeading mb="8mm">Den sosiale dimensjonen</SectionHeading>
        <Box mb="4mm">
          Den sosiale dimensjonen er delt inn i kategoriene likestilling, balansert lønn, arbeidsforhold,
          verdikjedeansvar, og governance og eksternaliteter. Kategorienes indikatorer belyser følgende:
        </Box>
        <UnorderedList m="0 6mm 6mm">
          <ListItem>Virksomhetens inkludering av mangfold</ListItem>
          <ListItem>Lønnspraksis og arbeidsforhold</ListItem>
          <ListItem>Virksomhetsstyring og forretningsetikk</ListItem>
        </UnorderedList>
        <Box position="relative">
          <FreeVersionWatermark />
          <img width={"100%"} src={socPageImg} alt="" />
        </Box>
      </Page>
      <Page>
        {method.config.kpiDimensions[2].categories.map((category: KpiCategory) => (
          <React.Fragment key={category.id}>
            {category.kpiIds.map((kpiId) => (
              <KPI kpi={method.kpisById[kpiId]} category={category} key={kpiId} />
            ))}
          </React.Fragment>
        ))}
      </Page>
    </>
  )
}
