import { useRating } from "lib/providers/RatingProvider"
import { Page, SectionHeading } from "./components"

export const TOC: React.FC = () => {
  const { isFree } = useRating()
  return (
    <Page>
      <SectionHeading mb="14mm">Innholdsfortegnelse</SectionHeading>
      <ul className="toc-list">
        {!isFree && (
          <>
            <li>
              <a href="#method">
                <span className="title">Metode</span>
              </a>
            </li>
            <li>
              <a href="#indicators">
                <span className="title">Oversikt over indikatorer</span>
              </a>
              <ul>
                <li>
                  <a href="#indicators">
                    <span className="title">Den økonomiske dimensjonen</span>
                  </a>
                </li>
                <li>
                  <a href="#indicators-env">
                    <span className="title">Miljødimensjonen</span>
                  </a>
                </li>
                <li>
                  <a href="#indicators-soc">
                    <span className="title">Den sosiale dimensjonen</span>
                  </a>
                </li>
              </ul>
            </li>
          </>
        )}
        <li>
          <a href="#kpis">
            <span className="title">Resultat per indikator</span>
          </a>
          <ul>
            <li>
              <a href="#kpi-eco">
                <span className="title">Den økonomiske dimensjonen</span>
              </a>
            </li>
            <li>
              <a href="#kpi-env">
                <span className="title">Miljødimensjonen</span>
              </a>
            </li>
            <li>
              <a href="#kpi-soc">
                <span className="title">Den sosiale dimensjonen</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </Page>
  )
}
