import { Button, Link } from "@chakra-ui/react"
import { IconArrowRight } from "components/Icons"
import { useReportDownload } from "lib/hooks/useReportDownload/useReportDownload"
import { routes } from "lib/routes"
import { Link as RouterLink } from "react-router-dom"
import type { Rating, RatingState } from "../types"
import type { CardAction } from "./types"

interface RatingCallToActionProps {
  rating: Rating
  state: RatingState
}

export const RatingCallToAction: React.FC<RatingCallToActionProps> = ({ rating, state }) => {
  const downloadPdf = useReportDownload(rating.key)

  const actions: Record<RatingState, CardAction | null> = {
    PENDING_BASIS: {
      link: routes.rating(rating.key, "foundation"),
      label: "Legg inn grunnlagsdata",
    },
    PENDING_CONTROL: {
      link: routes.ratings(`${rating.key}/data`),
      label: "Kontroller måling",
    },
    COMPLETED: {
      action: downloadPdf,
      label: "Last ned rapport",
    },
    CANCELLED: null,
  }

  const action = actions[state]

  if (action) {
    const commonProps = {
      display: "flex",
      gap: 1,
      alignItems: "center",
      color: "#1A79E5",
      fontSize: "md",
      fontWeight: "600",
      lineHeight: "24px",
      paddingRight: 4,
    }

    return action.link ? (
      <Link as={RouterLink} to={action.link} {...commonProps}>
        {action.label} <IconArrowRight boxSize="6" mt="2px" />
      </Link>
    ) : (
      <Button
        variant="link"
        onClick={action.action}
        {...commonProps}
        textDecoration="none"
        fontWeight="normal"
        _hover={{ textDecoration: "underline" }}
      >
        {action.label} <IconArrowRight boxSize="6" mt="2px" />
      </Button>
    )
  }
}
