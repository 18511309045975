import { Navigate, Route, Routes } from "react-router-dom"
import { AboutCompany } from "./AboutCompany"
import { CompanyGoals } from "./CompanyGoals"
import { CompanyStrategy } from "./CompanyStrategy"

export const CompanyTextsRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="about-company" replace={true} />} />
      <Route path="about-company" element={<AboutCompany />} />
      <Route path="company-goals" element={<CompanyGoals />} />
      <Route path="company-strategy" element={<CompanyStrategy />} />
    </Routes>
  )
}
