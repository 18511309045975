import { Checkbox, Flex, Td, Text, Tr } from "@chakra-ui/react"
import { UserRatingRole } from "@esgt/types"
import { RATING_ROLE_GUI_NAMES } from "@esgt/utils"
import type { CurrentUserQuery } from "lib/generated/graphql"
import { useCallback } from "react"
import { FadedLabel } from "../../FadedLabel"
import type { RaterPickerRow } from "./RaterPickerRow"

interface RaterPickerUserRowProps {
  user: Pick<CurrentUserQuery["currentUser"], "id" | "name" | "email">
  isSelected: boolean
  isOwner?: boolean
  updateRater: (userId: number, value: RaterPickerRow) => void
}

export const RaterPickerUserRow = ({ user, isSelected, isOwner, updateRater }: RaterPickerUserRowProps) => {
  const handleSelect = useCallback(() => {
    updateRater(user.id, {
      user,
      isSelected: !isSelected,
      role: UserRatingRole.Rater,
    })
  }, [isSelected, updateRater, user])

  return (
    <Tr key={user.id}>
      <Td>
        <Checkbox isChecked={isSelected} size="lg" isDisabled={isOwner} onChange={handleSelect} />
      </Td>

      <Td>
        <Flex direction="column">
          <Text>{user.name}</Text>
          <Text opacity={0.75} fontSize="0.8em">
            {user.email}
          </Text>
        </Flex>
      </Td>

      <Td>
        <FadedLabel highlighted={isOwner}>
          {RATING_ROLE_GUI_NAMES[isOwner ? UserRatingRole.Owner : UserRatingRole.Rater]}
        </FadedLabel>
      </Td>
    </Tr>
  )
}
