import type { RatingProfilesQuery } from "lib/generated/graphql"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { FixedSizeList } from "react-window"
import styled from "styled-components"
import useResizeObserver from "use-resize-observer"

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-flow: column nowrap;
	overflow: hidden;
`

const TableHeader = styled.div`
	display: flex;
	flex-flow: row nowrap;
	grid-gap: 16px;
	height: 55px;
	align-items: center;
	padding: 0 16px;
	font-weight: var(--chakra-fontWeights-heading);
	font-family: var(--chakra-fonts-heading);
	text-transform: uppercase;
	font-size: 14px;
	color: var(--chakra-colors-blackAlpha-600);
	border-bottom: 1px solid var(--chakra-colors-blackAlpha-200);
`

const TableBody = styled.div`
	flex: 1;
	overflow: hidden;
	line-height: 1.3;
`

const Cell = styled.div``

const Column = styled(Cell)``

const RowContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	border-bottom: 1px solid #f1f1f1;
	box-sizing: border-box;
	align-items: center;
	grid-gap: 16px;
	padding: 0 16px;
	cursor: pointer;

	&:hover {
		background-color: #bae7ff33;
	}
`

const DoubleLineCell = styled.div`
	display: flex;
	flex-flow: column nowrap;

	> *:nth-child(2) {
		font-size: 12px;
		color: #777;
	}
`

export const OverviewTable: React.FC<{ data?: RatingProfilesQuery }> = ({ data }) => {
  const navigate = useNavigate()

  const { ref: bodyRef, width: tableBodyWidth = 1, height: tableBodyHeight = 1 } = useResizeObserver<HTMLDivElement>()
  const { ref: c1Ref, width: c1Width = 1 } = useResizeObserver<HTMLDivElement>()
  const { ref: c2Ref, width: c2Width = 1 } = useResizeObserver<HTMLDivElement>()
  const { ref: c3Ref, width: c3Width = 1 } = useResizeObserver<HTMLDivElement>()
  const { ref: c4Ref, width: c4Width = 1 } = useResizeObserver<HTMLDivElement>()
  const { ref: c5Ref, width: c5Width = 1 } = useResizeObserver<HTMLDivElement>()

  const Row = useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      ({ index, style }: any) => {
        const rowData = data.ratingProfiles[index]
        return (
          <RowContainer style={style} onClick={() => navigate(`${rowData.id}`)}>
            <Cell style={{ width: c1Width }}>
              <span>{rowData.name}</span>
            </Cell>
            <Cell style={{ width: c5Width }}>
              <span>
                {rowData.businessArea} / {rowData.methodVersion}
              </span>
            </Cell>
            <Cell style={{ width: c2Width }}>{rowData.description}</Cell>
            <DoubleLineCell style={{ width: c3Width }}>
              <span>{rowData.activeRevisionId ? new Date(rowData.activeRevisionId).toLocaleString() : "-"}</span>
              <span>
                {/* {formatDistanceToNow(new Date(rowData.lastSignificantEventAt), {
									locale: nb,
									addSuffix: true,
								})} */}
              </span>
            </DoubleLineCell>
            <DoubleLineCell style={{ width: c4Width }}>
              <span>
                {rowData.activeRevisionId !== rowData.latestRevision
                  ? new Date(rowData.latestRevision).toLocaleString()
                  : "-"}
              </span>
              <span>
                {/* {formatDistanceToNow(new Date(rowData.lastSignificantEventAt), {
									locale: nb,
									addSuffix: true,
								})} */}
              </span>
            </DoubleLineCell>
          </RowContainer>
        )
      },
    [data, c1Width, c2Width, c3Width, c4Width, c5Width, navigate],
  )

  return (
    <Container>
      <TableHeader>
        <Column ref={c1Ref} style={{ flex: 0.8 }}>
          ID
        </Column>
        <Column ref={c5Ref} style={{ flex: 2 }}>
          Område / versjon
        </Column>
        <Column ref={c2Ref} style={{ flex: 2 }}>
          Beskrivelse
        </Column>
        <Column ref={c3Ref} style={{ minWidth: "180px", flex: 1 }}>
          Aktiv revisjon
        </Column>
        <Column ref={c4Ref} style={{ minWidth: "180px", flex: 1 }}>
          Nytt utkast
        </Column>
      </TableHeader>

      <TableBody ref={bodyRef}>
        <FixedSizeList
          height={tableBodyHeight}
          width={tableBodyWidth}
          itemCount={data?.ratingProfiles.length || 0}
          itemSize={60}
          style={{ position: "absolute" }}
        >
          {Row}
        </FixedSizeList>
      </TableBody>
    </Container>
  )
}
