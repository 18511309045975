import type { CUID } from "@esgt/types"

interface QuestionDisplayName {
  id: CUID
  questionNumber: string
}

export const formatUploadScope = (scope: string, questionDisplayNames: QuestionDisplayName[]) => {
  if (scope.startsWith("questions")) {
    const [_, questionId] = scope.split("/")
    /**
     * The reason we can expect some ids starting with "legacy" is
     * because some old ratings had a different method version that we are initially migrating from
     * Will be fixed once we import all old method versions that have been deployed for old ratings previously
     */
    const question = questionId.startsWith("legacy")
      ? "Ikke funnet"
      : questionDisplayNames.find((q) => q.id === questionId)?.questionNumber
    return `questions/${question}`
  }
  return scope
}
