import type { CUID, Kpi, RatingAnomaly, RatingDatapoints, RatingProfileConfig } from "@esgt/types"
import { isNumerical } from "@esgt/utils"
import * as math from "mathjs"
import type { KpiOverride } from "../types"
import { interpolateFormula, interpolateMultipleRecords } from "./interpolateFormula"
import { makeMathParser } from "./mathParser"

type KpiFormulaResult = {
  value: math.BigNumber | number | undefined
  hasAllRequiredDatapoints: boolean
}

export const calculateKPIFormula = (
  kpi: Kpi,
  datapoints: RatingDatapoints,
  anomalies: Array<RatingAnomaly> = [],
  kpisById: Record<CUID, Kpi> = {},
  ratingProfileConfig?: RatingProfileConfig,
  overriddenKpisById: Record<CUID, KpiOverride> = {},
): KpiFormulaResult => {
  const parser = makeMathParser()

  if (Object.entries(datapoints).some(([_key, val]) => val === undefined)) {
    throw new Error("KPI has undefined datapoints")
  }

  if (!kpi.formula) {
    throw new Error("KPI has no formula")
  }

  // Remove self from kpisById (used for KPI depencencies) to ensure dependency cycles won't cause a loop even if they somehow sneak in
  const { [kpi.id]: _, ...otherKpisByKpiId } = kpisById

  const interpolatedFormula = interpolateFormula(
    kpi,
    datapoints,
    anomalies,
    otherKpisByKpiId,
    ratingProfileConfig,
    overriddenKpisById,
  )

  const hasAllRequiredDatapoints = !kpi.datapoints.some((datapoint) => !isNumerical(`${datapoints[datapoint]}`))

  let value: math.BigNumber | number | undefined
  try {
    if (hasAllRequiredDatapoints) {
      const evaluated = parser.evaluate(interpolatedFormula)

      // Handle mathjs multi-line computations (which ResultSet containing the result, instead of the result directly)
      const result = typeof evaluated === "object" && evaluated.entries?.length === 1 ? evaluated.entries[0] : evaluated

      if (typeof result !== "undefined") {
        value = math.round(result, 2)
      }
    }
  } catch (error) {
    if (process.env.NODE_ENV !== "test") {
      console.log(`error for formula "${interpolatedFormula}" ::`)
      console.log(error)
    }
    value = undefined
  }

  if (typeof value !== "undefined" && math.isNaN(value)) {
    value = math.bignumber(0)
  }

  return { value, hasAllRequiredDatapoints }
}

export const calculateFormula = (
  formula: string,
  datapoints: RatingDatapoints,
): math.BigNumber | number | undefined => {
  const parser = makeMathParser()
  const interpolatedFormula = interpolateMultipleRecords(formula, datapoints)
  const evaluated = parser.evaluate(interpolatedFormula)
  return evaluated
}
