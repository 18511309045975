import { Alert, AlertIcon, AlertTitle, Button, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { CompleteFreeRatingModal } from "components/CompleteFreeRatingModal"
import { useSubmitDocumentationMutation } from "lib/generated/graphql"
import { useRating } from "lib/providers/RatingProvider"
import { type ReactNode, useMemo } from "react"

export const CompleteOrSubmitRating = () => {
  const { submissionProgress, ratingState, ratingId, isFree } = useRating()

  const toast = useToast()
  const completeFreeRatingModal = useDisclosure()
  const [submitStatus, submit] = useSubmitDocumentationMutation()

  const canSubmit = useMemo(() => {
    return submissionProgress?.allCompleted && ratingState?.phase === "SUBMISSION"
  }, [submissionProgress, ratingState])

  const handleSubmit = async () => {
    const res = await submit({ ratingId })

    if (res.error) {
      toast({
        title: "Feil ved innsending av dokumentasjon",
        description: res.error.toString(),
        status: "error",
        position: "top",
        isClosable: true,
      })
    }

    if (res.data) {
      toast({
        title: "Underlag innsendt",
        status: "success",
        position: "top",
        isClosable: true,
      })
    }
  }

  return (
    <>
      {isFree && ["SUBMISSION", "PROCESSING_DATAFILES"].includes(ratingState?.phase) && (
        <Tooltip
          borderRadius="4px"
          mr="4"
          p="4"
          hasArrow
          isDisabled={canSubmit}
          label="Målingen kan ikke fullføres før alle dokumenter er lastet opp og alle spørsmål er besvart"
        >
          <Button
            size="md"
            colorScheme={"blue"}
            fontWeight={700}
            onClick={completeFreeRatingModal.onOpen}
            isDisabled={!canSubmit}
          >
            Fullfør måling
          </Button>
        </Tooltip>
      )}
      {ratingState?.phase === "COMPLETED" && <CompletedState>Måling er fullført</CompletedState>}
      {!isFree && ratingState?.phase === "SUBMISSION" && (
        <Tooltip
          hasArrow
          isDisabled={canSubmit}
          p="4"
          mr="4"
          label="Underlag kan ikke sendes inn før alle dokumenter er lastet opp og alle spørsmål er besvart"
        >
          <Button
            size="md"
            colorScheme={"blue"}
            fontWeight={700}
            isLoading={submitStatus.fetching}
            onClick={handleSubmit}
            isDisabled={!canSubmit}
          >
            Send inn underlag
          </Button>
        </Tooltip>
      )}
      {["RATING_IN_PROGRESS", "PROCESSING_DOCUMENTATION"].includes(ratingState?.phase) && (
        <CompletedState>Underlag er levert</CompletedState>
      )}
      <CompleteFreeRatingModal isOpen={completeFreeRatingModal.isOpen} onClose={completeFreeRatingModal.onClose} />
    </>
  )
}

const CompletedState = ({ children }: { children: ReactNode }) => (
  <Alert status="success" bg="none" flexDirection="row" alignItems="center" justifyContent="end" p="0">
    <AlertIcon boxSize="18px" mr="8px" />
    <AlertTitle fontSize="md" color="gray.700">
      {children}
    </AlertTitle>
  </Alert>
)
