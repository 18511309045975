import { Box, Button, Flex, useToast } from "@chakra-ui/react"
import { type BRREGUnit, type MinimalContactInfo, RatingType } from "@esgt/types"
import { validateContactPerson, validatePeriod } from "@esgt/utils"
import { ContentBox } from "components/ContentBox"
import { InfoHeadingPanel } from "components/InfoHeadingPanel"
import { SiteTitle } from "components/SiteTitle"
import { type OrderSelfServiceRatingMutationVariables, useOrderSelfServiceRatingMutation } from "lib/generated/graphql"
import { useCurrentUser } from "lib/providers/CurrentUser"
import { routes } from "lib/routes"
import { NewRatingStep } from "pages/Ratings/NewRating/NewRatingStep"
import {
  type RatingContactInfo,
  RatingContributorsForm,
} from "pages/Ratings/NewRating/contributors/RatingContributorsForm"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { AgreementForm, type AgreementSettings } from "./AgreementForm"
import { CompanyInfoForm } from "./CompanyInfoForm"
import { type NewRatingPeriodSettings, RatingPeriodForm } from "./RatingPeriodForm"
import { type NewRatingProfileSettings, RatingProfileForm } from "./RatingProfileForm"

export type NewRatingValues = {
  primaryContact: RatingContactInfo
  period: Partial<NewRatingPeriodSettings>
  contributors: Array<MinimalContactInfo>
  ratingTypeSelected: RatingType
  ratingProfileId: number
  agreementAccepted: boolean
}

const Header = styled.div`
	margin-bottom: 32px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-end;
`

const LAST_YEAR = new Date().getFullYear() - 1
const START_OF_LAST_YEAR = { year: LAST_YEAR, month: 1 }
const END_OF_LAST_YEAR = { year: LAST_YEAR, month: 12 }

export const NewRating = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const [orderRatingResult, orderSelfServiceRating] = useOrderSelfServiceRatingMutation()
  const user = useCurrentUser()
  const INITIAL_VALUES: NewRatingValues = {
    primaryContact: {
      name: user?.user.name || "",
      email: user?.user.email || "",
      phone: user?.user.phone || "",
    },
    period: {
      periodStart: START_OF_LAST_YEAR,
      periodEnd: END_OF_LAST_YEAR,
    },
    ratingProfileId: null,
    contributors: [],
    ratingTypeSelected: RatingType.Free,
    agreementAccepted: false,
  }

  const [selectedOrganization, setSelectedOrganization] = useState<BRREGUnit>(null)
  const [values, setValues] = useState<NewRatingValues>(INITIAL_VALUES)

  const setContributorsValues = useCallback((contributors: Array<MinimalContactInfo>) => {
    setValues((current) => ({ ...current, contributors }))
  }, [])

  const setRatingPeriodValues = useCallback(
    (updates: NewRatingPeriodSettings) => {
      setValues((current) => ({ ...current, period: updates }))
    },
    [setValues],
  )

  const setRatingProfileValues = useCallback(
    (updates: NewRatingProfileSettings) => {
      setValues((current) => ({ ...current, ...updates }))
    },
    [setValues],
  )

  const setAgreementValues = useCallback(
    (updates: AgreementSettings) => {
      setValues((current) => ({ ...current, ...updates }))
    },
    [setValues],
  )

  const nonEmptyContributors = values.contributors.filter((c) => c.name.length || c.email.length)

  const periodValidity = useMemo(
    () =>
      validatePeriod({
        start: values.period.periodStart,
        end: values.period.periodEnd,
      }),
    [values.period],
  )

  const isSubmittable =
    selectedOrganization?.organisasjonsnummer &&
    values.primaryContact.name &&
    values.primaryContact.email &&
    periodValidity.valid &&
    values.ratingProfileId &&
    values.ratingTypeSelected &&
    values.agreementAccepted &&
    nonEmptyContributors.every(validateContactPerson)

  useEffect(() => {
    if (orderRatingResult.error) {
      toast({
        title: "Kunne ikke opprette måling",
        status: "error",
        position: "top",
        isClosable: true,
      })
    } else if (orderRatingResult.data) {
      navigate(routes.rating(orderRatingResult.data.orderSelfServiceRating.key, "?new=true"))
    }
  }, [navigate, orderRatingResult, toast])
  const onSubmit = () => {
    orderSelfServiceRating(createOrderSelfServiceRatingQuery(values, selectedOrganization.organisasjonsnummer))
  }

  return (
    <Flex height="full" alignItems="center" justifyContent="center">
      <Box maxWidth="820px" mx="8">
        <SiteTitle title={"Opprett bærekraftsmåling"} />
        <Header>
          <InfoHeadingPanel
            as="h1"
            size="lg"
            style={{ marginTop: "70px" }}
            helpTextBg="white"
            helpText="helpTexts.newRating.createRating"
          >
            Opprett bærekraftsmåling
          </InfoHeadingPanel>
        </Header>
        <Flex direction="column" gap={9} paddingBottom={12}>
          <NewRatingStep>
            <CompanyInfoForm
              selectedOrganization={selectedOrganization}
              setSelectedOrganization={setSelectedOrganization}
            />

            <RatingContributorsForm
              primaryContact={values.primaryContact}
              contributors={values.contributors}
              setContributors={setContributorsValues}
              isSelfServiceRating={true}
            />

            <RatingPeriodForm values={values.period} setValues={setRatingPeriodValues} />

            <ContentBox padding={0}>
              <RatingProfileForm
                values={values}
                setValues={setRatingProfileValues}
                selectedOrganization={selectedOrganization}
              />

              <AgreementForm agreementAccepted={values.agreementAccepted} setAgreementValues={setAgreementValues} />
            </ContentBox>

            <Flex justifyContent="center" gap="4">
              <Button as={RouterLink} to="../" size="lg" variant="outline">
                Avbryt
              </Button>
              <Button
                size="lg"
                colorScheme={"blue"}
                onClick={onSubmit}
                isLoading={orderRatingResult.fetching}
                isDisabled={!isSubmittable}
              >
                Opprett bærekraftsmåling
              </Button>
            </Flex>
          </NewRatingStep>
        </Flex>
      </Box>
    </Flex>
  )
}

function createOrderSelfServiceRatingQuery(
  values: NewRatingValues,
  orgNr: string,
): OrderSelfServiceRatingMutationVariables {
  return {
    orgNr,
    contactPerson: {
      email: values.primaryContact.email,
      name: values.primaryContact.name,
      phone: values.primaryContact.phone,
    },
    period: {
      start: values.period.periodStart,
      end: values.period.periodEnd,
    },
    ratingProfileId: Number(values.ratingProfileId),
    contributors: [
      {
        email: values.primaryContact.email,
        name: values.primaryContact.name,
      },
      ...values.contributors.filter(validateContactPerson),
    ],
    ratingType: RatingType.Free,
    ratingTypeSelected: values.ratingTypeSelected,
    agreementAccepted: values.agreementAccepted,
  }
}
