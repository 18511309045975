import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from "@chakra-ui/react"
import { I18nText } from "components/I18nText"
import type { I18nKeys } from "lib/i18n/nb"
import styled from "styled-components"

const AccordionHeader = styled(AccordionButton)`
  p {
    margin-bottom: 0;
  }
`

interface HelpAccordionProps {
  helpAccordionQuestion: I18nKeys
  helpAccordionAnswer: I18nKeys
}

export const HelpAccordionItem: React.FC<HelpAccordionProps> = ({ helpAccordionQuestion, helpAccordionAnswer }) => {
  return (
    <AccordionItem p="0" borderColor="neutral.100">
      <AccordionHeader>
        <Box as="span" flex="1" textAlign="left" fontSize="lg" p="3">
          <I18nText i18nKey={helpAccordionQuestion} />
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel px="7" pb="6">
        <I18nText i18nKey={helpAccordionAnswer} />
      </AccordionPanel>
    </AccordionItem>
  )
}
