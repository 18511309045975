import { CloseIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input } from "@chakra-ui/react"
import { useFieldArray, useFormContext } from "react-hook-form"
import type { NewAccount } from "./types"

export const AccountAdmins: React.FC = () => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<NewAccount>()

  const { fields, remove, append } = useFieldArray({ control, name: "admins" })

  return (
    <div>
      <Box mt={8} mb={4}>
        <Heading as="h2" size="md">
          Konto medeiere
        </Heading>
      </Box>
      {fields.map((field, index) => (
        <Flex key={field.id} gap={2}>
          <FormControl isInvalid={!!errors.admins?.[index]?.name}>
            <FormLabel htmlFor={`admins.${index}.name`}>Navn</FormLabel>
            <Input
              id={`admins.${index}.name`}
              placeholder="Navn"
              autoFocus={true}
              {...register(`admins.${index}.name`, { required: "Fyll ut fullt navn" })}
            />
            {errors.admins?.[index]?.name && (
              <FormErrorMessage>{errors.admins?.[index]?.name.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!errors.admins?.[index]?.email}>
            <FormLabel htmlFor={`admins.${index}.email`}>E-post</FormLabel>
            <Input
              id={`admins.${index}.email`}
              placeholder="E-post"
              type={"email"}
              {...register(`admins.${index}.email`, { required: "Fyll ut gyldig e-post" })}
            />
            {errors.admins?.[index]?.email && (
              <FormErrorMessage>{errors.admins?.[index]?.email.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl>
            <FormLabel htmlFor={`admins.${index}.phone`}>Telefonnummer</FormLabel>
            <Input id={`admins.${index}.phone`} placeholder="Telefonnummer" {...register(`admins.${index}.phone`)} />
          </FormControl>
          <Button variant="ghost" mt={8} onClick={() => remove(index)}>
            <CloseIcon />
          </Button>
        </Flex>
      ))}
      <Button variant="link" mt={3} onClick={() => append({ email: "", name: "", phone: "" })}>
        Legg til medeier
      </Button>
    </div>
  )
}
