import type { Question, RatingProfileConfig } from "@esgt/types"
import { isKpiDisabled } from "../isKpiDisabled"

export function isMissingAllKpiDeps(question: Question, ratingProfileConfig: RatingProfileConfig) {
  return (
    question.hideIfAllDisabled &&
    question.hideIfAllDisabled.length > 0 &&
    question.hideIfAllDisabled.every((kpiId) => isKpiDisabled(ratingProfileConfig, kpiId))
  )
}
