import { Heading } from "@chakra-ui/react"
import { CenteredContent } from "components/CenteredContent"
import type { ReactNode } from "react"
import styled from "styled-components"
import { ContentBox } from "./ContentBox"

const Header = styled.div`
	margin-bottom: 24px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-end;
`
const Container = styled.div`
	flex: 1;
	display: flex;
	flex-flow: column nowrap;
	overflow: hidden;
	padding: 0 24px;
	margin: 70px 0;
`

type NewItemProps = {
  children: ReactNode
  headingText: string
  maxWidth?: string
}

export const NewItem: React.FC<NewItemProps> = ({ children, headingText, maxWidth = "700px" }: NewItemProps) => (
  <CenteredContent maxWidth={maxWidth}>
    <Container>
      <ContentBox overflow="auto">
        <Header>
          <Heading as="h1" size="lg" mt="24px">
            {headingText}
          </Heading>
        </Header>
        {children}
      </ContentBox>
    </Container>
  </CenteredContent>
)
