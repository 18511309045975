import type { BRREGUnit, RatingType } from "@esgt/types"
import { NewRatingStep } from "pages/Ratings/NewRating/NewRatingStep"
import { CompanyInfo } from "pages/Ratings/NewRating/orderInfo/CompanyInfo"
import type { Dispatch, SetStateAction } from "react"

export type CompanyInfoSettings = {
  ratingTypeSelected: RatingType
  agreementAccepted: boolean
}

interface CompanyInfoFormProps {
  selectedOrganization: BRREGUnit
  setSelectedOrganization: Dispatch<SetStateAction<BRREGUnit>>
}

export const CompanyInfoForm = ({ selectedOrganization, setSelectedOrganization }: CompanyInfoFormProps) => {
  return (
    <NewRatingStep as="form">
      <CompanyInfo selectedOrganization={selectedOrganization} setSelectedOrganization={setSelectedOrganization} />
    </NewRatingStep>
  )
}
