import type { RatingState } from "@esgt/event-store"
import type { MethodConfig, RatingProfileConfig } from "@esgt/types"
import { isMissingAllKpiDeps } from "./question/isMissingAllKpiDeps"

export const filterCategoriesWithoutQuestions = (
  ratingState: Pick<RatingState, "answerValues" | "additionalQuestions" | "uploads" | "ratingType">,
  methodConfig: MethodConfig,
  categories: MethodConfig["questionCategories"],
  ratingProfileConfig?: RatingProfileConfig,
) => {
  if (!categories || !methodConfig) return []
  return categories.filter((category) => {
    const questions = methodConfig.questions.filter((q) => category?.questionIds.includes(q.id))

    return (
      !ratingProfileConfig ||
      questions.some(
        (q) =>
          !isMissingAllKpiDeps(q, ratingProfileConfig) &&
          !(q.dependsOn && ratingState?.answerValues[q.dependsOn]?.primaryAnswer !== "true"),
      )
    )
  })
}
