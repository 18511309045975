import { Text, Textarea } from "@chakra-ui/react"
import ContentAccordion from "components/ContentAccordion"
import { ContentAccordionLeft } from "components/ContentAccordionLeft"
import { ContentAccordionRight } from "components/ContentAccordionRight"
import { FoundationHeader } from "components/FoundationHeader"
import FoundationMainContent from "components/FoundationMainContent"
import { useUpdateRatingCompanyTextMutation } from "lib/generated/graphql"
import { useRating } from "lib/providers/RatingProvider"
import { useEffect } from "react"
import { useForm } from "react-hook-form"

export const CompanyStrategy = () => {
  const { ratingId, ratingState, submissionProgress } = useRating()
  if (!ratingState) return null

  const [_saveStatus, save] = useUpdateRatingCompanyTextMutation()
  const isReadOnly =
    ratingState.phase !== "SUBMISSION" ||
    (ratingState.reopenedScopes && !ratingState.reopenedScopes?.includes("companyTexts"))

  const scope = "companyStrategy"

  const form = useForm({
    defaultValues: {
      text: ratingState.companyTexts[scope],
    },
    mode: "onBlur",
  })

  useEffect(() => {
    ratingState.companyTexts[scope] && form.setValue("text", ratingState.companyTexts[scope])
  }, [ratingState.companyTexts])

  const saveChanges = () => {
    if (isReadOnly) return

    save({
      ratingId,
      scope,
      text: form.getValues("text"),
    })
  }

  return (
    <FoundationMainContent>
      <FoundationHeader title="Bærekraftsstrategi" />
      <ContentAccordion
        headingText={"Bærekraftsstrategi"}
        completed={submissionProgress?.companyTexts?.companyStrategy}
        readOnly={isReadOnly}
      >
        <ContentAccordionLeft>
          <Text mb="2" fontWeight={500}>
            Skriv inn deres strategier knyttet til bærekraft
          </Text>
          <Textarea isDisabled={isReadOnly} {...form.register("text")} h="300" bgColor="white" onBlur={saveChanges} />
        </ContentAccordionLeft>
        <ContentAccordionRight>
          <Text mb="4">
            Denne teksten plasseres under deres bærekraftsmål. I rapporten vil det stå "I strategien har vi følgende om
            bærekraft" over det dere skriver inn her.
          </Text>
          <Text mb="4">
            Her kan dere inkludere deres bærekraftstrategi eller hvordan bærekraft er integrert i strategien deres. Har
            dere ikke en strategi for dette enda, kan dere beskrive hvorfor det ikke er gjort og når det skal gjøres.
            Hvis bærekraftsrapporten fra ESG Trackr brukes til å komme i gang med deres bærekraftsarbeid, så kan dere
            skrive det.
          </Text>
        </ContentAccordionRight>
      </ContentAccordion>
    </FoundationMainContent>
  )
}
