import { isSelfServiceUser } from "@esgt/auth"
import { type CurrentUserQuery, useCurrentUserQuery } from "lib/generated/graphql"
import { useAuth } from "lib/providers/AuthProvider"
import { createContext, useContext, useEffect, useRef } from "react"
import type { CombinedError } from "urql"

interface CurrentUserContext {
  user?: CurrentUserQuery["currentUser"]
  account?: CurrentUserQuery["currentAccount"]
  isSelfServiceUser?: boolean
  fetching?: boolean
  error?: CombinedError
}

export const context = createContext<CurrentUserContext>({})

const { Provider: DefaultProvider } = context

export const Provider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { token } = useAuth()

  const fetchedToken = useRef(token)
  const fetchedData = useRef<CurrentUserQuery>()
  const isNewToken = fetchedToken.current !== token
  const pause = (!token && !isNewToken) || fetchedData.current

  const [{ fetching, data, error }, refetch] = useCurrentUserQuery({
    pause: !!pause,
  })

  useEffect(() => {
    if (data) {
      fetchedData.current = data
    }
  }, [data])

  useEffect(() => {
    if (isNewToken && !fetching) {
      fetchedToken.current = token
      refetch({ requestPolicy: "network-only" })
    }
  }, [token, isNewToken])

  const currentUser = fetchedData.current?.currentUser || data?.currentUser
  const currentAccount = fetchedData.current?.currentAccount || data?.currentAccount

  const value = {
    user: fetching ? undefined : currentUser,
    isSelfServiceUser: isSelfServiceUser(currentUser),
    account: fetching ? undefined : currentAccount,
    fetching,
    error,
  }

  return <DefaultProvider value={value}>{children}</DefaultProvider>
}

export const useCurrentUser = () => {
  return useContext(context)
}
