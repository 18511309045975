import type { UserAccessRole } from "@esgt/types"

export type ApplicationPermissions =
  // Admin
  | "system:write"

  // Accounts
  | "account:read"
  | "account:update"
  | "account:administer_admins"
  | "account:administer_raters"

  // Ratings within account
  | "account:rating:read_all"
  | "account:rating:update_all"
  | "account:rating:create"
  | "account:rating:create_free"

  // Single ratings
  | "rating:read"
  | "rating:input"
  | "rating:update"
  | "rating:control"

  // Self service
  | "self_service:read"

type UserPermissionsType = {
  [key in UserAccessRole]: readonly ApplicationPermissions[]
}

type entityType = "system" | "account" | "rating" | "self_service"

export const UserPermissions: UserPermissionsType = {
  Admin: ["system:write"],
  AccountOwner: [
    "account:read",
    "account:update",
    "account:rating:read_all",
    "account:rating:update_all",
    "account:rating:create",
    "account:administer_raters",
    "account:administer_admins",
  ],
  AccountAdmin: [
    "account:read",
    "account:update",
    "account:rating:read_all",
    "account:rating:update_all",
    "account:rating:create",
    "account:administer_raters",
  ],
  AccountMember: ["account:rating:create"],
  Rater: ["rating:read", "rating:input", "rating:update", "rating:control"],
  ContactPerson: ["rating:read", "rating:input"],
  SelfServiceUser: ["account:rating:create_free", "self_service:read"],
}

export const getUserAccessTypes = (role: UserAccessRole, entityType: entityType): string[] => {
  return UserPermissions[role].map((p) => p.split(`${entityType}:`)[1]).filter(Boolean)
}

export const getUserRole = (accessTypes: string[], entityType: entityType) => {
  for (const role of Object.keys(UserPermissions) as (keyof UserPermissionsType)[]) {
    if (
      UserPermissions[role].every((up) => accessTypes.includes(up.split(`${entityType}:`)[1] as ApplicationPermissions))
    ) {
      return role
    }
  }
}

type UserAccess = {
  entityId: string
  entityType: string
  accessTypes: string[]
}

type User = {
  access?: UserAccess[]
}

export const isSuperAdmin = (user: User): boolean => {
  return !!user?.access?.find((access) => access.entityType === "system" && access.accessTypes?.includes("write"))
}

export const isSelfServiceUser = (user: User): boolean => {
  return !!user?.access?.find((access) => access.entityType === "self_service" && access.accessTypes?.includes("read"))
}

/**
 * Checks if the user has the given permission for the given entity
 *
 * @param user
 * @param permission
 * @param entityId - If entityId is "." then the permission is checked for all entities of the given type
 * @returns boolean
 */
export const doesUserHavePermission = (
  user: User,
  permission: ApplicationPermissions,
  entityId: string | number | undefined | null,
): boolean => {
  if (isSuperAdmin(user)) return true

  const [entityType, entityPermission] = permission.split(/:(.+)/)
  return !!(
    entityId &&
    user.access?.find(
      (access) =>
        (entityId === "." || access.entityId === String(entityId)) &&
        access.entityType === entityType &&
        access.accessTypes?.includes(entityPermission),
    )
  )
}

export const doesUserHavePermissionToModifyUser = (
  user: User,
  isUserToCheckAdmin: boolean,
  accountId: number | undefined,
) => {
  return doesUserHavePermission(
    user,
    isUserToCheckAdmin ? "account:administer_admins" : "account:administer_raters",
    accountId,
  )
}
