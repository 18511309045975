import {
  type Account,
  type CreateUserInAccountMutation,
  type CreateUserInAccountMutationVariables,
  type DeleteAccountMutation,
  type DeleteAccountMutationVariables,
  type DeleteAccountUserInput,
  type DeleteAccountUserMutation,
  type DeleteAccountUserMutationVariables,
  type NewUserInAccountInput,
  type UpdateAccountUserInput,
  type UpdateAccountUserMutation,
  type UpdateAccountUserMutationVariables,
  useAccountQuery,
  useCreateUserInAccountMutation,
  useDeleteAccountMutation,
  useDeleteAccountUserMutation,
  useUpdateAccountUserMutation,
} from "lib/generated/graphql"
import { createContext, useContext } from "react"
import type { CombinedError, OperationResult } from "urql"

export const AccountEditingContext = createContext<{
  fetching: boolean
  account: Account
  deleteUser: ({
    input,
  }: {
    input: DeleteAccountUserInput
  }) => Promise<OperationResult<DeleteAccountUserMutation, DeleteAccountUserMutationVariables>>
  deletingUserInProgress: boolean
  userDeleted: boolean | null
  deleteUserError: CombinedError
  createUser: ({
    input,
  }: {
    input: NewUserInAccountInput
  }) => Promise<OperationResult<CreateUserInAccountMutation, CreateUserInAccountMutationVariables>>
  createUserInProgress: boolean
  userCreated: boolean | null
  createUserError: CombinedError
  updateUser: ({
    input,
  }: {
    input: UpdateAccountUserInput
  }) => Promise<OperationResult<UpdateAccountUserMutation, UpdateAccountUserMutationVariables>>
  updateUserInProgress: boolean
  userUpdated: boolean | null
  updateUserError: CombinedError
  deleteAccount: ({
    accountId,
  }: {
    accountId: number
  }) => Promise<OperationResult<DeleteAccountMutation, DeleteAccountMutationVariables>>
  accountDeleted: boolean
  accountDeletedError: CombinedError
}>({} as any)

interface AccountEditingProviderProps {
  accountId: string
  children?: React.ReactNode
}

export const AccountEditingProvider: React.FC<AccountEditingProviderProps> = ({ children, accountId }) => {
  const [query] = useAccountQuery({
    variables: { id: accountId },
    requestPolicy: "network-only",
    pause: false,
  })

  const [deleteUserStatus, deleteUser] = useDeleteAccountUserMutation()
  const [deleteAccountStatus, deleteAccount] = useDeleteAccountMutation()
  const [createUserStatus, createUser] = useCreateUserInAccountMutation()
  const [updateUserStatus, updateUser] = useUpdateAccountUserMutation()

  const context = {
    fetching: query.fetching,
    account: query.data?.account || null,
    deleteAccount,
    accountDeleted:
      deleteAccountStatus &&
      deleteAccountStatus.data &&
      deleteAccountStatus.data.deleteAccount &&
      deleteAccountStatus.data.deleteAccount.deletedAt !== null,
    accountDeletedError: deleteAccountStatus.error,
    deleteUser,
    deletingUserInProgress: deleteUserStatus.fetching,
    deleteUserError: deleteUserStatus.error,
    userDeleted: deleteUserStatus?.data?.deleteAccountUser?.deletedAt
      ? deleteUserStatus.data.deleteAccountUser.deletedAt !== null
      : null,

    createUser,
    createUserInProgress: createUserStatus.fetching,
    createUserError: createUserStatus.error,
    userCreated: createUserStatus?.data?.createUserInAccount?.id
      ? createUserStatus.data.createUserInAccount.id !== null
      : null,

    updateUser,
    updateUserInProgress: updateUserStatus.fetching,
    updateUserError: updateUserStatus.error,
    userUpdated: updateUserStatus?.data?.updateAccountUser?.id
      ? updateUserStatus.data.updateAccountUser.id !== null
      : null,
  }

  return <AccountEditingContext.Provider value={context}>{children}</AccountEditingContext.Provider>
}

export const useAccountEditing = () => {
  return useContext(AccountEditingContext)
}
