import { Flex, NumberInput, NumberInputField, type NumberInputProps, Switch, Text } from "@chakra-ui/react"
import type React from "react"
import styled from "styled-components"
import { useRevisionEditing } from "../RatingEditingContext"

const BenchmarkLevels2 = [
  { name: "Meget svak", color: "#b04f4f" },
  { name: "Svak", color: "#e38e4b" },
  { name: "Tilfreds", color: "#eabb3b" },
  { name: "God", color: "#82aa63" },
  { name: "Meget god", color: "#548a3e" },
]

const Container = styled.div`
  font-size: 14px;
  display: flex;
  flex-flow: row;
  gap: 16px;
  align-items: center;
`

const ValueInput = styled(NumberInput)<{ $color: string }>`
  &.chakra-numberinput {
    border-radius: 6px;
    border: 1px solid var(--chakra-colors-gray-300);
    border-top: 3px solid ${(p) => p.$color};
    display: block;
    height: var(--chakra-sizes-10);
    width: 100%;
    
    > input {
      padding: 0px 12px;
      border: none;
    }

    &:focus {
      border-color: ${(p) => p.$color};
    }

    &:disabled {
      pointer-events: none;
    }
  }
`
const WeightInput = styled(NumberInput)`
  &.chakra-numberinput {
    display: block;
    height: var(--chakra-sizes-10);

    text-align: center;
    min-width: 50px;
    
    > input {
      padding: 0px 6px;
      text-align: center;
    }

    &:focus {
      outline: none;
    }
  }
`

const KPIHeading = styled.div`
  align-items: center;
  display: flex;
  gap: 14px;
`

const KPI = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 18px;
`

// A way to support , as decimal separator
const commonNumberInputProps: NumberInputProps = {
  isValidCharacter: (char) => !!char.match(/^[Ee0-9+\-.,]$/),
}

const parseValue = (value?: string) => {
  if (value?.endsWith(",") || value?.endsWith(".")) {
    return value
  }

  return value?.length > 0 ? Number.parseFloat(value.replace(/,/, ".")) : undefined
}

export const ConfigLine: React.FC<{
  itemType: "kpis" | "categories" | "dimensions"
  itemId: string
  unit?: string
  title?: string | React.ReactNode
  disabled?: boolean
}> = ({ itemType, itemId, unit = "%", title = "A title", disabled = false }) => {
  const { baselineConfig, setValue, onSave, revisionConfig, isReadOnly, isBaselineProfile } = useRevisionEditing()

  const baseSet = baselineConfig[itemType]?.[itemId]
  const updatedConfigSet = revisionConfig[itemType]?.[itemId]

  const isDisabledByBaseConfig = baseSet?.enabled === false

  const isEnabled = isBaselineProfile
    ? updatedConfigSet?.enabled !== false
    : updatedConfigSet?.enabled !== false && !isDisabledByBaseConfig

  return (
    <KPI>
      <KPIHeading>
        <Switch
          value={"true"}
          isDisabled={disabled}
          isChecked={isEnabled}
          isReadOnly={isReadOnly}
          style={{ opacity: isReadOnly ? 0.5 : 1 }}
          onChange={() => {
            setValue(itemType, itemId, "enabled", !isEnabled, true)
          }}
        />
        <Flex align="baseline" gap={4}>
          {title}

          {isDisabledByBaseConfig && (
            <Text fontSize="sm" fontStyle="italic">
              (Skrudd av i grunnlags-profilen)
            </Text>
          )}
        </Flex>
      </KPIHeading>
      <Container>
        {BenchmarkLevels2.map((bm, i) => {
          const baselineValue = baseSet?.[`benchmark_${i}`]
          const updatedConfigValue = updatedConfigSet?.[`benchmark_${i}`]

          return (
            <ValueInput
              key={i}
              $color={bm.color}
              allowMouseWheel={false}
              value={updatedConfigValue || ""}
              onChange={(value) => setValue(itemType, itemId, `benchmark_${i}`, parseValue(value))}
              onBlur={() => onSave()}
              isDisabled={disabled || isReadOnly || !isEnabled}
              isReadOnly={isReadOnly}
              {...commonNumberInputProps}
            >
              <NumberInputField
                placeholder={
                  !updatedConfigValue && !isBaselineProfile
                    ? typeof baselineValue !== "undefined"
                      ? baselineValue
                      : bm.name
                    : bm.name
                }
              />
            </ValueInput>
          )
        })}
        <span style={{ minWidth: "70px" }}>{unit}</span>
        <div>Vekting</div>
        <WeightInput
          allowMouseWheel={false}
          value={updatedConfigSet?.weight || ""}
          onChange={(value) => setValue(itemType, itemId, "weight", parseValue(value))}
          onBlur={() => onSave()}
          isDisabled={disabled || isReadOnly || !isEnabled}
          isReadOnly={isReadOnly}
          {...commonNumberInputProps}
        >
          <NumberInputField placeholder={!updatedConfigSet?.weight ? baseSet?.weight || "1" : "1"} />
        </WeightInput>
      </Container>
    </KPI>
  )
}
