import { Flex, Heading, Text } from "@chakra-ui/react"
import type { BRREGUnit } from "@esgt/types"
import type { RatingContactInfo } from "../orderInfo/CompanyInfoForm"
import { MissingWarning } from "./Warning"

interface CompanySummaryProps {
  selectedOrganization: BRREGUnit
  primaryContact: RatingContactInfo
}

export const CompanySummary = ({ selectedOrganization, primaryContact }: CompanySummaryProps) => {
  return (
    <Flex direction="row" gap={4} justifyContent="space-between">
      {selectedOrganization ? (
        <Flex basis="50%" direction="column">
          <Text>{selectedOrganization.navn}</Text>
          <Text>{selectedOrganization.forretningsadresse.adresse}</Text>
          <Text>
            {selectedOrganization.forretningsadresse.postnummer} {selectedOrganization.forretningsadresse.poststed}{" "}
            {selectedOrganization.forretningsadresse.land}
          </Text>

          <Text paddingTop={2}>Org. nr.: {selectedOrganization.organisasjonsnummer}</Text>
        </Flex>
      ) : (
        <MissingWarning level="error" label="selskap" />
      )}

      <Flex basis="50%" direction="column" gap={1}>
        <Heading as="h3" size="sm">
          Kontaktperson
        </Heading>

        <MissingWarning level="error" label="navn">
          {primaryContact.name && <Text as="span">{primaryContact.name}</Text>}
        </MissingWarning>

        <MissingWarning level="error" label="e-post">
          {primaryContact.email && <Text as="span">{primaryContact.email}</Text>}
        </MissingWarning>

        <MissingWarning level="warning" label="telefonnummer">
          {primaryContact.phone && <Text as="span">{primaryContact.phone}</Text>}
        </MissingWarning>
      </Flex>
    </Flex>
  )
}
