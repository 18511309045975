import type { RatingState } from "@esgt/event-store"

export type CompanyTextsProgress = {
  aboutCompany: boolean
  companyGoals: boolean
  companyStrategy: boolean
}

export function companyTextsProgress(ratingState: RatingState): CompanyTextsProgress {
  const status: CompanyTextsProgress = {
    aboutCompany: textIsValid(ratingState.companyTexts?.aboutCompany),
    companyGoals: textIsValid(ratingState.companyTexts?.companyGoals),
    companyStrategy: textIsValid(ratingState.companyTexts?.companyStrategy),
  }
  return status
}

export function textIsValid(text?: string) {
  return !!text && typeof text === "string" && text.trim().length > 0
}
