import {} from "@chakra-ui/icons"
import { IconButton, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from "@chakra-ui/react"
import { doesUserHavePermission } from "@esgt/auth"
import { AbortRatingModal } from "components/AbortRatingModal"
import { IconMoreVertical } from "components/Icons"
import { useCurrentUser } from "lib/providers/CurrentUser"
import { RatingProvider } from "lib/providers/RatingProvider"
import { routes } from "lib/routes"
import { Link } from "react-router-dom"
import type { Rating, RatingState } from "../types"
import type { CardAction } from "./types"

interface RatingCardMenuProps {
  rating: Rating
  state: RatingState
}

export const RatingCardMenu: React.FC<RatingCardMenuProps> = ({ rating, state }) => {
  const { user } = useCurrentUser()

  const cancelModal = useDisclosure()

  const showCancel =
    !["COMPLETED", "CANCELLED"].includes(state) && doesUserHavePermission(user, "rating:update", rating.key)

  const isCompleted = ["COMPLETED"].includes(state)

  const menuItems: Array<CardAction & { color?: string }> = [
    {
      label: state === "PENDING_BASIS" ? "Se indikatorer" : "Se grunnlagsdata",
      link:
        state === "PENDING_BASIS"
          ? routes.ratings(`${rating.key}/indicators`)
          : routes.rating(rating.key, "foundation"),
    },
    isCompleted && {
      link: routes.ratings(`${rating.key}/indicators`),
      label: "Se indikatorer",
    },
    {
      link: routes.ratings(`${rating.key}/settings`),
      label: "Målingsinnstillinger",
    },
    {
      link: routes.ratings(`${rating.key}/logs`),
      label: "Logg",
    },
    showCancel && {
      action: cancelModal.onOpen,
      label: "Kansellér måling",
      color: "#8A260F",
    },
  ]

  return (
    <>
      <Menu>
        <MenuButton as={IconButton} icon={<IconMoreVertical boxSize="5" />} size="sm" variant="ghost" />
        <MenuList>
          {menuItems.filter(Boolean).map((item) => (
            <MenuItem
              key={item.label}
              color={item.color}
              onClick={item.action}
              {...(item.link && { as: Link, to: item.link })}
            >
              {item.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      {cancelModal.isOpen && (
        <RatingProvider ratingId={rating.key}>
          <AbortRatingModal isOpen={true} onCancel={cancelModal.onClose} onOk={cancelModal.onClose} />
        </RatingProvider>
      )}
    </>
  )
}
