import type { RatingState } from "@esgt/event-store"
import { ProcessingStatus, RatingFileCategory, RatingType, UploadScopeEnum } from "@esgt/types"

export type RegnskapProgress = {
  saft: boolean
  saldobalanse?: boolean
  aldersfordeltSaldoListe?: boolean
}

export function regnskapProgress(ratingState: RatingState): RegnskapProgress {
  const isFree = ratingState?.ratingType === RatingType.Free

  const status: RegnskapProgress = {
    saft:
      saftFileIsValid(ratingState) &&
      (ratingState.ratingType !== RatingType.Free ||
        ratingState.dataFilesProcessingStatus?.saft?.status === ProcessingStatus.Completed),
  }
  if (!isFree) {
    status.saldobalanse =
      ratingState?.uploads.findIndex(
        (u) => u.ratingFileType === RatingFileCategory.generic && u.scope === UploadScopeEnum.Saldobalanse && u.valid,
      ) >= 0
    status.aldersfordeltSaldoListe =
      ratingState?.uploads.findIndex(
        (u) =>
          u.ratingFileType === RatingFileCategory.generic &&
          u.scope === UploadScopeEnum.AldersfordeltSaldoliste &&
          u.valid,
      ) >= 0
  }
  return status
}

export function saftFileIsValid(ratingState: RatingState) {
  return ratingState?.uploads.findIndex((u) => u.ratingFileType === RatingFileCategory.saft && u.valid) >= 0
}
