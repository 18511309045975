import { Flex } from "@chakra-ui/react"
import type { BRREGUnit, MinimalContactInfo } from "@esgt/types"
import { CenteredContent } from "components/CenteredContent"
import { InfoHeadingPanel } from "components/InfoHeadingPanel"
import { SiteTitle } from "components/SiteTitle"
import { useBreadcrumb } from "components/breadcrumbs/BreadcrumbProvider"
import { ContentNavBarLinks } from "components/common/ContentNavBar"
import { useCallback, useState } from "react"
import { NavLink, Navigate, Route, Routes } from "react-router-dom"
import styled from "styled-components"
import type { RaterOption } from "../RaterOption"
import { NewRatingContributorsStep } from "./contributors/NewRatingContributorsStep"
import { NewRatingOrderInfoStep } from "./orderInfo/NewRatingOrderInfoStep"
import { NewRatingSummary, type NewRatingValues } from "./summary/NewRatingSummary"

const Header = styled.div`
	margin-bottom: 32px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-end;
`

const LAST_YEAR = new Date().getFullYear() - 1
const START_OF_LAST_YEAR = { year: LAST_YEAR, month: 1 }
const END_OF_LAST_YEAR = { year: LAST_YEAR, month: 12 }

const INITIAL_VALUES: NewRatingValues = {
  primaryContact: {
    name: "",
    email: "",
  },
  measurement: {
    periodStart: START_OF_LAST_YEAR,
    periodEnd: END_OF_LAST_YEAR,
  },
  // TODO rename to additionalContacts (everywhere) to match current intention
  contributors: [],
  raters: [],
}

export const NewRating = () => {
  useBreadcrumb("Opprett måling", "/ratings/new")

  const [selectedOrganization, setSelectedOrganization] = useState<BRREGUnit>(null)
  const [values, setValues] = useState<NewRatingValues>(INITIAL_VALUES)

  const setRaterValues = useCallback((raters: Array<RaterOption>) => {
    setValues((current) => ({ ...current, raters }))
  }, [])

  const setContributorsValues = useCallback((contributors: Array<MinimalContactInfo>) => {
    setValues((current) => ({ ...current, contributors }))
  }, [])

  return (
    <CenteredContent flex scrollable maxWidth="900px">
      <SiteTitle title={"Opprett måling"} />
      <Header>
        <InfoHeadingPanel
          as="h1"
          size="lg"
          style={{ marginTop: "70px" }}
          helpTextBg="white"
          helpText="helpTexts.newRating.createRating"
        >
          Opprett måling
        </InfoHeadingPanel>
      </Header>

      <Flex direction="column" gap={9} paddingBottom={12}>
        <ContentNavBarLinks>
          <NavLink to="order">Målingsinformasjon</NavLink>
          <NavLink to="roles">Roller</NavLink>
          <NavLink to="summary">Oppsummering</NavLink>
        </ContentNavBarLinks>

        <Routes>
          <Route
            path="order"
            element={
              <NewRatingOrderInfoStep
                values={values}
                setValues={setValues}
                selectedOrganization={selectedOrganization}
                setSelectedOrganization={setSelectedOrganization}
              />
            }
          />

          <Route
            path="roles"
            element={
              <NewRatingContributorsStep
                contactPerson={values.primaryContact}
                contributors={values.contributors}
                setContributors={setContributorsValues}
                raters={values.raters}
                setRaters={setRaterValues}
              />
            }
          />

          <Route
            path="summary"
            element={<NewRatingSummary values={values} selectedOrganization={selectedOrganization} />}
          />

          <Route path="*" element={<Navigate to="order" replace />} />
        </Routes>
      </Flex>
    </CenteredContent>
  )
}
