import { Flex, Select } from "@chakra-ui/react"
import type { BRREGUnit, PeriodMonth } from "@esgt/types"
import { validatePeriod } from "@esgt/utils"
import { PERIOD_SELECTOR_DEFAULT_MONTHS, RatingPeriodSelector } from "components/RatingPeriodSelector"
import { RatingPeriodInfoTooltipIcon, RatingProfileInfoTooltipIcon } from "components/Tooltips"
import { type RatingProfileSearchResult, useRatingProfilesQuery } from "lib/generated/graphql"
import { NewRatingStep, NewRatingSubstep } from "pages/Ratings/NewRating/NewRatingStep"
import { useEffect, useMemo } from "react"
import { type FieldValues, useForm } from "react-hook-form"

export type NewRatingMeasurementSettings = {
  periodStart: PeriodMonth
  periodEnd: PeriodMonth
  ratingProfileId: number
}

interface MeasurementDetailsFormProps {
  values: Partial<NewRatingMeasurementSettings>
  setValues: (v: Partial<NewRatingMeasurementSettings>) => void
  selectedOrganization?: BRREGUnit
  isSelfServiceRating?: boolean
}

export const MeasurementDetailsForm = ({
  values,
  setValues,
  selectedOrganization,
  isSelfServiceRating,
}: MeasurementDetailsFormProps) => {
  const [ratingProfiles] = useRatingProfilesQuery()

  const defaultProfile = useRatingProfileMatch(selectedOrganization, ratingProfiles.data?.ratingProfiles)

  const { register, watch } = useForm({
    defaultValues: {
      periodStartYear: values.periodStart.year,
      periodStartMonth: isSelfServiceRating ? 1 : values.periodStart.month,
      periodLengthMonths: isSelfServiceRating ? 12 : PERIOD_SELECTOR_DEFAULT_MONTHS,
      ratingProfileId: defaultProfile,
    } as FieldValues,
  })

  const periodValidity = useMemo(
    () =>
      validatePeriod({
        start: values.periodStart,
        end: values.periodEnd,
      }),
    [values.periodStart, values.periodEnd],
  )

  useEffect(() => {
    if (defaultProfile) {
      setValues({ ...values, ratingProfileId: Number(defaultProfile) })
    }
  }, [defaultProfile, setValues])

  useEffect(() => {
    const subscription = watch((value) => {
      const endsInDifferentYear = value.periodStartMonth + value.periodLengthMonths - 1 > 12
      setValues({
        periodStart: {
          year: value.periodStartYear,
          month: value.periodStartMonth,
        },
        periodEnd: {
          year: endsInDifferentYear ? value.periodStartYear + 1 : value.periodStartYear,
          // -1 on these because both ends are inclusive; [jan, feb] is [1, 2]. 2 months, but difference is 1
          month: endsInDifferentYear
            ? value.periodStartMonth + value.periodLengthMonths - 12 - 1
            : value.periodStartMonth + value.periodLengthMonths - 1,
        },
        ratingProfileId: Number(value.ratingProfileId) || Number(defaultProfile),
      })
    })
    return () => subscription.unsubscribe()
  }, [defaultProfile, setValues, watch])

  return (
    <NewRatingStep as="form">
      <NewRatingSubstep
        title={
          <>
            Velg periode <RatingPeriodInfoTooltipIcon />
          </>
        }
      >
        <Flex direction="column" gap={4} width="50%">
          <RatingPeriodSelector
            register={register}
            startYear={values.periodStart.year}
            startMonth={values.periodStart.month}
            periodValidity={periodValidity}
            isSelfServiceRating={isSelfServiceRating}
          />
        </Flex>
      </NewRatingSubstep>

      <NewRatingSubstep
        title={
          <>
            Velg målingsprofil <RatingProfileInfoTooltipIcon />
          </>
        }
      >
        <Select
          width="50%"
          value={values.ratingProfileId || defaultProfile}
          placeholder={ratingProfiles.fetching ? "Henter målingsprofiler..." : "Velg målingsprofil"}
          background="white"
          {...register("ratingProfileId", { required: true, disabled: ratingProfiles.fetching })}
        >
          {ratingProfiles.data?.ratingProfiles.map((rating, index) => (
            <option key={index} value={rating.id}>
              {rating.name} {rating.methodVersion} — {rating.description}
            </option>
          ))}
        </Select>
      </NewRatingSubstep>
    </NewRatingStep>
  )
}

function useRatingProfileMatch(
  selectedOrganization?: BRREGUnit,
  profiles?: Array<RatingProfileSearchResult>,
): number | undefined {
  return useMemo(() => {
    if (!profiles || !profiles.length) {
      return undefined
    }

    const industrycode = selectedOrganization?.naeringskode1?.kode

    // No industrycode present, return baseline
    if (!industrycode) {
      return profiles.find(({ isBaseline }) => isBaseline)?.id
    }

    const profileIdsByNames = profiles.reduce(
      (obj, { id, name }) => {
        obj[name] = id
        return obj
      },
      {} as Record<string, number>,
    )

    if (profileIdsByNames[industrycode]) {
      return profileIdsByNames[industrycode]
    }

    const [primaryCode, subcode] = industrycode.split(".")

    // If no profile for 5.1123, also check 5.112, 5.11, 5.1 and 5
    for (let length = subcode.length - 1; length >= 0; length--) {
      const partialSubcode = subcode.substring(0, length)
      const searchCode = partialSubcode ? `${primaryCode}.${partialSubcode}` : primaryCode

      const resultProfile = profileIdsByNames[searchCode]
      if (resultProfile) {
        return resultProfile
      }
    }

    // Otherwise return baseline profile
    return profiles.find(({ isBaseline }) => isBaseline)?.id
  }, [selectedOrganization, profiles])
}
