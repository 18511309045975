import {
  Card,
  CardBody,
  CardFooter,
  Divider,
  Flex,
  HStack,
  Heading,
  Skeleton,
  type SkeletonProps,
  SkeletonText,
  Tag,
  Text,
} from "@chakra-ui/react"
import { RatingType } from "@esgt/types"
import { FormattedRatingPeriod } from "components/FormattedRatingPeriod"
import { IconCalendarLine, IconHome, IconList } from "components/Icons"
import type { Rating, RatingState } from "../types"
import { RatingCallToAction } from "./RatingCallToAction"
import { RatingCardMenu } from "./RatingCardMenu"

const stateColors: Record<RatingState, string> = {
  PENDING_BASIS: "#F2DDA9",
  PENDING_CONTROL: "#D2E5FA",
  COMPLETED: "#CCCCCC",
  CANCELLED: "#EB65474D",
}

interface RatingCardProps {
  loading?: boolean
  rating?: Rating
  fadeDuration?: number
  state: RatingState | RatingType.Free
}

const loadingRating = {
  companyName: "Selskap AS",
  orgNumber: "123456789",
  period: {
    start: new Date(),
    end: new Date(),
  },
  ratingProfile: {
    name: "Ratingprofil",
  },
  owner: {
    name: "Kontrollør",
  },
  contactPerson: {
    name: "Kontaktperson",
  },
  lastSignificantEventType: "",
}

export const RatingCard: React.FC<RatingCardProps> = ({ fadeDuration, loading, rating: _rating, state }) => {
  const rating = loading ? loadingRating : _rating

  const freeRatingState: RatingState =
    rating.lastSignificantEventType === "RatingCompleted"
      ? "COMPLETED"
      : rating.lastSignificantEventType === "RatingAborted"
        ? "CANCELLED"
        : "PENDING_BASIS"

  const skeletonProps: Partial<SkeletonProps> = {
    isLoaded: Boolean(!loading && rating),
    fadeDuration,
  }

  const isProcessingDocumentation = rating.lastSignificantEventType === "RequestDocumentationProcessing"

  return (
    <Card
      height="14rem"
      maxWidth="42rem"
      borderLeft="16px solid"
      borderColor={
        state === RatingType.Free ? stateColors[freeRatingState] : stateColors[state] || stateColors.COMPLETED
      }
    >
      <CardBody>
        <SkeletonText {...skeletonProps} skeletonHeight="32px" noOfLines={1}>
          <Flex justifyContent="space-between">
            <HStack>
              <Heading fontSize="xl" noOfLines={1}>
                {rating?.companyName}
              </Heading>
              {isProcessingDocumentation && (
                <Tag color="green.600" fontSize="sm" fontWeight="600">
                  Prosesserer dokumentasjon
                </Tag>
              )}
            </HStack>
            {_rating && <RatingCardMenu rating={_rating} state={state === RatingType.Free ? freeRatingState : state} />}
          </Flex>
        </SkeletonText>
        <HStack gap={4} mt="8px" fontSize="sm">
          <SkeletonText {...skeletonProps} noOfLines={1}>
            <Text display="flex" gap={1} alignItems="start">
              <IconHome boxSize="4" mt="1px" /> {rating?.orgNumber}
            </Text>
          </SkeletonText>
          <SkeletonText {...skeletonProps} noOfLines={1}>
            <Text display="flex" gap={1} alignItems="center">
              <IconCalendarLine boxSize="4" /> <FormattedRatingPeriod period={rating?.period} />
            </Text>
          </SkeletonText>
          <SkeletonText {...skeletonProps} noOfLines={1}>
            <Text display="flex" gap={1} alignItems="center">
              <IconList boxSize="4" /> {rating?.ratingProfile?.name}
            </Text>
          </SkeletonText>
        </HStack>
      </CardBody>
      <CardFooter borderTop="1px" borderColor="neutral.100" justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <div>
            <SkeletonText {...skeletonProps} noOfLines={1} color="#999" fontWeight="600">
              Kontrollør
            </SkeletonText>
            <SkeletonText {...skeletonProps} noOfLines={1}>
              <Text noOfLines={1}>{rating?.owner?.name || "-"}</Text>
            </SkeletonText>
          </div>
          <Divider orientation="vertical" height="34px" mx="16px" />
          <div>
            <SkeletonText {...skeletonProps} noOfLines={1} color="#999" size="sm" fontWeight="600">
              Kontaktperson
            </SkeletonText>
            <SkeletonText {...skeletonProps} noOfLines={1} textTransform="capitalize">
              <Text noOfLines={1}>{rating?.contactPerson?.name || "-"}</Text>
            </SkeletonText>
          </div>
        </Flex>
        <Skeleton {...skeletonProps} height="24px" minWidth="192px" display="flex" justifyContent="flex-end">
          {_rating && (
            <RatingCallToAction rating={_rating} state={state === RatingType.Free ? freeRatingState : state} />
          )}
        </Skeleton>
      </CardFooter>
    </Card>
  )
}
