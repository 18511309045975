import { Flex, Heading, Select } from "@chakra-ui/react"
import type { BRREGUnit } from "@esgt/types"
import { RatingProfileInfoTooltipIcon } from "components/Tooltips"
import { type RatingProfileSearchResult, useRatingProfilesQuery } from "lib/generated/graphql"
import { useEffect, useMemo } from "react"
import { type FieldValues, useForm } from "react-hook-form"

export type NewRatingProfileSettings = {
  ratingProfileId: number
}

interface RatingProfileFormProps {
  values: Partial<NewRatingProfileSettings>
  setValues: (v: Partial<NewRatingProfileSettings>) => void
  selectedOrganization?: BRREGUnit
}

export const RatingProfileForm = ({ values, setValues, selectedOrganization }: RatingProfileFormProps) => {
  const [ratingProfiles] = useRatingProfilesQuery()

  const defaultProfile = useRatingProfileMatch(selectedOrganization, ratingProfiles.data?.ratingProfiles)

  const { register, watch } = useForm({
    defaultValues: {
      ratingProfileId: defaultProfile,
    } as FieldValues,
  })

  useEffect(() => {
    if (defaultProfile) {
      setValues({ ...values, ratingProfileId: Number(defaultProfile) })
    }
  }, [defaultProfile, setValues])

  useEffect(() => {
    const subscription = watch((value) => {
      setValues({
        ratingProfileId: Number(value.ratingProfileId) || Number(defaultProfile),
      })
    })
    return () => subscription.unsubscribe()
  }, [defaultProfile, setValues, watch])

  return (
    <Flex gap={7} p={8} direction={"column"} as="form">
      <Flex justifyContent="space-between">
        <Heading as="h2" size="md">
          Velg målingsprofil <RatingProfileInfoTooltipIcon />
        </Heading>
      </Flex>

      <Select
        width="50%"
        value={values.ratingProfileId || defaultProfile}
        placeholder={ratingProfiles.fetching ? "Henter målingsprofiler..." : "Velg målingsprofil"}
        background="white"
        {...register("ratingProfileId", { required: true, disabled: ratingProfiles.fetching })}
      >
        {ratingProfiles.data?.ratingProfiles.map((rating, index) => (
          <option key={index} value={rating.id}>
            {rating.name} {rating.methodVersion} — {rating.description}
          </option>
        ))}
      </Select>
    </Flex>
  )
}

function useRatingProfileMatch(
  selectedOrganization?: BRREGUnit,
  profiles?: Array<RatingProfileSearchResult>,
): number | undefined {
  return useMemo(() => {
    if (!profiles || !profiles.length) {
      return undefined
    }

    const industrycode = selectedOrganization?.naeringskode1?.kode

    // No industrycode present, return baseline
    if (!industrycode) {
      return profiles.find(({ isBaseline }) => isBaseline)?.id
    }

    const profileIdsByNames = profiles.reduce(
      (obj, { id, name }) => {
        obj[name] = id
        return obj
      },
      {} as Record<string, number>,
    )

    if (profileIdsByNames[industrycode]) {
      return profileIdsByNames[industrycode]
    }

    const [primaryCode, subcode] = industrycode.split(".")

    // If no profile for 5.1123, also check 5.112, 5.11, 5.1 and 5
    for (let length = subcode.length - 1; length >= 0; length--) {
      const partialSubcode = subcode.substring(0, length)
      const searchCode = partialSubcode ? `${primaryCode}.${partialSubcode}` : primaryCode

      const resultProfile = profileIdsByNames[searchCode]
      if (resultProfile) {
        return resultProfile
      }
    }

    // Otherwise return baseline profile
    return profiles.find(({ isBaseline }) => isBaseline)?.id
  }, [selectedOrganization, profiles])
}
