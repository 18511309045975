import { RepeatClockIcon } from "@chakra-ui/icons"
import { Button, Td, Text, Tr } from "@chakra-ui/react"
import type { User } from "lib/generated/graphql"

interface ExistingContactPersonRowProps {
  user: User
  isRemoved: boolean
  isPrimaryContact: boolean
  onRemove: () => void
  onRestore: () => void
}

export const ExistingContactPersonRow = ({
  user,
  isRemoved,
  isPrimaryContact,
  onRemove,
  onRestore,
}: ExistingContactPersonRowProps) => {
  return (
    <Tr>
      <Td paddingLeft={1}>
        <Text as="span" decoration={isRemoved ? "line-through" : undefined}>
          {user.name}
        </Text>
      </Td>
      <Td>
        <Text as="span" decoration={isRemoved ? "line-through" : undefined}>
          {user.email}
        </Text>
      </Td>
      <Td textAlign="right" paddingRight={1}>
        {isPrimaryContact ? (
          <Text padding="8px 0">Hovedkontaktperson</Text>
        ) : !isRemoved ? (
          <Button colorScheme="red" variant="outline" size="sm" minWidth={0} onClick={onRemove}>
            Fjern
          </Button>
        ) : (
          <Button variant="outline" size="sm" minWidth={0} onClick={onRestore}>
            <RepeatClockIcon width="16px" height="16px" />
          </Button>
        )}
      </Td>
    </Tr>
  )
}
