import { Icon, type IconProps } from "@chakra-ui/react"

export const IconHomeSecure: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <title>Home Secure</title>
      <path
        d="M0.75 8.875L12 2L23.25 8.875M20.75 7.34722V21.25C20.75 21.4489 20.671 21.6397 20.5303 21.7803C20.3897 21.921 20.1989 22 20 22H4C3.80109 22 3.61032 21.921 3.46967 21.7803C3.32902 21.6397 3.25 21.4489 3.25 21.25V7.34722"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 22V14C15 13.4477 14.5523 13 14 13H10C9.44772 13 9 13.4477 9 14V22"
        stroke="currentColor"
        strokeWidth="1.2"
      />
    </Icon>
  )
}
