import { FormControl, type FormControlProps, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react"
import { useCallback } from "react"
import type { FieldErrors, FieldValues, UseFormClearErrors, UseFormRegister, UseFormSetError } from "react-hook-form"
import { RequiredInputAsterisk } from "./RequiredInputAsterisk"
import { EMAIL_PATTERN, validateEmail } from "./emailValidation"

const EMAIL_ERROR_MESSAGE = "Ugyldig e-postadresse"

interface EmailInputProps extends FormControlProps {
  inputKey: string
  errors?: FieldErrors
  setError: UseFormSetError<FieldValues>
  clearErrors: UseFormClearErrors<FieldValues>
  register: UseFormRegister<FieldValues>
  errorMessage?: string
  label?: string
  value?: string
  isReadOnly?: boolean
  allowEmpty?: boolean
}

export function EmailInput({
  inputKey,
  errors,
  setError,
  clearErrors,
  register,
  errorMessage = EMAIL_ERROR_MESSAGE,
  label,
  value,
  isReadOnly,
  allowEmpty,
  ...formControlProps
}: EmailInputProps) {
  const error = errors?.[inputKey]

  const handleEmailInputBlur = useCallback(
    (event) => {
      const input = event.currentTarget.value
      const isValid = validateEmail(input) || (allowEmpty && input === "")

      if (!isValid) {
        setError(inputKey, { message: errorMessage, type: "pattern" })
      } else {
        clearErrors(inputKey)
      }
    },
    [allowEmpty, setError, inputKey, errorMessage, clearErrors],
  )

  return (
    <FormControl {...formControlProps} isInvalid={error !== undefined}>
      {label && (
        <FormLabel htmlFor="email">
          {label} <RequiredInputAsterisk />
        </FormLabel>
      )}

      <Input
        id="email"
        type="email"
        background={isReadOnly ? "neutral.50" : undefined}
        color={isReadOnly ? "neutral.500" : undefined}
        placeholder="email@example.com"
        readOnly={isReadOnly}
        {...register(inputKey, {
          required: true,
          pattern: { value: EMAIL_PATTERN, message: errorMessage },
          onBlur: handleEmailInputBlur,
          value,
        })}
      />

      {error?.message && <FormErrorMessage>{error.message.toString()}</FormErrorMessage>}
    </FormControl>
  )
}
