import { Text } from "@chakra-ui/react"
import type { ReactNode } from "react"

interface FadedLabelProps {
  children: ReactNode
  highlighted?: boolean
}

export const FadedLabel = ({ children, highlighted }: FadedLabelProps) => {
  return (
    <Text as="span" color="gray.500" fontStyle={highlighted ? "italic" : undefined}>
      {children}
    </Text>
  )
}
