import { Flex, FormLabel, Select } from "@chakra-ui/react"
import { MONTH_STRINGS, type MonthNumber, type ValidatorResult } from "@esgt/utils"
import { Warning } from "pages/Ratings/NewRating/summary/Warning"
import type { FieldValues, UseFormRegister } from "react-hook-form"

const NOW = new Date()
const CURRENT_MONTH = NOW.getMonth() + 1
const CURRENT_YEAR = NOW.getFullYear()
const PAST_YEARS = 9
const YEAR_OPTIONS = new Array(PAST_YEARS).fill(CURRENT_YEAR).map((year, index) => year - (PAST_YEARS - index))

// [1, 2, 3, ..., 12], so january is 1
const MONTH_OPTIONS: MonthNumber[] = new Array(12).fill(1).map((value, index) => value + index)

export const PERIOD_SELECTOR_DEFAULT_MONTHS = 12

interface RatingPeriodSelectorProps {
  register: UseFormRegister<FieldValues>
  startYear: number
  startMonth: number
  periodValidity: ValidatorResult
  isDisabled?: boolean
  isSelfServiceRating?: boolean
}

export const RatingPeriodSelector = ({
  register,
  startYear,
  startMonth,
  periodValidity,
  isDisabled,
  isSelfServiceRating,
}: RatingPeriodSelectorProps) => {
  return (
    <>
      <Flex direction="column">
        {isSelfServiceRating ? (
          <FormLabel>Fra år (01. januar til 31. desember)</FormLabel>
        ) : (
          <FormLabel>Fra år og måned</FormLabel>
        )}

        <Flex gap={2}>
          <Select
            isDisabled={isDisabled}
            background="white"
            {...register("periodStartYear", {
              value: startYear,
              valueAsNumber: true,
              required: true,
            })}
          >
            {YEAR_OPTIONS.map((year) => (
              <option value={year} key={year}>
                {year}
              </option>
            ))}
          </Select>

          {!isSelfServiceRating && (
            <Select
              isDisabled={isDisabled}
              background="white"
              {...register("periodStartMonth", {
                value: startMonth,
                valueAsNumber: true,
                required: true,
              })}
            >
              {MONTH_OPTIONS.map((month) => (
                <option value={month} key={month} disabled={startYear === CURRENT_YEAR && month >= CURRENT_MONTH}>
                  {MONTH_STRINGS[month]}
                </option>
              ))}
            </Select>
          )}
        </Flex>
      </Flex>

      {!isSelfServiceRating && (
        <Flex direction="column">
          <FormLabel>Periode</FormLabel>

          <Flex gap={2}>
            <Select
              isDisabled={isDisabled}
              background="white"
              {...register("periodLengthMonths", {
                valueAsNumber: true,
                required: true,
              })}
            >
              <option value={6}>6 måneder</option>
              <option value={12}>12 måneder</option>
            </Select>
          </Flex>
        </Flex>
      )}

      {!periodValidity.valid && <Warning level="error">Ugyldig periode: må være i fortiden</Warning>}
    </>
  )
}
