import { Box, type BoxProps, Progress } from "@chakra-ui/react"
import { config } from "lib/helpers/config"
import { useEffect, useState } from "react"
import { useIntervalWhen } from "rooks"

const UPDATE_INTERVAL_MS = 1000

const dateToProgress = (start: number, end: number, currentUnixTime: number) =>
  (1 - (currentUnixTime - start) / (end - start)) * 100

interface Props extends BoxProps {
  startsAt: number
  expiresAt: number
  onTimeout: () => void
}

/**
 * The countdown fetches currentUnixTimeMs from the server, as some clients do have incorrect time,
 * and might make the Countdown trigger timeout often right away after it is mounted.
 *
 * Now we:
 *
 *  1. Fetch the time from server
 *  2. Increment the time each second
 *  3. Update the progress based on currentUnixTimeMs
 */
export const Countdown: React.FC<Props> = ({ startsAt, expiresAt, onTimeout, ...props }) => {
  const [progress, setProgress] = useState<number | null>(null)
  const [isFetched, setIsFetched] = useState(false)

  useIntervalWhen(
    async () => {
      const res = await fetch(`${config.API_URL}/server-time`)
      const { unixTimeMs } = await res.json()
      setProgress(Math.max(dateToProgress(startsAt, expiresAt, unixTimeMs), -1))
      setIsFetched(true)
    },
    UPDATE_INTERVAL_MS,
    true,
    true,
  )

  useEffect(() => {
    if (progress !== null && progress < 0) onTimeout()
  }, [progress, onTimeout])

  const seconds = (expiresAt - startsAt) / 1000

  if (!isFetched) return null

  return (
    <Box {...props}>
      <Progress borderRadius="md" colorScheme="green" value={progress ?? 0} />
      <small>Koden er gyldig i {seconds} sekunder</small>
    </Box>
  )
}
