import { Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react"
import type { RatingState } from "@esgt/event-store"
import { EmptyMessage } from "components/EmptyMessage"
import { AnomalyTableRow } from "./Anomaly"

export const AnomaliesTable: React.FC<{
  anomalies?: RatingState["anomalies"]
  readOnly?: boolean
}> = ({ anomalies, readOnly }) => {
  return (
    <>
      {anomalies?.length ? (
        <Table variant="striped" width="100%" colorScheme="gray" mt="32px">
          <Thead>
            <Tr>
              <Th width="200px">Konto</Th>
              <Th>Melding</Th>
              <Th width="60px">Godkjent</Th>
              <Th width="300px" textAlign="right">
                Omklassifisering
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {anomalies.map((anomaly, i) => (
              <AnomalyTableRow anomaly={anomaly} key={i} id={i.toString()} readOnly={!!readOnly} />
            ))}
          </Tbody>
        </Table>
      ) : (
        <EmptyMessage>Ingen avvik registrert</EmptyMessage>
      )}
    </>
  )
}
