import { Box, Flex, Icon } from "@chakra-ui/react"
import { type ExtendedCalculatedKpi, benchmarkLevels, benchmarksAreAscending } from "@esgt/method-structure"
import type { Kpi, RatingProfileConfigItem } from "@esgt/types"
import type { i18n as I18nType } from "i18next"
import { getLanguage } from "lib/i18n/utils"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Container = styled.div`
	background-color: rgb(253, 253, 253);
	border: 1px solid rgb(223, 223, 219);
	border-radius: 8px;
	padding: 12px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 8px;
	font-size: 11px;
`

const BenchmarkLevel = styled.div`
	display: flex;
	flex-flow: column nowrap;
	gap: 1px;
`

const CircleIcon = (props: any) => (
  <Icon viewBox="0 0 100 100" {...props} width="16px" height="16px">
    <circle cx="50" cy="50" r="30" fill="currentColor" />
    {props.outline && <circle cx="50" cy="50" r="45" fill="none" strokeWidth={7} stroke="currentColor" />}
  </Icon>
)

export const BenchmarkBox: React.FC<{
  calculatedKPI: ExtendedCalculatedKpi
  kpi: Kpi
}> = ({ calculatedKPI, kpi }) => {
  const { i18n } = useTranslation()
  return (
    <Container>
      {benchmarkRanges(calculatedKPI.kpiConfig, kpi, i18n).map((bml, i) => (
        <BenchmarkLevel key={i}>
          <Flex flexFlow="row nowrap" alignItems="center" gap="6px">
            <CircleIcon color={bml.benchmark.color} outline={bml.benchmarkId === calculatedKPI.benchmarkLevel} />
            <Box fontWeight="400">{bml.benchmark.name}</Box>
          </Flex>
          <Flex flexFlow="row nowrap" gap="6px">
            <Box width="16px" />
            <Box fontWeight="300">{bml.rangeDescription}</Box>
          </Flex>
        </BenchmarkLevel>
      ))}
    </Container>
  )
}

function benchmarkRanges(kpiConfig: RatingProfileConfigItem, kpi: Kpi, i18n: I18nType) {
  const output: Array<{
    benchmarkId: number
    rangeDescription: string
    benchmark: (typeof benchmarkLevels)[number]
  }> = []
  let previousThresholdValue = ""
  if (benchmarksAreAscending(kpiConfig)) {
    for (let i = 4; i > -1; i--) {
      const thresholdValue = kpiConfig[`benchmark_${i as 0 | 1 | 2 | 3 | 4}` as const]

      let rangeDescription: string
      if (i === 4) {
        rangeDescription = `≥ ${thresholdValue} ${getLanguage(kpi.unit, i18n)}`
      } else if (i === 0) {
        rangeDescription = `< ${previousThresholdValue} ${getLanguage(kpi.unit, i18n)}`
      } else {
        rangeDescription = `${thresholdValue} - ${previousThresholdValue} ${getLanguage(kpi.unit, i18n)}`
      }
      output.push({
        benchmarkId: i,
        rangeDescription,
        benchmark: benchmarkLevels[i],
      })
      previousThresholdValue = thresholdValue || ""
    }
  } else {
    for (let i = 4; i > -1; i--) {
      const thresholdValue = kpiConfig[`benchmark_${i as 0 | 1 | 2 | 3 | 4}` as const]

      let rangeDescription: string
      if (i === 4) {
        rangeDescription = `≤ ${thresholdValue} ${getLanguage(kpi.unit, i18n)}`
      } else if (i === 0) {
        rangeDescription = `> ${previousThresholdValue} ${getLanguage(kpi.unit, i18n)}`
      } else {
        rangeDescription = `${previousThresholdValue} - ${thresholdValue} ${getLanguage(kpi.unit, i18n)}`
      }
      output.push({
        benchmarkId: i,
        rangeDescription,
        benchmark: benchmarkLevels[i],
      })
      previousThresholdValue = thresholdValue || ""
    }
  }
  return output
}
