import { Box, Text } from "@chakra-ui/react"
import { SectionHeading } from "../components"

export const Disclaimer: React.FC = () => {
  return (
    <Box mb="10mm">
      <SectionHeading>Avgrensninger</SectionHeading>
      <Text mb="4mm">
        ESG Trackr AS står ikke ansvarlig for resultatene i denne bærekraftsrapporten. Det er kunden selv som står
        ansvarlig for data og dokumentasjon som resultatene bygger på.
      </Text>
      <Text mb="4mm">
        Kunden står også ansvarlig for sine kommentarer til rapporten, strategi og mål. Dette er ikke gjennomgått av
        uavhengig tredjepart eller ESG Trackr.
      </Text>
      <Text mb="4mm">
        Indikatorsettet bygger på GRI, TCFD, NSRS og VSMEs ESRS, og har til hensikt å fylle ut mest mulig av
        indikatorene i nevnte standarder. Likevel er ikke standardene dekket fullt ut, da det krever mer
        selskapsspesifikke vurderinger.
      </Text>
    </Box>
  )
}
