import { Box, Text } from "@chakra-ui/react"
import { useRatingAccount } from "lib/providers/RatingAccount"
import { useRating } from "lib/providers/RatingProvider"
import { Page, SectionHeading } from "../components"
import { Disclaimer } from "./Disclaimer"

export const Intro: React.FC = () => {
  const { accountTitle } = useRatingAccount()
  const { ratingState } = useRating()

  return (
    <Page>
      <Box mb="10mm">
        <SectionHeading>Forord</SectionHeading>
        <Text mb="4mm" fontWeight="400">
          {ratingState?.brreg?.navn} har bærekraftsmålt sin virksomhet på plattformen ESG Trackr. En uavhengig og
          objektiv tredjepart har gjennomgått data og dokumentasjon.
        </Text>
        <Text mb="4mm">
          Målingen uttrykker virksomhetens bærekraft på et forhåndsdefinert indikatorsett. Resultatene sees i sammenheng
          med måltall for virksomhetens næringskode, og gis en farge som indikerer virksomhetens resultat. Resultatene
          presenteres etter dimensjonene økonomi, miljø og sosial. Videre er resultatene presentert visuelt i en sol og
          utfyllende per indikator.
        </Text>
        <Text mb="4mm">
          For de virksomhetene som er i startfasen på bærekraftarbeidet kan en måling gi et godt utgangspunkt for
          hvordan virksomheten skal jobbe med bærekraft. For virksomheter som allerede har startet bærekraftarbeidet,
          vil en bærekraftsmåling indikere virksomhetens grad av bærekraft, samt resultat av allerede innførte tiltak og
          strategier. Ved gjentakende målinger vil virksomheten kunne se målbare endringer ved å sammenligne egen score
          fra en periode til neste.
        </Text>
        <Text mb="4mm">
          Resultatene bygger på kundens innleverte data og dokumentasjon på plattformen. Etter formler i
          metodebeskrivelsen beregner plattformen resultatene automatisk. En uavhengig tredjepart har gjennomført
          kontroll på resultater og innsendt data og dokumentasjon. Resultatene er derfor objektive og etterprøvbare.
        </Text>
        <Text mb="4mm">{accountTitle} har utført denne bærekraftsmålingen på plattformen ESG Trackr.</Text>
      </Box>
      <Disclaimer />
    </Page>
  )
}
