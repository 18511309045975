import { SearchIcon } from "@chakra-ui/icons"
import { Input, InputGroup, InputLeftElement, type InputProps, Text } from "@chakra-ui/react"
import Downshift from "downshift"
import { useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import styled from "styled-components"
import { useDebounce } from "usehooks-ts"
import { classificationItems } from "./30.json"
import type { FormValues } from "./New"

const itemToString = (something: any) => something?.navn

const SearchResults = styled.div`
	display: flex;
	position: absolute;
	background-color: rgba(240, 240, 240, 0.98);
	left: 0;
	right: 0;
	z-index: 2;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	flex-flow: column nowrap;
	max-height: 350px;
	overflow: scroll;
`
const SearchResultItem = styled(
  ({
    searchResult,
    className,
    isActive: _,
    ...rest
  }: {
    searchResult: Record<string, any>
    isActive: boolean
    className?: string
  }) => {
    return (
      <div className={className} {...rest}>
        <Text fontSize="sm">{searchResult.code}</Text>
        <Text fontSize="xs">{searchResult.name}</Text>
      </div>
    )
  },
)`
	${(p) => (p.isActive ? "background-color: rgba(144, 205, 244, 0.3);" : "")}
	color: ${(p) => (p.isActive ? "black" : "#333")};
	font-weight: ${(p) => (p.isActive ? "500" : "normal")};
	cursor: pointer;
	padding: 10px 20px;
`

export const ClassificationSelector = () => {
  const { control } = useFormContext<FormValues>()
  const [searchString, setSearchString] = useState("")

  const [searchResults, setSearchResults] = useState<Array<Record<string, any>>>([])
  const debouncedValue = useDebounce<string>(searchString, 30)

  useEffect(() => {
    if (searchString.length > 0) {
      const res = []
      for (const item of classificationItems) {
        if (item.code.startsWith(searchString) || item.name.toLowerCase().includes(searchString.toLowerCase())) {
          res.push(item)
          if (res.length === 20) {
            break
          }
        }
      }
      setSearchResults(res)
    } else {
      setSearchResults([])
    }
  }, [debouncedValue])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Controller
        control={control}
        name="selectedClassification"
        rules={{ required: true }}
        render={({ field: { onChange } }) => (
          <Downshift
            onChange={(selection) => {
              setSearchResults([])
              onChange({ target: { value: selection } })
            }}
            itemToString={itemToString}
            inputValue={searchString}
            onInputValueChange={(s) => {
              setSearchString(s || "")
              if (!s) setSearchResults([])
            }}
          >
            {({ getInputProps, getMenuProps, getItemProps, highlightedIndex }) => (
              <div>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ margin: "0 24px 0 0" }}>
                    <InputLeftElement pointerEvents="none">
                      <SearchIcon color="gray.300" />
                    </InputLeftElement>
                    <Input
                      type="search"
                      placeholder="Søk i næringskoder"
                      spellCheck={false}
                      autoFocus={true}
                      style={{ zIndex: 3 }}
                      {...getInputProps<InputProps>()}
                    />
                  </InputGroup>
                </div>
                <div style={{ position: "relative" }}>
                  {searchResults.length ? (
                    <SearchResults {...getMenuProps({})}>
                      {searchResults.map((result: any, i: any) => (
                        <SearchResultItem
                          key={i}
                          searchResult={result}
                          isActive={highlightedIndex === i}
                          {...getItemProps({
                            item: result,
                            index: i,
                          })}
                        />
                      ))}
                    </SearchResults>
                  ) : null}
                </div>
              </div>
            )}
          </Downshift>
        )}
      />
    </div>
  )
}
