import { Button, Grid, Icon } from "@chakra-ui/react"
import type { RatingFileCategory, RatingUpload, UploadScope } from "@esgt/types"
import { useArchiveRatingUploadsMutation } from "lib/generated/graphql"
import { useRating } from "lib/providers/RatingProvider"
import { nanoid } from "nanoid"
import { useEffect, useMemo, useState } from "react"
import { type Accept, useDropzone } from "react-dropzone"
import { MdRefresh, MdUpload } from "react-icons/md"
import styled from "styled-components"
import { ExistingItem } from "./ExistingItem"
import { UploadItem } from "./UploadItem"

const DropZone = styled.div`
	display: flex;
	flex-flow: row nowrap;
	gap: 16px;
	align-items: center;
`

const Container = styled.div``

export interface UploadItemData {
  uploadId: string
  file: File
  timestamp: string
}

interface RatingUploaderProps {
  ratingFileType: RatingFileCategory
  uploadScope: UploadScope
  accept?: Accept
  readOnly?: boolean
  maxFiles?: number
}

export const RatingUploader: React.FC<RatingUploaderProps> = ({
  ratingFileType,
  uploadScope,
  accept,
  readOnly,
  maxFiles,
}) => {
  const [files, setFiles] = useState<UploadItemData[]>([])
  const { ratingState, ratingId } = useRating()
  const [, archiveUploads] = useArchiveRatingUploadsMutation()

  const isReadonly = readOnly === undefined ? ratingState?.phase !== "SUBMISSION" : readOnly

  const ratingUploadsCountForScope = ratingState?.uploads?.filter((u) => u.scope === uploadScope).length || 0
  const combinedList = useMemo(() => {
    const existingUploadIds = (ratingState?.uploads || []).map((u) => u.uploadId)

    const list: Array<RatingUpload | UploadItemData> = [
      ...(ratingState?.uploads || []).filter((up) => up.scope === uploadScope),
      ...files.filter((f) => !existingUploadIds.includes(f.uploadId)),
    ]

    return list.sort((a, b) => (a.timestamp + a.uploadId > b.timestamp + b.uploadId ? 1 : -1))
  }, [ratingState?.uploads, files])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const newFiles = [...files]
      if (combinedList.length >= maxFiles) {
        await archiveUploads({ ratingId, uploadIds: combinedList.map((f) => f.uploadId) })
      }
      for (const file of acceptedFiles) {
        newFiles.unshift({
          uploadId: nanoid(),
          file,
          timestamp: new Date().toISOString(),
        })
      }

      setFiles(newFiles)
    },
    accept,
    noClick: isReadonly,
    multiple: maxFiles !== 1,
  })

  const removeFile = (id: string) => {
    setFiles(files.filter((f) => f.uploadId !== id))
  }

  useEffect(() => {
    const uploadIds = (ratingState?.uploads || []).map((u) => u.uploadId)
    setFiles(files.filter((f) => !uploadIds.includes(f.uploadId)))
  }, [ratingState?.uploads])

  return (
    <Container>
      <DropZone {...getRootProps()}>
        <input {...getInputProps()} />
        <Button variant="outline" isDisabled={isReadonly || ratingUploadsCountForScope < combinedList.length}>
          <Icon
            as={ratingUploadsCountForScope >= maxFiles ? MdRefresh : MdUpload}
            height={"100%"}
            width={"18px"}
            color="white.300"
            opacity={0.8}
            mr="8px"
          />
          {ratingUploadsCountForScope >= maxFiles ? "Erstatt" : "Velg og last opp"}
          {maxFiles === 1 ? " fil" : " filer"}
        </Button>
      </DropZone>
      {combinedList.length > 0 && (
        <Grid mt="6" gap={"24px"} flexFlow="column nowrap">
          {combinedList.map((file, index) =>
            "file" in file ? (
              <UploadItem
                timestamp={file.timestamp}
                key={file.uploadId}
                id={file.uploadId}
                file={file.file}
                ratingFileType={ratingFileType}
                uploadScope={uploadScope}
                onDelete={() => removeFile(file.uploadId)}
                delay={index * 500}
              />
            ) : (
              <ExistingItem
                key={file.uploadId}
                {...file}
                processed={file.processed}
                valid={file.valid}
                readOnly={readOnly}
              />
            ),
          )}
        </Grid>
      )}
    </Container>
  )
}
