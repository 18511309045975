import { CheckIcon, ChevronDownIcon, ChevronUpIcon, CloseIcon } from "@chakra-ui/icons"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { UserAccountRole } from "@esgt/types"
import { Can } from "components/Can"
import { CenteredContent } from "components/CenteredContent"
import { ContentBox } from "components/ContentBox"
import { RatingLoading } from "components/RatingLoading"
import { SiteTitle } from "components/SiteTitle"
import { useBreadcrumb } from "components/breadcrumbs/BreadcrumbProvider"
import { EMAIL_PATTERN } from "components/common/Contact/emailValidation"
import type { Account } from "lib/generated/graphql"
import { routes } from "lib/routes"
import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { useAccountEditing } from "./AccountEditingProvider"

export const AccountEditing = () => {
  const {
    account,
    fetching,
    deleteUser,
    deleteUserError,
    userDeleted,
    createUser,
    createUserError,
    userCreated,
    updateUser,
    updateUserError,
    userUpdated,
    deleteAccount,
    accountDeleted,
    accountDeletedError,
  } = useAccountEditing()

  useBreadcrumb(account?.title || "", account ? `/admin/accounts/${account?.id}` : ".")

  // const defaultValues = account || {}
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm<Account>({
    defaultValues: {},
    mode: "onBlur",
  })

  const [userInAction, setUserInAction] = useState(null)

  const toast = useToast()
  const navigate = useNavigate()

  const { isOpen: confirmDeleteIsOpen, onOpen: confirmDeleteOnOpen, onClose: confirmDeleteOnClose } = useDisclosure()

  const {
    isOpen: confirmDeleteAccountIsOpen,
    onOpen: confirmDeleteAccountOnOpen,
    onClose: confirmDeleteAccountOnClose,
  } = useDisclosure()

  const confirmRef = useRef<any>(null)
  const confirmDeleteAccountRef = useRef<any>(null)

  // Set form initial values
  useEffect(() => {
    if (account) {
      for (const key of Object.keys(account)) {
        setValue(key as never, account[key] as never)
      }
    }
  }, [account, setValue])

  // Adds new user to array, without persisting it
  const addUser = (key: "admins" | "members") => {
    const val = [...getValues()[key]]
    val.push({
      email: "",
      name: "",
      phone: "",
      id: null,
    })
    setValue(key, val)
  }

  // Removes user if it is un-saved, otherwise shows warning
  const removeUser = (key: "admins" | "members", arrIndex: number) => {
    const val = [...getValues()[key]]
    if (val[arrIndex].id) {
      setUserInAction(val[arrIndex].id)
      confirmDeleteOnOpen()
    } else {
      val.splice(arrIndex, 1)
      setValue(key, val)
    }
  }

  // Triggers when delete is attempted
  useEffect(() => {
    if (userDeleted === true) {
      toast({
        title: "Bruker slettet",
        status: "success",
        position: "top",
        isClosable: true,
      })
    }
    if (deleteUserError) {
      toast({
        title: "Bruker IKKE slettet",
        description: deleteUserError.message,
        status: "error",
        position: "top",
        isClosable: true,
      })
    }
  }, [userDeleted, deleteUserError, toast])

  // Triggered from confirm-modal, actually deletes user
  const removeSavedUser = () => {
    deleteUser({ input: { id: userInAction, accountId: account.id } }).finally(() => {
      confirmDeleteOnClose()
      setUserInAction(null)
    })
  }

  // CreateUser is attempted
  useEffect(() => {
    if (userCreated === true) {
      toast({
        title: "Bruker opprettet",
        status: "success",
        position: "top",
        isClosable: true,
      })
    }
    if (createUserError) {
      toast({
        title: "Bruker ikke opprettet",
        description: createUserError.message,
        status: "error",
        position: "top",
        isClosable: true,
      })
    }
  }, [userCreated, createUserError, toast])

  // Update is attempted
  useEffect(() => {
    if (userUpdated === true) {
      toast({
        title: "Bruker oppdatert",
        status: "success",
        position: "top",
        isClosable: true,
      })
    }
    if (updateUserError) {
      toast({
        title: "Bruker ikke oppdatert",
        description: updateUserError.message,
        status: "error",
        position: "top",
        isClosable: true,
      })
    }
  }, [updateUserError, userUpdated, toast])

  const doSaveUser = (key: "admins" | "members", arrIndex: number) => {
    const val = [...getValues()[key]]
    const params = { ...val[arrIndex] }

    if (params.id === null) {
      params.id = undefined
      createUser({
        input: {
          ...params,
          role: key === "admins" ? (UserAccountRole.AccountAdmin as any) : (UserAccountRole.AccountMember as any),
          accountId: account.id,
        },
      })
    } else {
      doUpdateUser(key, arrIndex)
    }
  }

  const doUpdateUser = (key: "admins" | "members", arrIndex: number) => {
    const val = [...getValues()[key]]
    const params = { ...val[arrIndex] }

    if (params.id) {
      updateUser({
        input: {
          phone: params.phone,
          name: params.name,
          role: key === "admins" ? (UserAccountRole.AccountAdmin as any) : (UserAccountRole.AccountMember as any),
          id: params.id,
          accountId: account.id,
        },
      })
    }
  }

  const switchUserRole = (newRole: UserAccountRole, arrIndex: number) => {
    const val = [...getValues()[newRole === UserAccountRole.AccountAdmin ? "members" : "admins"]]
    const params = { ...val[arrIndex] }

    if (params.id) {
      updateUser({
        input: {
          role: newRole as any,
          id: params.id,
          accountId: account.id,
        },
      })
    }
  }

  const deactivateAccount = () => confirmDeleteAccountOnOpen()

  const doRemoveAccount = () => {
    deleteAccount({ accountId: account.id })
  }

  // Account deletion is attempted
  useEffect(() => {
    if (accountDeleted === true) {
      toast({
        title: "Konto deaktivert",
        status: "success",
        position: "top",
        isClosable: true,
      })
      navigate("..")
    }
    if (accountDeletedError) {
      toast({
        title: "Kunne ikke deaktivere konto",
        description: accountDeletedError.message,
        status: "error",
        position: "top",
        isClosable: true,
      })
    }
  }, [accountDeleted, accountDeletedError, toast, navigate])

  if (fetching) return <RatingLoading />

  const members = watch("members") as any[]
  const admins = watch("admins") as any[]

  return (
    <CenteredContent scrollable>
      <SiteTitle title={"Brukeradministrasjon"} />
      <Can permission="system:write" entityId="*">
        <SiteTitle title={`Konto - ${account.title}`} />
        <Link to={routes.admin("accounts")}>
          <Text mt={10}>« Tilbake</Text>
        </Link>
      </Can>
      <Flex mt={10} direction={"column"}>
        <Flex justify={"space-between"}>
          <Heading size={"lg"}>{account.title}</Heading>
          <Can permission="system:write" entityId="*">
            <Button colorScheme="red" onClick={deactivateAccount}>
              Deaktiver konto
            </Button>
          </Can>
        </Flex>
        <Flex mt={4} direction={"column"}>
          <Heading aria-level={2} size="md">
            Kontoeier
          </Heading>
          <Heading aria-level={3} size="sm">
            {account.owner.name}, {account.owner.email} {account.owner.phone ? ` / ${account.owner.phone}` : ""}
          </Heading>
        </Flex>

        <ContentBox mt={16}>
          <Heading aria-level={2} size="md" mb={0}>
            Kontomedeiere
          </Heading>
          <Text fontStyle={"italic"}>
            En kontomedeier er medansvarlig for kontoen, og har tilgang til samtlige målinger, og bidrar til å
            administrere kontrollører og målinger opprettet under denne kontoen.
          </Text>
          {admins?.map((a, k) => (
            <Flex key={k} mt={4} gap={2}>
              <FormControl>
                <FormLabel htmlFor={`admins.${k}.name`}>Navn</FormLabel>
                <Input
                  id={`admins.${k}.name`}
                  placeholder="Navn"
                  autoFocus={true}
                  defaultValue={a.name}
                  {...register(`admins.${k}.name`, { required: "Fyll ut fullt navn" })}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor={`admins.${k}.email`}>E-post</FormLabel>
                <Input
                  id={`admins.${k}.email`}
                  placeholder="E-post"
                  type={"email"}
                  defaultValue={a.email}
                  isDisabled={!!admins[k].id}
                  {...register(`admins.${k}.email`, {
                    required: true,
                    pattern: { value: EMAIL_PATTERN, message: "Fyll ut gyldig e-post" },
                  })}
                />
                {errors.admins?.[k]?.name && <FormErrorMessage>{errors.admins?.[k]?.name.message}</FormErrorMessage>}
              </FormControl>
              <FormControl>
                <FormLabel htmlFor={`admins.${k}.phone`}>Telefonnummer</FormLabel>
                <Input
                  id={`admins.${k}.phone`}
                  placeholder="Telefonnummer"
                  defaultValue={a.phone}
                  {...register(`admins.${k}.phone`)}
                />
              </FormControl>

              <Tooltip label={`Lagre bruker: "${a.name}"`}>
                <Button mt={8} variant="outline" onClick={() => doSaveUser("admins", k)}>
                  <CheckIcon />
                </Button>
              </Tooltip>

              <Tooltip label={`Gjør om "${a.name}" til kontrollør`}>
                <Button mt={8} variant="outline" onClick={() => switchUserRole(UserAccountRole.AccountMember, k)}>
                  <ChevronDownIcon />
                </Button>
              </Tooltip>

              <Tooltip label={`Slett bruker: "${a.name}"`}>
                <Button mt={8} variant="critical" onClick={() => removeUser("admins", k)}>
                  <CloseIcon />
                </Button>
              </Tooltip>
            </Flex>
          ))}
          <Button mt={8} colorScheme="green" width={250} onClick={() => addUser("admins")}>
            + Legg til kontomedeier
          </Button>
        </ContentBox>

        <ContentBox mt={16}>
          <Heading aria-level={2} size="md" mb={0}>
            Kontrollører
          </Heading>
          <Text fontStyle={"italic"}>
            En kontrollør er ansvarlig for å gjennomføre og kontrollere bærekraftsmålinger, opprette disse eller bidra
            til målinger som medkontrollør. En kontrollør har kun tilgang til sine egne målinger eller målinger der
            han/hun er lagt til som medkontrollør.
          </Text>
          {members?.map((a, k) => (
            <Flex key={k} mt={4} gap={2}>
              <FormControl>
                <FormLabel htmlFor={`members.${k}.name`}>Navn</FormLabel>
                <Input
                  id={`members.${k}.name`}
                  placeholder="Navn"
                  autoFocus={true}
                  defaultValue={a.name}
                  {...register(`members.${k}.name`, { required: "Fyll ut fullt navn" })}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor={`members.${k}.email`}>E-post</FormLabel>
                <Input
                  id={`members.${k}.email`}
                  placeholder="E-post"
                  type={"email"}
                  defaultValue={a.email}
                  isDisabled={!!members[k].id}
                  {...register(`members.${k}.email`, {
                    required: true,
                    pattern: { value: EMAIL_PATTERN, message: "Fyll ut gyldig e-post" },
                  })}
                  // onBlur={() => shouldUserBeSaved("members", k)}
                />
                {errors.members?.[k]?.name && <FormErrorMessage>{errors.members?.[k]?.name.message}</FormErrorMessage>}
              </FormControl>
              <FormControl>
                <FormLabel htmlFor={`members.${k}.phone`}>Telefonnummer</FormLabel>
                <Input
                  id={`members.${k}.phone`}
                  placeholder="Telefonnummer"
                  defaultValue={a.phone}
                  {...register(`members.${k}.phone`)}
                />
              </FormControl>

              <Tooltip label={`Lagre bruker: "${a.name}"`}>
                <Button mt={8} variant="outline" onClick={() => doSaveUser("members", k)}>
                  <CheckIcon />
                </Button>
              </Tooltip>

              <Tooltip label={`Gjør om "${a.name}" til kontomedeier`}>
                <Button mt={8} variant="outline" onClick={() => switchUserRole(UserAccountRole.AccountAdmin, k)}>
                  <ChevronUpIcon />
                </Button>
              </Tooltip>

              <Tooltip label={`Slett bruker: "${a.name}"`}>
                <Button mt={8} variant="critical" onClick={() => removeUser("members", k)}>
                  <CloseIcon />
                </Button>
              </Tooltip>
            </Flex>
          ))}
          <Button width={250} mt={8} colorScheme="green" onClick={() => addUser("members")}>
            + Legg til medlem
          </Button>
        </ContentBox>
      </Flex>
      <AlertDialog isOpen={confirmDeleteIsOpen} leastDestructiveRef={confirmRef} onClose={confirmDeleteOnClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slette bruker?
            </AlertDialogHeader>

            <AlertDialogBody>Denne brukeren vil da miste tilgangen til systemet.</AlertDialogBody>

            <AlertDialogFooter>
              <Button variant="outline" mr={2} onClick={confirmDeleteOnClose}>
                Avbryt
              </Button>
              <Button colorScheme={"green"} onClick={() => removeSavedUser()}>
                Ja, slett bruker
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={confirmDeleteAccountIsOpen}
        leastDestructiveRef={confirmDeleteAccountRef}
        onClose={confirmDeleteAccountOnClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Deaktivere konto?
            </AlertDialogHeader>

            <AlertDialogBody>Dette vil føre til at alle brukere mister tilgang.</AlertDialogBody>

            <AlertDialogFooter>
              <Button mr={2} variant="outline" onClick={confirmDeleteAccountOnClose}>
                Avbryt
              </Button>
              <Button colorScheme={"green"} onClick={() => doRemoveAccount()}>
                Ja, deaktiver konto
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </CenteredContent>
  )
}
