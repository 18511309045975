import { Button, useToast } from "@chakra-ui/react"
import type { BRREGUnit, MinimalContactInfo } from "@esgt/types"
import { validateContactPerson, validatePeriod } from "@esgt/utils"
import { StagingIndicator } from "components/StagingIndicator"
import { type OrderRatingMutationVariables, useOrderRatingMutation } from "lib/generated/graphql"
import { useEffect, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import type { RaterOption } from "../../RaterOption"
import { NewRatingStep, NewRatingStepFooter, NewRatingSubstep } from "../NewRatingStep"
import type { RatingContactInfo } from "../orderInfo/CompanyInfoForm"
import type { NewRatingMeasurementSettings } from "../orderInfo/MeasurementDetailsForm"
import { CompanySummary } from "./CompanySummary"
import { ContactPersonsSummary } from "./ContactPersonsSummary"
import { DetailsSummary } from "./DetailsSummary"
import { RaterSummary } from "./RaterSummary"

export type NewRatingValues = {
  primaryContact: RatingContactInfo
  measurement: Partial<NewRatingMeasurementSettings>
  raters: Array<RaterOption>
  contributors: Array<MinimalContactInfo>
}

interface NewRatingSummaryProps {
  values: NewRatingValues
  selectedOrganization: BRREGUnit
}

export const NewRatingSummary = ({ values, selectedOrganization }: NewRatingSummaryProps) => {
  const navigate = useNavigate()
  const toast = useToast()
  const [orderRatingResult, orderRating] = useOrderRatingMutation()

  const nonEmptyContributors = values.contributors.filter((c) => c.name.length || c.email.length)

  const periodValidity = useMemo(
    () =>
      validatePeriod({
        start: values.measurement.periodStart,
        end: values.measurement.periodEnd,
      }),
    [values.measurement],
  )

  const isSubmittable =
    selectedOrganization?.organisasjonsnummer &&
    values.primaryContact.name &&
    values.primaryContact.email &&
    periodValidity.valid &&
    values.measurement.ratingProfileId &&
    values.raters.length > 0 &&
    nonEmptyContributors.every(validateContactPerson)

  useEffect(() => {
    if (orderRatingResult.error) {
      toast({
        title: "Kunne ikke opprette måling",
        description:
          orderRatingResult.error.message === "[GraphQL] Contact person can not be the same as rater"
            ? "Kontaktperson kan ikke være den samme som kontrollør"
            : "",
        status: "error",
        position: "top",
        isClosable: true,
      })
    } else if (orderRatingResult.data) {
      navigate(`../../${orderRatingResult.data.orderRating.key}`)
    }
  }, [navigate, orderRatingResult, toast])

  const onSubmit = () => {
    orderRating(createOrderRatingQuery(values, selectedOrganization.organisasjonsnummer))
  }

  return (
    <NewRatingStep>
      <NewRatingSubstep title="Virksomhet">
        <CompanySummary primaryContact={values.primaryContact} selectedOrganization={selectedOrganization} />
      </NewRatingSubstep>

      <NewRatingSubstep title="Periode">
        <DetailsSummary measurement={values.measurement} periodValidity={periodValidity} />
      </NewRatingSubstep>

      <NewRatingSubstep title="Kontrollører">
        <RaterSummary raters={values.raters} />
      </NewRatingSubstep>

      <NewRatingSubstep title="Kontaktpersoner">
        <ContactPersonsSummary nonEmptyContributors={nonEmptyContributors} contactPerson={values.primaryContact} />
      </NewRatingSubstep>

      <NewRatingStepFooter comment="Når du trykker på 'Fullfør' sendes en e-post til alle kontaktpersoner i virksomheten med link til å svare ut målingen.">
        <Button variant="outline" size="lg" as={Link} to="../roles">
          Tilbake
        </Button>

        <Button
          colorScheme="green"
          size="lg"
          onClick={onSubmit}
          isLoading={orderRatingResult.fetching}
          isDisabled={!isSubmittable}
        >
          Fullfør
        </Button>
      </NewRatingStepFooter>
      <StagingIndicator />
    </NewRatingStep>
  )
}

function createOrderRatingQuery(values: NewRatingValues, orgNr: string): OrderRatingMutationVariables {
  return {
    orgNr,
    contactPerson: {
      email: values.primaryContact.email,
      name: values.primaryContact.name,
      phone: values.primaryContact.phone,
    },
    period: {
      start: values.measurement.periodStart,
      end: values.measurement.periodEnd,
    },
    ratingProfileId: Number(values.measurement.ratingProfileId),
    raters: values.raters.map((rater) => ({
      userId: rater.user.id,
      role: rater.role,
    })),
    contributors: [
      {
        email: values.primaryContact.email,
        name: values.primaryContact.name,
      },
      ...values.contributors.filter(validateContactPerson),
    ],
  }
}
