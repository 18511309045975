import { useRatingAccountQuery } from "lib/generated/graphql"
import type React from "react"
import { Context } from "./Context"

interface ProviderProps {
  children: React.ReactNode
  ratingId: string
}

export const Provider: React.FC<ProviderProps> = ({ children, ratingId }) => {
  const [{ fetching, data }] = useRatingAccountQuery({ variables: { key: ratingId }, requestPolicy: "cache-first" })

  if (fetching || !data) {
    return null
  }

  return <Context.Provider value={{ accountTitle: data.rating?.accountTitle }}>{children}</Context.Provider>
}
