import { Icon, type IconProps } from "@chakra-ui/react"

export const IconCalendarLine: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <title>Calendar line</title>
      <path
        d="M21.5001 4H19.3334V5.33333H21.3334V20H2.66676V5.33333H4.66676V4H2.50009C2.34425 4.0026 2.19045 4.03588 2.04747 4.09794C1.90449 4.15999 1.77514 4.24961 1.6668 4.36166C1.55846 4.47371 1.47325 4.60601 1.41605 4.751C1.35885 4.89599 1.33077 5.05083 1.33342 5.20667V20.1267C1.33077 20.2825 1.35885 20.4373 1.41605 20.5823C1.47325 20.7273 1.55846 20.8596 1.6668 20.9717C1.77514 21.0837 1.90449 21.1733 2.04747 21.2354C2.19045 21.2974 2.34425 21.3307 2.50009 21.3333H21.5001C21.6559 21.3307 21.8097 21.2974 21.9527 21.2354C22.0957 21.1733 22.225 21.0837 22.3334 20.9717C22.4417 20.8596 22.5269 20.7273 22.5841 20.5823C22.6413 20.4373 22.6694 20.2825 22.6668 20.1267V5.20667C22.6694 5.05083 22.6413 4.89599 22.5841 4.751C22.5269 4.60601 22.4417 4.47371 22.3334 4.36166C22.225 4.24961 22.0957 4.15999 21.9527 4.09794C21.8097 4.03588 21.6559 4.0026 21.5001 4Z"
        fill="currentColor"
      />
      <path d="M5.33325 9.33325H6.66659V10.6666H5.33325V9.33325Z" fill="currentColor" />
      <path d="M9.33325 9.33325H10.6666V10.6666H9.33325V9.33325Z" fill="currentColor" />
      <path d="M13.3333 9.33325H14.6666V10.6666H13.3333V9.33325Z" fill="currentColor" />
      <path d="M17.3333 9.33325H18.6666V10.6666H17.3333V9.33325Z" fill="currentColor" />
      <path d="M5.33325 12.6667H6.66659V14.0001H5.33325V12.6667Z" fill="currentColor" />
      <path d="M9.33325 12.6667H10.6666V14.0001H9.33325V12.6667Z" fill="currentColor" />
      <path d="M13.3333 12.6667H14.6666V14.0001H13.3333V12.6667Z" fill="currentColor" />
      <path d="M17.3333 12.6667H18.6666V14.0001H17.3333V12.6667Z" fill="currentColor" />
      <path d="M5.33325 16H6.66659V17.3333H5.33325V16Z" fill="currentColor" />
      <path d="M9.33325 16H10.6666V17.3333H9.33325V16Z" fill="currentColor" />
      <path d="M13.3333 16H14.6666V17.3333H13.3333V16Z" fill="currentColor" />
      <path d="M17.3333 16H18.6666V17.3333H17.3333V16Z" fill="currentColor" />
      <path
        d="M6.66667 7.66659C6.84348 7.66659 7.01305 7.59635 7.13807 7.47132C7.2631 7.3463 7.33333 7.17673 7.33333 6.99992V2.99992C7.33333 2.82311 7.2631 2.65354 7.13807 2.52851C7.01305 2.40349 6.84348 2.33325 6.66667 2.33325C6.48986 2.33325 6.32029 2.40349 6.19526 2.52851C6.07024 2.65354 6 2.82311 6 2.99992V6.99992C6 7.17673 6.07024 7.3463 6.19526 7.47132C6.32029 7.59635 6.48986 7.66659 6.66667 7.66659Z"
        fill="currentColor"
      />
      <path
        d="M17.3334 7.66659C17.5102 7.66659 17.6798 7.59635 17.8048 7.47132C17.9298 7.3463 18.0001 7.17673 18.0001 6.99992V2.99992C18.0001 2.82311 17.9298 2.65354 17.8048 2.52851C17.6798 2.40349 17.5102 2.33325 17.3334 2.33325C17.1566 2.33325 16.987 2.40349 16.862 2.52851C16.737 2.65354 16.6667 2.82311 16.6667 2.99992V6.99992C16.6667 7.17673 16.737 7.3463 16.862 7.47132C16.987 7.59635 17.1566 7.66659 17.3334 7.66659Z"
        fill="currentColor"
      />
      <path d="M8.66675 4H15.3334V5.33333H8.66675V4Z" fill="currentColor" />
    </Icon>
  )
}
