import { AccountingPeriod, AccountingPeriodRange, type RatingState } from "@esgt/event-store"
import { ProcessingStatus, RatingType, UploadScopeEnum } from "@esgt/types"

export type AltinnProgress = {
  A07: boolean
}

export function altinnProgress(ratingState: RatingState): AltinnProgress {
  const status: AltinnProgress = {
    A07:
      ratingState.answerValues.A07?.primaryAnswer === "false" ||
      (a07FilesAreValid(ratingState) &&
        (ratingState.ratingType !== RatingType.Free ||
          ratingState.dataFilesProcessingStatus?.a07?.status === ProcessingStatus.Completed)),
  }
  return status
}

export function a07FilesAreValid(ratingState: RatingState) {
  return missingPeriodsInA07Uploads(ratingState)?.noMissingPeriods === 0
}

export function missingPeriodsInA07Uploads(ratingState: RatingState) {
  return ratingState.period?.missingPeriods(
    ratingState.uploads
      .filter((upload) => upload.scope === UploadScopeEnum.A07 && upload.valid)
      .map(
        (upload) =>
          new AccountingPeriodRange(
            new AccountingPeriod(upload.parsedData?.periodStart),
            new AccountingPeriod(upload.parsedData?.periodEnd),
          ),
      ),
  )
}
