import { Alert, AlertDescription, AlertIcon, type AlertProps, AlertTitle, Flex } from "@chakra-ui/react"

interface ErrorMessageProps {
  error: string
  title?: string
  status?: AlertProps["status"]
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ error, title, status = "warning" }) => {
  return (
    <Alert status={status}>
      <AlertIcon />
      <Flex flexDirection="column">
        {title && <AlertTitle>{title}</AlertTitle>}
        <AlertDescription>{error}</AlertDescription>
      </Flex>
    </Alert>
  )
}
